const AWS_ADDRESS = process.env.REACT_APP_API_GATEWAY_ADDRESS

// User Type
const TEST_USER = 1;
const DEVELOPER_USER = 2;
const ANALYST_USER = 3;
const ADMIN_USER = 4;

// App Target
const ANDROID_TYPE = 0;
const IOS_TYPE = 1;
const WEB_APP_TYPE = 2;

// App Status
const REQUEST = 0;
const APPROVE = 1;
const TEST_CLOSE = 2;
const ADMIN_REJECT = 3;
const ADMIN_REMOVE = 4;
const DEVELOPER_REMOVE = 5;


const LGPG_APP_GLOBAL = {
  ENTRY_POINT_LIST: 'List',
  ENTRY_POINT_REQUEST: 'Request',
  ENTRY_POINT_SIDEBAR: 'Sidebar'
}

const LGPG_APP_LIST = {
  AWS_APP_LIST_QUERY: AWS_ADDRESS + '/apps/list',
  AWS_APP_APPROVED_LIST_QUERY: AWS_ADDRESS + '/apps/approvedlist',

  PAGE_CARD_VIEW: 10,

  REQUEST: REQUEST,
  APPROVE: APPROVE,
  TEST_CLOSE: TEST_CLOSE,
  ADMIN_REJECT: ADMIN_REJECT,
  ADMIN_REMOVE: ADMIN_REMOVE,
  DEVELOPER_REMOVE: DEVELOPER_REMOVE,

  ANDROID_TYPE: ANDROID_TYPE,
  IOS_TYPE: IOS_TYPE,
  WEB_APP_TYPE: WEB_APP_TYPE,
};

const LGPG_APP_DETAIL = {
  AWS_DETAIL_QUERY: AWS_ADDRESS + '/apps/details',
  AWS_APP_STATUS_UPDATE_QUERY: AWS_ADDRESS + '/update',
  SEND_PUSH_MESSAGE: AWS_ADDRESS + '/user/fcm/send',

  TEST_USER: TEST_USER,
  DEVELOPER_USER: DEVELOPER_USER,
  ANALYST_USER: ANALYST_USER,
  ADMIN_USER: ADMIN_USER,

  REQUEST: REQUEST,
  APPROVE: APPROVE,
  TEST_CLOSE: TEST_CLOSE,
  ADMIN_REJECT: ADMIN_REJECT,
  ADMIN_REMOVE: ADMIN_REMOVE,
  DEVELOPER_REMOVE: DEVELOPER_REMOVE,

  ANDROID_TYPE: ANDROID_TYPE,
  IOS_TYPE: IOS_TYPE,
  WEB_APP_TYPE: WEB_APP_TYPE,

  HIGHEST_SCREEN_WIDTH: 1920,
  HIGH_SCREEN_WIDTH: 1400,
  MIDDLE_SCREEN_WIDTH: 1200,
  LOW_SCREEN_WIDTH: 1000,
};

const LGPG_APP_REGISTER = {
  AWS_APP_REGISTER_QUERY: AWS_ADDRESS + '/apps/register',
  AWS_APP_DETAIL_QUERY: AWS_ADDRESS + '/apps/details',
  ENTRY_POINT_HOME: false,
  ENTRY_POINT_DETAILS: true,

  TEST_USER: TEST_USER,
  DEVELOPER_USER: DEVELOPER_USER,
  ANALYST_USER: ANALYST_USER,
  ADMIN_USER: ADMIN_USER,

  MAX_APP_NAME_LENGTH: 30,
  MAX_APP_ID_LENGTH: 30,
  MAX_APP_PACKAGE_NAME_LENGTH: 255,
  MAX_SHORT_DESCRIPTION_LENGTH: 100,
  MAX_DESCRIPTION_LENGTH: 1000,
  MAX_WHATS_NEW_LENGTH: 300,
  MAX_UNLIMITED_LENGTH: 9999,

  ANDROID_TYPE: ANDROID_TYPE,
  IOS_TYPE: IOS_TYPE,
  WEB_APP_TYPE: WEB_APP_TYPE,

  MAX_APP_FILE: 1,
  MAX_APP_IOS_FILE: 2,
  MAX_ICON_FILE: 1,
  MAX_BANNER_FILE: 1,
  MIN_SCREENSHOT_FILE: 4,
  MAX_SCREENSHOT_FILE: 10,

  URL_PROTOCOL: 0,
  URL_SEPARATOR_SPACE: 1,
  URL_SERVER_ADDRESS: 2,
  URL_SERVER_S3_BUCKET: 3,
  URL_TARGET: 4,
  URL_APP_NAME: 5,
  URL_APP_FILENAME: 6,
};

const LGPG_APP_REQUEST = {
  AWS_APP_REQUEST_QUERY: AWS_ADDRESS + '/apps/approval',

  PAGE_CARD_VIEW: 10,

  ANDROID_TYPE: ANDROID_TYPE,
  IOS_TYPE: IOS_TYPE,
  WEB_APP_TYPE: WEB_APP_TYPE,

  TEST_USER: TEST_USER,
  DEVELOPER_USER: DEVELOPER_USER,
  ANALYST_USER: ANALYST_USER,
  ADMIN_USER: ADMIN_USER,

  REQUEST: REQUEST,
  APPROVE: APPROVE,
  TEST_CLOSE: TEST_CLOSE,
  ADMIN_REJECT: ADMIN_REJECT,
  ADMIN_REMOVE: ADMIN_REMOVE,
  DEVELOPER_REMOVE: DEVELOPER_REMOVE,
}

const LGPG_APP_TEST_GROUP = {
  ALL:0,
  LGE:1,
  FUT:2
}

export const APP_GLOBAL = LGPG_APP_GLOBAL;
export const APP_LIST = LGPG_APP_LIST;
export const APP_DETAIL = LGPG_APP_DETAIL;
export const APP_REGISTER = LGPG_APP_REGISTER;
export const APP_REQUEST = LGPG_APP_REQUEST;
export const APP_TEST_GROUP = LGPG_APP_TEST_GROUP;
