/* eslint-disable */
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { faAngleDoubleLeft, faAngleDoubleRight, faAngleDown, faArrowLeft, faPen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Col, Container, Dropdown, Nav, Navbar, Pagination } from '@themesberg/react-bootstrap';
import axios from 'axios';
import qs from 'qs';

import "../../css/board.css";
import { PG_CONFIG } from "../../data/pgConfig";
import emp from '../../utils/emp';
import pg from "../../utils/pg";
import Post from "./Post";

function BoardMobile() {
  const history = useHistory();
  const [posts, setPosts] = useState([]);
  const [curPage, setCurPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isLoading, setLoading] = useState(false);
  const [category, setCategory] = useState("0");
  const [search, setSearch] = useState({ type: 1, text: "" });
  const [userId, setUserId] = useState();
  const page_limit = 10;
  const page_range_limit = 5;
  const [cloudFront, setCloudFront] = useState();

  const query = qs.parse(location.search, { ignoreQueryPrefix: true });
  //console.log('query:', query);

  const { access_token, board_id } = query;

  const [appIconMap, setAppIconMap] = useState(new Map());

  const upsert = (key, value) => {
    setAppIconMap((prev) => new Map(prev).set(key, value));
  }

  const onNextItem = async () => {
    if (curPage === totalPages) return;

    await pg.getBoardList(page_limit, curPage + 1, search.type, search.text, category)
      .then(res => {
        if (res) {
          setPosts(res);
        }
        setLoading(false);
      }).catch((err) => {
        //setError(err);
      }).finally(() => {
        setLoading(false);
      })

    setCurPage(curPage + 1);
  };

  const onPreviousItem = async () => {
    if (curPage === 1) return;

    await pg.getBoardList(page_limit, curPage - 1, search.type, search.text, category)
      .then(res => {
        if (res) {
          setPosts(res);
        }
        setLoading(false);
      }).catch((err) => {
        //setError(err);
      }).finally(() => {
        setLoading(false);
      })

    setCurPage(curPage - 1);
  };

  const onPageItem = async (page) => {
    await pg.getBoardList(page_limit, page, search.type, search.text, category)
      .then(res => {
        if (res) {
          setPosts(res);
        }
        setLoading(false);
      }).catch((err) => {
        //setError(err);
      }).finally(() => {
        setLoading(false);
      })

    setCurPage(page);
  };

  const loadItem = async () => {
    setLoading(true);
    await pg.getBoardList(page_limit, curPage, search.type, search.text, category)
      .then(res => {
        if (res) {
          setPosts(res);
        }

        let totalCount = res[0].total;
        let pageCnt;
        if (totalCount > 0) {
          let remainder = totalCount % page_limit;
          let result = parseInt(totalCount / page_limit);
          if (remainder > 0) {
            pageCnt = result + 1;
          } else {
            pageCnt = result;
          }
        } else {
          pageCnt = 1;
        }
        setTotalPages(pageCnt);
        setLoading(false);
      }).catch((err) => {
        //setError(err);
      }).finally(() => {
        setLoading(false);
      })
  }

  const PaginationItem = () => {
    let myPagination = [];
    let viewPageRange = 0;
    let viewPageRemainder = curPage % page_range_limit;
    let viewPageResult = parseInt(curPage / page_range_limit);

    if (viewPageRemainder > 0) {
      viewPageRange = viewPageResult + 1;
    } else {
      viewPageRange = viewPageResult;
    }

    for (let i = ((viewPageRange - 1) * page_range_limit) + 1; (i <= totalPages) && (i <= ((viewPageRange) * page_range_limit)); i++) {
      myPagination.push(
        <Pagination.Item key={i} active={curPage === i} onClick={() => onPageItem(i)}>{i}</Pagination.Item>
      );
    }

    return myPagination;
  }

  useEffect(() => {
    axios.get(PG_CONFIG.apiGatewayUrl + '/apps/list' + `?app_status=1&offset=0`).then(res => {
      setCloudFront(res.data.CloudFront);

      const appList = res.data.appList;
      for (let app of appList) {
        upsert(app.app_id, app.icon_file);
      }
    });
  }, []);

  useEffect(() => {
    if (access_token) {
      emp.getUserProfile(access_token).then(info => {
        console.log("debug - userId:", info.account.displayUserID);
        setUserId(info.account.displayUserID);
      }
      ).catch((reason) => console.log('Invalid access token!!', reason)/*alert('Invalid access token!!\n' + reason)*/);
    } else {
      console.log('No access token!!');
      //alert('No access token!!');
    }
  }, []);

  useEffect(() => {
    setLoading(true);

    pg.getBoardList(page_limit, curPage, 0, "", category)
      .then(res => {
        if (res) {
          setPosts(res);
        }

        let totalCount = res[0].total;
        let pageCnt;
        if (totalCount > 0) {
          let remainder = totalCount % page_limit;
          let result = parseInt(totalCount / page_limit);
          if (remainder > 0) {
            pageCnt = result + 1;
          } else {
            pageCnt = result;
          }
        } else {
          pageCnt = 1;
        }
        setTotalPages(pageCnt);
        setLoading(false);
      }).catch((err) => {
        //setError(err);
      }).finally(() => {
        setLoading(false);
      })
  }, [category]);

  return (
    <div>
      <Navbar className="sticky-top" style={{ 'background-color': '#f5f8fb' }}>
        <Container fluid className="position-relative justify-content-between px-3">
          <Col xs={8}>
            <h4 className="mb-0">
              <FontAwesomeIcon icon={faArrowLeft} className="me-2" onClick={() => { window.location.replace("/board/mobile?returnCode=400&returnDescription=cancel") }} />
              Community Board
            </h4>
          </Col>
          <Col xs={4} align="right">
            <h4 className="mb-0">
              {/*<FontAwesomeIcon icon={faBookmark} />
            <FontAwesomeIcon icon={faSearch} className="ms-3" />*/}
              <FontAwesomeIcon icon={faPen} className="ms-3" onClick={() => {
                history.push({
                  pathname: "/board/mobile/new",
                  state: {
                    userId: userId,
                  }
                })
              }} />
            </h4>
          </Col>
        </Container>
      </Navbar>

      <main className="content mt-2 mx-1">
        <Dropdown className="btn-toolbar mb-2" onSelect={(eventKey) => { setCategory(eventKey) }}>
          <Dropdown.Toggle as={Button} variant="outline-gray-900" size="sm" className="me-2">
            {category === "1" ? "개선제안" :
              category === "2" ? "문의" :
                category === "3" ? "오류" :
                  category === "4" ? "기타" : "전체"
            }
            <FontAwesomeIcon icon={faAngleDown} className="ms-2" />
          </Dropdown.Toggle>
          <Dropdown.Menu className="dashboard-dropdown dropdown-menu-left mt-2">
            <Dropdown.Item className="fw-bold" eventKey="0">
              전체
            </Dropdown.Item>
            <Dropdown.Item className="fw-bold" eventKey="1">
              개선제안
            </Dropdown.Item>
            <Dropdown.Item className="fw-bold" eventKey="2">
              문의
            </Dropdown.Item>
            <Dropdown.Item className="fw-bold" eventKey="3">
              오류
            </Dropdown.Item>
            <Dropdown.Item className="fw-bold" eventKey="4">
              기타
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>

        {posts.map(({ id, title, contents, author, date, views, likes, category, appId, commentCount, deviceType }) => (
          <div key={id}
            onClick={() => {
              history.push({
                pathname: "/board/mobile/detail",
                state: {
                  userId: userId,
                  id: id,
                  title: title,
                  contents: contents,
                  author: author,
                  date: date,
                  category: category,
                  views: views + 1,
                  likes: likes,
                  commentCount: commentCount,
                  deviceType: deviceType,
                  iconPath: appIconMap.get(appId) ? cloudFront + "/" + appIconMap.get(appId) : undefined
                }
              })

              pg.updatePostViews(id);
            }}>
            <Post
              key={id}
              id={id}
              title={title}
              contents={contents}
              author={author}
              date={date}
              views={views}
              likes={likes}
              category={category}
              commentCount={commentCount}
              iconPath={appIconMap.get(appId) ? cloudFront + "/" + appIconMap.get(appId) : undefined}
            />
          </div>
        ))}
        <Nav className="justify-content-center">
          <Pagination className="mb-2 mb-lg-0">
            <Pagination.Prev onClick={() => onPreviousItem()} title="Prev">
              <FontAwesomeIcon icon={faAngleDoubleLeft} />
            </Pagination.Prev>
            <PaginationItem />
            <Pagination.Next onClick={() => onNextItem()} title="Next">
              <FontAwesomeIcon icon={faAngleDoubleRight} />
            </Pagination.Next>
          </Pagination>
        </Nav>
      </main>
    </div>
  );
}

export default BoardMobile;
