import React, { Component } from 'react';
import { Button, Container, Row, Card, Form, Modal } from '@themesberg/react-bootstrap';
import axios from 'axios';
import { PG_CONFIG } from "../../data/pgConfig";
import pg from "../../utils/pg";
import { SURVEY_STATUS, SURVEY_USER_TYPE } from "./const/surveyConst";
import auth from "../../utils/auth";

import { Breadcrumb } from '@themesberg/react-bootstrap';
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import SurveySummaryItem from './components/SurveySummaryItem';


export default class SurveySummary extends Component {
  state = {
    survey_header: {
      survey_title: "",
      survey_description: ""
    },
    app_id:"",
    app_name: "",
    survey_id:0,
    period: "",
    running_state: "",
    request_id: "",
    summary_list: [],
    response_count:0,
    delete_modal: {
      title: "",
      message: "",
      message2: "",
      confirm_btn : false,
      open: false,
    },
    alert_modal: {
      title: "",
      message: "",
      go_back: false,
      open: false,
    },
    remind_modal: {
      title: "",
      message: "",
      message2: "",
      confirm_btn : false,
      open: false,
    },
    remind_alert_message:""
  }

  loadItem = async () => {
    const id = this.props.location.state.id;

    try {
      const response = await axios.get(PG_CONFIG.apiGatewayUrl + '/survey/response', {
        params: {
          survey_id: id
        },
        timeout: 2000
      });

      //응답 데이터 취합하기
      let questionList = Array.from(this.props.location.state.question_list);
      console.log(response.data);

      for (let surveyData of response.data) {
        let surveyContents = JSON.parse(surveyData.survey_contents);
        for (let ans of surveyContents.survey_list) {
          //console.log(ans);
          for (let sel of ans.selected) {
            //questionList[ans.id-1].select.push(sel);
            questionList[ans.id - 1].answer[sel - 1].amount = questionList[ans.id - 1].answer[sel - 1].amount + 1;
          }
          if (ans.comment !== "") {
            questionList[ans.id - 1].comment.push(ans.comment);
          }
        }
      }
      //console.log(questionList);

      //취합 데이터 set
      this.setState({
        survey_header: {
          survey_title: this.props.location.state.survey_header.survey_title,
          survey_description: this.props.location.state.survey_header.survey_description
        },
        app_id: this.props.location.state.app_id,
        app_name: this.props.location.state.app_name,
        survey_id: this.props.location.state.id,
        period: this.props.location.state.period,
        running_state : this.props.location.state.running_state,
        request_id: this.props.location.state.request_id,
        summary_list: questionList,
        response_count: response.data.length
      })
    } catch (error) {
      console.log(error);
    }
  }

  componentDidMount() {
    this.loadItem();
  }

  remindPushHandler = async () => {
    console.log(this.state.survey_id);
    if (this.state.running_state === "종료") {
      this.setState({
        alert_modal: {
          title: '설문 참여 알림 오류',
          message: '이미 종료된 설문입니다.',
          go_back: false,
          open: true
        }
      });
    } else if (this.state.running_state === "대기") {
      this.setState({
        alert_modal: {
          title: '설문 참여 알림 오류',
          message: '설문 기간이 아닙니다.',
          go_back: false,
          open: true
        }
      });
    } else {
      //Remind Model Open
      let modalTitle = "설문 참여 알림";
      let modalMessage = "설문 참여 알림을 보낼까요? 내용을 작성해 주시면 작성된 내용으로 전송됩니다."
      let modalMessage2 = "내용이 없으면, 기본 내용으로 전송됩니다."

      try {
        this.setState({
          remind_modal: {
            title: modalTitle,
            message: modalMessage,
            message2 : modalMessage2,
            confirm_btn: true,
            open: true
          }
        });
      } catch (error) {
        console.log(error);
        this.setState({
          remind_modal: {
            title: '설문 참여 알림 오류',
            message: '설문 참여 알림 요청 중 오류가 발생되었습니다.',
            confirm_btn: false,
            open: true
          }
        });
      }
    }
  }

  remindConfirmHandler = async () => {
    let checkMsg = this.state.remind_alert_message.split(' ').join('');
    checkMsg = checkMsg.split('\n').join('');
    let remindMsg = (checkMsg === "")
      ? (this.state.app_id.split(':')[0] === 'general')
        ? "'" + this.state.survey_header.survey_title + "' 설문에 참여 부탁드립니다.\n이미 설문에 참여하신 경우에는 다시 참여하실 필요 없습니다."
        : "'" + this.state.app_name + "' 앱의 '" + this.state.survey_header.survey_title + "' 설문에 참여 부탁드립니다.\n이미 설문에 참여하신 경우에는 다시 참여하실 필요 없습니다."
      : this.state.remind_alert_message;

    try {
      await pg.sendFCMPush(
        "설문 참여 알림",
        remindMsg,
        "survey",
        "https://lgplayground.lge.com/survey/response",
        this.state.app_id,
        this.state.survey_id
      );

      this.setState({
        remind_modal: {
          open: false
        },
        alert_modal: {
          title: '설문 참여 알림',
          message: '설문 참여 알림이 발송되었습니다.',
          go_back: false,
          open: true
        }
      });
    } catch (error) {
      console.log(error);
      this.setState({
        remind_modal: {
          open: false
        },
        alert_modal: {
          title: '설문 참여 알림 오류',
          message: '설문 참여 알림 요청 중 오류가 발생되었습니다.',
          go_back: false,
          open: true
        }
      });
    }
  }

  remindModalClose = () => {
    this.setState({
      remind_modal: {
        open: false
      }
    });
  }

  inputRemindMsgHandler = (e) => {
    console.log(e.target.value);
    this.setState({
      remind_alert_message : e.target.value
    });
  }

  // Delete Modal Handler
  deleteModalHandler = async () => {
    let modalTitle = "설문 결과 삭제";
    let modalMessage = "설문 결과를 삭제할까요?";

    if (this.state.running_state === "진행중") {
      modalTitle = '설문 결과 삭제';
      modalMessage = '진행중인 설문 입니다. 설문 결과를 삭제할까요?'
    } else if (this.state.running_state === "종료") {
      modalTitle = '설문 결과 삭제';
      modalMessage = '종료된 설문 입니다. 설문 결과를 삭제할까요?'
    } else {
      modalTitle = '설문 삭제';
      modalMessage = '대기중인 설문 입니다. 설문을 삭제할까요?'
    }
    console.log(modalMessage);

    try {
      this.setState({
        delete_modal: {
          title: modalTitle,
          message: modalMessage,
          message2: '설문 : ' + this.state.survey_header.survey_title,
          confirm_btn: true,
          open: true
        }
      });
    } catch (error) {
      console.log(error);
      this.setState({
        delete_modal: {
          title: '설문 삭제 오류',
          message: '삭제할 설문 확인 중 오류가 발생되었습니다.',
          message2: '설문 : ' + this.state.survey_header.survey_title,
          confirm_btn: false,
          open: true
        }
      });
    }
  }

  deleteConfirmHandler = () => {
    try {
      // Database 에서 해당 설문 삭제 (survey_list table (list) 만 삭제. survey_response tabe은 남겨놓음.)
      console.log("survey id: ", this.props.location.state.id);
      /*
      this.setState({
        delete_modal: {
          open: false
        },
        alert_modal: {
          title: '설문 삭제 완료',
          message: this.state.survey_header.survey_title + '(' + this.props.location.state.id + ')' + ' 결과가 삭제되었습니다.',
          open: true
        }
      });
      */
      axios.post(PG_CONFIG.apiGatewayUrl + "/survey/remove/" + this.props.location.state.id)
      .then(response => {
        this.setState({
          delete_modal: {
            open: false
          },
          alert_modal: {
            title: '설문 삭제 완료',
            message: this.state.survey_header.survey_title + ' 결과가 삭제되었습니다.',
            go_back: true,
            open: true
          }
        });
      })
      .catch(error => {
        console.log(error);
        this.setState({
          delete_modal: {
            open: false
          },
          alert_modal: {
            title: '설문 삭제 오류',
            message: this.state.survey_header.survey_title + ' 삭제중 오류가 발생되었습니다.',
            go_back: true,
            open: true
          }
        });
      });
    } catch (error) {
      console.log(error);
      this.setState({
        delete_modal: {
          open: false
        },
        alert_modal: {
          title: '설문 삭제 오류',
          message: this.state.survey_header.survey_title + ' 삭제중 오류가 발생되었습니다.',
          go_back: true,
          open: true
        }
      });
    }
    // Close delete_modal and Open alert_modal
    /*
    this.setState({
      delete_modal: {
        open: false
      },
      alert_modal: {
        title: '설문 삭제 완료',
        message: this.state.survey_header.survey_title + ' 결과가 삭제되었습니다.',
        open: true
      }
    })
    */
  }

  deleteModalClose = () => {
    this.setState({
      delete_modal: {
        open: false
      }
    })
  }

  //Alert Modal hanler
  alertModalClose = () => {
    this.setState({
      alert_modal: {
        open: false
      }
    })

    if(this.state.alert_modal.go_back === true) {
      this.props.history.goBack();
    }
  }

  render() {
    //console.log(this.state);
    var userInfo = auth.getUserInfo();
    return (
      <div>
        <Container fluid className="main-content-container" style={{ marginBottom: "15px" }}>
          {/* Page Header */}
          <Row noGutters className="page-header py-4">
            {/*<PageTitle sm="4" title="Survey Summary" subtitle="Survey" className="text-sm-left" />*/}
            <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
              <Breadcrumb.Item><FontAwesomeIcon icon={faHome} /></Breadcrumb.Item>
              <Breadcrumb.Item>Survey</Breadcrumb.Item>
              <Breadcrumb.Item active>Summary</Breadcrumb.Item>
            </Breadcrumb>
            <h4>Survey Summary</h4>
            <p className="mb-0">
              { }
            </p>
          </Row>
          <Container> {/* 폭 고정 틀*/}
            <Card border="light" className="shadow-sm mb-1">
              <Card.Header className="border-bottom border-light justify-content-between">
                <span className="d-block">
                  <h4 className="text-primary align-top">{this.state.survey_header.survey_title}</h4>
                  <h6 className="text-primary align-top"><span style={{ fontWeight: "normal" }}>{this.state.survey_header.survey_description}</span></h6>
                  <hr/>
                  <h6 className="text-primary align-top">설문기간 : <span style={{ fontWeight: "normal" }}>{this.state.period}</span></h6>
                  <h6 className="text-primary align-top">설문대상 : <span style={{ fontWeight: "normal" }}>
                    {
                      this.state.app_name
                      ? ((this.state.app_id.split(':')[0] === 'lgpg') || (this.state.app_id.split(':')[0] === 'general'))
                        ? this.state.app_name
                        : this.state.app_name + ' (' + this.state.app_id.split(':')[0] + ')'
                      : "-"
                      /* this.state.app_name + ' (' + this.state.app_id.split(':')[0] + ')' */
                    }
                  </span></h6>
                  <h6 className="text-primary align-top">작성자 : <span style={{ fontWeight: "normal" }}>{this.state.request_id}</span></h6>
                  <h6 className="text-primary align-top"><span style={{ color: "red" }}>총 응답수 : <span style={{ fontWeight: "normal" }}>{this.state.response_count}</span></span></h6>
                </span>
              </Card.Header>
            </Card>
            <ol style={{ paddingInlineStart: "0px" }}>
              {this.state.summary_list.map((summaryItem, index) => (
                <li style={{ listStyleType: "none", marginBottom: "5px" }} key={index}>
                  <SurveySummaryItem data={summaryItem} idx={index} />
                </li>
              ))}
            </ol>
            <Form.Group>
              <div className="mt-2 mb-4">
                <div className="text-right" style={{ marginRight: "20px" }}>
                  {((userInfo.userType === SURVEY_USER_TYPE.ADMIN) || (userInfo.userType === SURVEY_USER_TYPE.DEVELOPER)) ?
                  <Button outline="true" size="md" style={{ width: "120px" }} variant="warning" className="mb-2 mr-1 me-2" id="remind" name="remind"
                    onClick={this.remindPushHandler.bind(this)}>다시 알림</Button> : <></>}
                  <Button outline="true" size="md" style={{ width: "120px" }} variant="danger" className="mb-2 mr-1 me-2" id="delete" name="delete"
                    onClick={this.deleteModalHandler.bind(this)}>삭제</Button>
                  <Button outline="true" size="md" style={{ width: "120px" }} theme="primary" className="mb-2 mr-1 me-2" id="confirm" name="confirm"
                    onClick={() => { this.props.history.goBack() }}>확인</Button>
                </div>
              </div>
            </Form.Group>
          </Container>

          {/* Delete Modal */}
          <Modal as={Modal.Dialog} centered show={this.state.delete_modal.open}>
            <Modal.Header>
              <Modal.Title className="h6">{this.state.delete_modal.title}</Modal.Title>
              <Button variant="close" aria-label="Close" onClick={() => {this.deleteModalClose();}} />
            </Modal.Header>
            <Modal.Body>
              <p>{this.state.delete_modal.message}</p>
              <p>{this.state.delete_modal.message2}</p>
            </Modal.Body>
            <Modal.Footer>
              {this.state.delete_modal.confirm_btn?<Button variant="secondary" onClick={() => {this.deleteConfirmHandler();}}>확인</Button>:<></>}
              <Button variant="link" className="text-gray ms-auto" onClick={() => {this.deleteModalClose();}}>닫기</Button>
            </Modal.Footer>
          </Modal>

          {/* Alert Modal */}
          <Modal as={Modal.Dialog} centered show={this.state.alert_modal.open}>
            <Modal.Header>
              <Modal.Title className="h6">{this.state.alert_modal.title}</Modal.Title>
              <Button variant="close" aria-label="Close" onClick={() => {this.alertModalClose();}} />
            </Modal.Header>
            <Modal.Body>
              <p>{this.state.alert_modal.message}</p>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="link" className="text-gray ms-auto" onClick={() => {this.alertModalClose();}}>닫기</Button>
            </Modal.Footer>
          </Modal>

          {/* Remind Modal */}
          <Modal as={Modal.Dialog} centered show={this.state.remind_modal.open}>
            <Modal.Header>
              <Modal.Title className="h6">{this.state.remind_modal.title}</Modal.Title>
              <Button variant="close" aria-label="Close" onClick={() => {this.remindModalClose();}} />
            </Modal.Header>
            <Modal.Body>
              <p>{this.state.remind_modal.message}</p>
              <p>{this.state.remind_modal.message2}</p>
              <p>
                <Form.Control
                  as="textarea"
                  placeholder={
                    (this.state.app_id.split(':')[0] === 'general')
                      ? "'" + this.state.survey_header.survey_title + "' 설문에 참여 부탁드립니다.\n이미 설문에 참여하신 경우에는 다시 참여하실 필요 없습니다."
                      : "'" + this.state.app_name + "' 앱의 '" + this.state.survey_header.survey_title + "' 설문에 참여 부탁드립니다.\n이미 설문에 참여하신 경우에는 다시 참여하실 필요 없습니다."
                  }
                  rows="3"
                  style={{ resize:"none" }}
                  onChange={this.inputRemindMsgHandler.bind(this)} />
              </p>
            </Modal.Body>
            <Modal.Footer>
              {this.state.remind_modal.confirm_btn?<Button variant="secondary" onClick={() => {this.remindConfirmHandler();}}>전송</Button>:<></>}
              <Button variant="link" className="text-gray ms-auto" onClick={() => {this.remindModalClose();}}>닫기</Button>
            </Modal.Footer>
          </Modal>
        </Container>
      </div>
    );
  }
}