import { faAndroid, faApple } from "@fortawesome/free-brands-svg-icons";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Col, Container, Navbar, Row } from '@themesberg/react-bootstrap';
import React from "react";
import { HashLink } from 'react-router-hash-link';

export default () => {

  return (
    <div>
      <Navbar variant="dark" expand="lg" bg="white" className="navbar-transparent navbar-theme-primary sticky-top">
        <Container fluid className="position-relative justify-content-between px-3">
          <Navbar.Brand as={HashLink} to="#" className="me-lg-3 d-flex align-items-center">
            {/*<Image src={ReactHero} />*/}
            <span className="ms-2 brand-text d-md-inline">LG PLAYGROUND</span>
          </Navbar.Brand>

          <div className="d-flex align-items-center">
            {/*<Navbar.Collapse id="navbar-default-primary">
              <Nav className="navbar-nav-hover align-items-lg-center">
                <Nav.Link as={HashLink} to="#features">Features</Nav.Link>
                <Nav.Link as={HashLink} to="#pages">Pages</Nav.Link>
                <Nav.Link as={HashLink} to="#folder" className="d-sm-none d-xl-inline">Folder Structure</Nav.Link>
                <Nav.Link as={HashLink} to="#getting-started">Getting Started</Nav.Link>
                <Nav.Link as={HashLink} to="#download">Upgrade to Pro</Nav.Link>
              </Nav>
              </Navbar.Collapse>
            <Button as={Link} to="/app/list" variant="outline-primary" className="ms-3"><FontAwesomeIcon icon={faSignInAlt} className="d-none d-sm-inline me-1" /> Login</Button>*/}
          </div>
        </Container>
      </Navbar>
      <div className="intro-mobile" style={{ height: "92vh" }}>
        <div className="overlay-mobile" style={{ height: "92vh" }}>
          <section className="section-header overflow-hidden text-white pt-3" id="home">
            <Row>
              <Col xs={12} className="text-center mt-5 ps-5">
                <h1 style={{ fontSize: "2rem" }} className="text-left fw-bolder">WELCOME TO</h1>
                <h1 style={{ fontSize: "2rem" }} className="text-left fw-bolder">LG PLAYGROUND</h1>
                <p className="text-left fw-bolder mt-4 h4">LG Playground를 설치하면</p>
                <p className="text-left fw-bolder h4">플랫폼사업센터의 새로운 서비스를</p>
                <p className="text-left fw-bolder h4">미리 만나볼 수 있습니다</p>
              </Col>

              <div className="pt-5 mt-2" align="center">
                <Button className="text-white me-2" variant="tertiary" style={{ width: "115px" }}>
                  <a href='https://d3hw8j3d2xi1v9.cloudfront.net/installer/android/LGPlayground.apk'>
                    <FontAwesomeIcon icon={faAndroid} className="d-sm-inline ms-1" /> Android
                  </a>
                </Button>
                <Button className="text-black ms-2" variant="light" style={{ width: "115px" }}>
                  <a href='itms-services://?action=download-manifest&url=https://d3hw8j3d2xi1v9.cloudfront.net/installer/ios/manifest.plist'>
                    <FontAwesomeIcon icon={faApple} className="d-sm-inline ms-1" /> iOS
                  </a>
                </Button>
              </div>
              <Col xs={12} className="text-center mt-4">
                <div style={{textShadow: "-1px 0 #000, 0 1px #000, 1px 0 #000, 0 -1px #000"}}>
                  <FontAwesomeIcon icon={faExclamationCircle} className="d-sm-inline me-1" />
                  <span className="fw-bolder h6">설치 가이드 (</span>
                  <span className="text-underline fw-bolder h6" onClick={() => { window.open("/guide?type=android") }}>Android</span>
                  <span className="fw-bolder h6"> / </span>
                  <span className="text-underline fw-bolder h6" onClick={() => { window.open("/guide?type=ios") }}>iOS</span>
                  <span className="fw-bolder h6">)</span>
                </div>
              </Col>
            </Row>
          </section>
        </div>
      </div>
    </div>
  );
};
