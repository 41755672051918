import React, { useEffect, useRef, useState } from "react";
import { Link, Redirect } from 'react-router-dom';

import { faAppStore, faGooglePlay, faHtml5 } from '@fortawesome/free-brands-svg-icons';
import { faCalendarAlt, faHome, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Breadcrumb, Button, Card, Col, Container, Dropdown, Form, InputGroup, Modal, Row, Table } from '@themesberg/react-bootstrap';
import { Routes } from "../../routes";

import moment from "moment-timezone";
import Datetime from "react-datetime";
import ReactQuill from "react-quill";
import FileAttach from "./FileAttach";

import axios from 'axios';
import auth from "../../utils/auth";
import gadget from "../../utils/gadget";
import { PG_CONFIG } from "../../data/pgConfig";

import { APP_GLOBAL, APP_REGISTER, APP_TEST_GROUP } from './const/AppConst';

import 'react-quill/dist/quill.snow.css';
import "../../css/register.css";


function Register(props) {
  const entryPoint = gadget.isEmpty(props.location.state) ? APP_GLOBAL.ENTRY_POINT_SIDEBAR : props.location.state.entryPoint;
  const appDetails = entryPoint ? { download_cnt: 0 } : { };

  const [screenRendering, setRendering] = useState(true);

  // Fileupload / Error Modal
  const [getUploadModal, setUploadModal] = useState({ open: false, title: '', message: '' });
  const [getErrorModal, setErrorModal] = useState({ open: false, title: '', message: '' });

  const AWSS3Path = useRef({ });
  const menuEntryPoint = useRef(entryPoint);
  const inputContent = useRef(appDetails);
  // const appNameCheckPass = useRef(false);

  const [testGroupType, setTestGroupType] = useState(0);
  const [testGroupCandidate, setTestGroupCandidate] = useState([]);
  const [selectedTestGroupList, setSelectedTestGroupList] = useState([]);
  const [reloadGroup, setReloadGroup] = useState(false);

  const handleInputEdit = async (event, length) => {
    if (event.currentTarget == null
        || event.currentTarget.value.length > length) {
      return;
    }

    let getString = event.currentTarget;
    let key = getString.name;

    inputContent.current = {...inputContent.current, [key]: getString.value};
  }

  const handleEditDescription = (content, length) => {
    inputContent.current = {...inputContent.current, description: content.getHTML()};
  }

  const handleEditWhatsNew = (content, length) => {
    if (content.length >= length) {
      return;
    }

    inputContent.current = {...inputContent.current, whats_new: content.getHTML()};
  }

  const handleSetAppType = async (event) => {
    let appType = event.target.name;

    inputContent.current = {
      ...inputContent.current,
      package_name: '',
      activity_name: '',
      url_schema: '',
      app_type: (appType === 'android') ? APP_REGISTER.ANDROID_TYPE : (appType === 'ios') ? APP_REGISTER.IOS_TYPE : APP_REGISTER.WEB_APP_TYPE
    };

    setRendering(!screenRendering);
  }

  const handleSetDeptType = (event) => {
    setTestGroupType(Number(event.target.value));
    setSelectedTestGroupList([]);
    setReloadGroup(!reloadGroup);
  }

  const handleSelectDept = (event) => {
    if (selectedTestGroupList.includes(event.target.options[event.target.selectedIndex].text)) {
      console.log("이미 그룹이 포함되어 있습니다.")
    } else {
      setSelectedTestGroupList([...selectedTestGroupList, event.target.options[event.target.selectedIndex].text]);
    }
  }

  const DeptSelectTag = () => {
    const OptionRow = (request) => {
      return (
        <option value={request.id}>{request.group_name}</option>
      )
    }

    return (
      <Form.Select value={APP_TEST_GROUP.ALL} onChange={handleSelectDept}>
        <option value="0">테스트 그룹을 선택하세요.</option>
        {testGroupCandidate.map(c => <OptionRow key={c.id} {...c} />)}
      </Form.Select>
    )
  }

  const removeSelectedTestGroup = (name) => {
    setSelectedTestGroupList(selectedTestGroupList.filter((element) => element !== name));
  }

  const SelectedTestGroupTag = () => {
    let selGroupTag = [];
    for (let selGroup of selectedTestGroupList) {
      selGroupTag.push(
        <tr className="border-bottom">
          <td>{selGroup}</td>
          <td className="border-0 float-end">
            <FontAwesomeIcon icon={faTrash} style={{cursor:"hand", marginRight:"10px", marginLeft:"10px"}} title="삭제" onClick={(name) => {removeSelectedTestGroup(selGroup)}} />
          </td>
        </tr>);
    }

    return (
      <Table responsive className="table-centered table-nowrap rounded mb-0 mt-2">
        <thead className="thead-light">
          <tr>
            <th className="border-0">Selected Group</th>
            <th className="border-0"></th>
          </tr>
        </thead>
        {
          selectedTestGroupList.length <= 0 ?
            <tbody>
              <tr>
                <td colSpan="2">
                  <div>선택된 테스트 그룹이 없습니다.</div>
                </td>
              </tr>
            </tbody>
          :
            <tbody>
              {selGroupTag}
            </tbody>
        }
      </Table>
    )
  }

  useEffect(() => {
    let groupParams = null;

    if (testGroupType === 1) {
      groupParams = {
        params : {
          group_type:APP_TEST_GROUP.LGE
        }
      }
    } else if (testGroupType === 2) {
      groupParams = {
        params : {
          group_type:APP_TEST_GROUP.FUT
        }
      }
    }

    try {
      axios.get(PG_CONFIG.apiGatewayUrl + '/group/list', groupParams)
      .then(response => {
        setTestGroupCandidate(response.data);
      })
      .catch(error => {
        console.log("그룹 목록 가져오기 에러");
      })
    } catch (error) {
      console.log("그룹 목록 가져오기 에러");
    }
  }, [reloadGroup]);


  const getTarget = (target) => {
    return (target === APP_REGISTER.ANDROID_TYPE) ? 'android' : (target === APP_REGISTER.IOS_TYPE) ? 'ios' : 'webapp';
  }

  const getConvertFilename = (filename, postFilename) => {
    var _fileLen = filename.length;
    var _lastSlash = filename.lastIndexOf('/') + 1;
    var _lastDot = filename.lastIndexOf('.');

    return filename.substring(_lastSlash, _lastDot) + '_' + postFilename + filename.substring(_lastDot, _fileLen)
  };

  const isValidFileCheck = (dbField, attachedFile) => {
    let fileList = [];

    attachedFile.app_file.forEach((file) => {
      fileList.push(file.path.split('?')[0]);
    })

    switch(dbField.app_type) {
      case APP_REGISTER.ANDROID_TYPE:
        if (fileList.length !== 1
            || fileList.filter((file) => file.match(/(.apk)/)).length === 0) {
          return false;
        }
        break;

      case APP_REGISTER.IOS_TYPE:
        if (fileList.length !== 2
            || fileList.filter((file) => file.match(/(.ipa)$/)).length === 0
            || fileList.filter((file) => file.match(/(.plist)$/)).length === 0) {
          return false;
        }
        break;

      case APP_REGISTER.WEB_APP_TYPE:
        if (fileList.length !== 1
            || fileList.filter((file) => file.match(/(.zip)/)).length === 0) {
          return false;
        }
        break;

      default:
      break;
    }

    return true;
  }

  const isValidCheck = (dbField, attachedFile) => {
    let error = true;
    let message;

    if (gadget.isEmpty(dbField.test_period)
        || dbField.test_period <= new Date().getTime()) {
      message = '테스트 기간을 확인 확인해주세요.'
    } else if (gadget.isEmpty(dbField.app_name)) {
      message = '앱 이름이 입력되지 않았습니다.';
    } else if (gadget.isEmpty(dbField.app_version)) {
      message = 'App version이 입력되지 않았습니다.'
    } else if (gadget.isEmpty(dbField.app_type)) {
      message = 'App type이 설정되지 않았습니다.'
    } else if (gadget.isEmpty(attachedFile.app_file)) {
      message = '앱 파일이 추가되지 않았습니다.'
    } else if (!isValidFileCheck(dbField, attachedFile)) {
      message = '앱 파일을 확인해 주세요.';
    } else if (gadget.isEmpty(attachedFile.icon_file)) {
      message = 'App icon 파일이 추가되지 않았습니다.'
    } else if (gadget.isEmpty(attachedFile.screenshot)) {
      message = 'Screenshot 파일이 추가되지 않았습니다.'
    } else if (attachedFile.screenshot.length < APP_REGISTER.MIN_SCREENSHOT_FILE || attachedFile.screenshot.length > APP_REGISTER.MAX_SCREENSHOT_FILE) {
      message = 'Screenshot 파일 개수를 확인해 주세요.'
    } else if (dbField.test_dept_type === APP_TEST_GROUP.LGE && gadget.isEmpty(dbField.lge_dept)) {
      message = 'LGE 테스트 그룹이 선택되지 않았습니다.'
    } else if (dbField.test_dept_type === APP_TEST_GROUP.FUT && gadget.isEmpty(dbField.fut_dept)) {
      message = 'FUT 테스트 그룹이 선택되지 않았습니다.'
    } else {
      error = false;
    }

    if (error) {
      setErrorModal({
        open: true,
        title: 'Error',
        message: message
      });

      return false;
    }

    return true;
  }

  const modifyPlist = async (file, path) => {
    let xmlString = await fileToString(file, 'utf8');
    const replaceString = process.env.REACT_APP_CLOUD_FRONT_ADDRESS + "/" + path;
    xmlString = replaceStringAfterKey(xmlString, replaceString);

    const blob = new Blob([xmlString], { type: "application/xml" });
    const new_file = new File([blob], file.name, {
      type: "application/xml",
    });
    return new_file;
  };

  const fileToString = async (file) => {
    const reader = new FileReader();
    reader.readAsText(file);
    return new Promise((resolve, reject) => {
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.onerror = reject;
    });
  }

  const replaceStringAfterKey = (str, newContent) => {
    const regex = /(<key>url<\/key>\s*<string>)([\s\S]*?)(<\/string>)/g;
    return str.replace(regex, `$1${newContent}$3`);
  }

  const appUpload = async () => {
    let selectedTestGroupStr = selectedTestGroupList.join(';');
    let selectedLgeDept = "";
    let selectedFutDept = "";
    if (testGroupType === APP_TEST_GROUP.LGE) {
      selectedLgeDept = selectedTestGroupStr;
    } else if (testGroupType === APP_TEST_GROUP.FUT) {
      selectedFutDept = selectedTestGroupStr;
    }
    inputContent.current = {
      ...inputContent.current,
      test_dept_type: testGroupType,
      lge_dept: selectedLgeDept,
      fut_dept: selectedFutDept,
    };

    let setDbField = inputContent.current;
    let attachFiles = [];

    if (!isValidCheck(setDbField, AWSS3Path.current)) {
      return;
    }

    const appType = getTarget(setDbField.app_type)
    // 띄어쓰기를 _로 변경, 알파벳, 숫자, 띄어쓰기, 한글만 허용하고 나머지 문자 제거
    const appFolderName = setDbField.app_name.replace(/\s+/g, '_').replace(/[^\w\s\uAC00-\uD7A3]/gi, '').toLowerCase();
    const today = new Date().getTime();

    // delete setDbField.id;
    setDbField.app_status = 0;
    setDbField.register_date = today;
    setDbField.request_id = auth.getUserID();
    let department = auth.getUserInfo().userDepartment;
    setDbField.department = department ? department.split(";")[0] : "";

    let ipa_file_name;
    for (let file of AWSS3Path.current['app_file']) {
      if (file.path) {
        let getUrl = file.path.split('?')[0];
        if (getUrl.match(/(.ipa)$/)) {
          ipa_file_name = file.name;
        }
      }
    }

    for (let type of ['app_file', 'icon_file', 'banner_file', 'screenshot']) {
      if (AWSS3Path.current[type].length >= 1) {
        if (gadget.isEmpty(AWSS3Path.current[type][0].path)) {
          continue;
        }

        let fileList = [];
        for (let file of AWSS3Path.current[type]) {
          if (file.uploaded) {
            // 파일이 AWS에 Upload 되어 있는 파일의 경우 https://~~~ 에서 경로만 (android|ios|webapp/app name/~~~) 추출.
            let getUrl = file.path.split('?')[0];
            fileList.push(decodeURIComponent(getUrl.match(/(android|ios|webapp)[/][\w\W]*[/][\w\W.]*/g)[0]));
          } else {
            let getUrl = file.path.split('?')[0];
            if (getUrl.match(/(.plist)$/)) {
              fileList.push(appType + '/' + appFolderName + '/' + getConvertFilename(file.name, today));
              attachFiles.push(await modifyPlist(file, appType + '/' + appFolderName + '/' + getConvertFilename(ipa_file_name, today)));
            } else if (getUrl.match(/(.ipa)$/)) {
              //fileList.push(appType + '/' + appFolderName + '/' + getConvertFilename(file.name, today));
              attachFiles.push(file);
              setDbField['app_size'] = file.app_size;
            } else {
              fileList.push(appType + '/' + appFolderName + '/' + getConvertFilename(file.name, today));
              attachFiles.push(file);

              if (type === 'app_file') {
                setDbField['app_size'] = file.app_size;
              }
            }
          }
        }

        setDbField[type] = fileList;
      }
    }

    try {
      let appTypeIcon = inputContent.current.app_type === APP_REGISTER.ANDROID_TYPE ? faGooglePlay : inputContent.current.app_type === APP_REGISTER.IOS_TYPE ? faAppStore : faHtml5;

      if (attachFiles.length !== 0) {
        let uploadComplete = new Set();

        for (let file of attachFiles) {
          const uploadFilename = appType + '/' + appFolderName + '/' + getConvertFilename(file.name, today);
          let presignedURL = await axios.get(APP_REGISTER.AWS_APP_REGISTER_QUERY  + `?upload_url=${uploadFilename}`);
          presignedURL = presignedURL.data.presignedURL;

          await axios.put(presignedURL, file, {
            onUploadProgress: (progressEvent) => {
              let title;
              let message;
              let button;
              let percentage;

              title = '등록 중...';
              message = `${setDbField.app_name} 앱이 등록 중 입니다.`;
              button = '취소';
              percentage = Math.round((progressEvent.loaded / progressEvent.total) * 100);

              if (percentage >= 100) {
                uploadComplete.add(file.name);

                if (uploadComplete.size === attachFiles.length) {
                  title = '등록 완료...';
                  message = `${setDbField.app_name} 앱이 등록 되었습니다.`;
                  button = '확인';
                }
              }

              setUploadModal({
                open: true,
                icon: appTypeIcon,
                title: title,
                message: message,
                filename: file.name,
                uploadedFileCnt: uploadComplete.size,
                totalFileCnt: attachFiles.length,
                totalProgress: Math.round((uploadComplete.size / attachFiles.length) * 100),
                progress: percentage,
                button: button
              });
            }
          });
        }
      } else {
        setUploadModal({
          open: true,
          icon: appTypeIcon,
          title: '등록 완료...',
          message: `${setDbField.app_name} 앱이 등록 되었습니다.`,
          button: '확인'
        });
      }

      await axios.post(APP_REGISTER.AWS_APP_REGISTER_QUERY, {
        DBField: setDbField,
        entryPoint: entryPoint
      });
    } catch (error) {
      setErrorModal({
        open: true,
        title: 'Error',
        message: `${error.message} ${error.response.data}`
      });
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(APP_REGISTER.AWS_APP_DETAIL_QUERY + `?id=${props.location.state.id}`);

        let appDetail = response.data.appDetails;
        let awsPath = response.data.S3Path;
        inputContent.current = appDetail;

        setTestGroupType(inputContent.current.test_dept_type);
        if (inputContent.current.test_dept_type === APP_TEST_GROUP.LGE) {
          setSelectedTestGroupList(inputContent.current.lge_dept.split(';'));
        } else if (inputContent.current.test_dept_type == APP_TEST_GROUP.FUT) {
          setSelectedTestGroupList(inputContent.current.fut_dept.split(';'));
        }

        let appFile = [];
        let screenshot = [];

        for (let item of awsPath.app_file) {
          let fileSize = '';
          let getUrl = item.split('?')[0];

          if (getUrl.match(/(.plist)$/)) {
            fileSize = '-';
          } else {
            fileSize = appDetail.app_size;
          }
          appFile.push({
            path: item,
            app_size: fileSize,
            date: new Date(appDetail.register_date).toLocaleString(),
            uploaded: true,
            position: 'app_file'
          })
        }

        for (let item of awsPath.screenshot) {
          screenshot.push({ path: item, uploaded: true, position: 'screenshot' })
        }

        AWSS3Path.current = {
          app_file: appFile,

          icon_file: {
            path: awsPath.icon_file,
            uploaded: true,
            position: 'icon_file'
          },

          banner_file: {
            path: awsPath.banner_file,
            uploaded: true,
            position: 'banner_file'
          },

          screenshot: screenshot
        }

        setRendering(!screenRendering);
      } catch (error) {
        console.log('Error: ', error);
      }
    };

    if (props.location.state != null) {
      fetchData();
    } else {
      menuEntryPoint.current = entryPoint;
      inputContent.current = appDetails;
      AWSS3Path.current = {};

      inputContent.current = {...inputContent.current, description: '', whats_new: ''};
      setRendering(!screenRendering);
    }
  }, [entryPoint]);


  // HTML Code...
  const RegisterHeader = (props) => {
    return (
      <Row noGutters className="page-header py-4">
        {/*<PageTitle sm="4" title="Register" subtitle="Apps" className="text-sm-left" />*/}
        <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
          <Breadcrumb.Item><FontAwesomeIcon icon={faHome} /></Breadcrumb.Item>
          <Breadcrumb.Item>Apps</Breadcrumb.Item>
          <Breadcrumb.Item active>Register</Breadcrumb.Item>
        </Breadcrumb>
        <h4>Register application</h4>
      </Row>
    );
  }

  const RegisterButton = (props) => {
    return (
      <div className="mt-5 mb-1 text-right">
        <Button size="md" style={{ width: "120px" }} theme="primary" className="mb-2 mr-1" onClick={() => { appUpload() }}>Submit</Button>
      </div>
    );
  }

  return (
    <>
      {!auth.isLogin() && <Redirect to="/login" />}
      {(auth.getUserType() !== 2 && auth.getUserType() !== 4) && <Redirect to="/examples/404" />}
      <Container fluid className="main-content-container">
        <RegisterHeader/>

        <Container>
          {/* Editor */}
          <Card className="mb-3">
            <Card.Header className="border-bottom border-light d-flex justify-content-between">
              <span className="d-block">
                <h4 className="text-primary align-top">Application Information</h4>
                <h6 className="text-primary align-top">Please fill in your application information in the form below</h6>
              </span>
            </Card.Header>

            <Card.Body>
              <Col xl={12} id="register-edit">
                <Form>
                  <Form.Group id="test_period" className="mb-5">
                    <Form.Label>Test Period</Form.Label>
                    <Row style={{ width: "400px" }}>
                      <Col style={{ width: "150px" }}>
                        <Datetime
                          timeFormat={false}
                          onChange={ () => {} }
                          renderInput={(props, openCalendar) => (
                            <InputGroup style={{ width: "150px" }}>
                              <InputGroup.Text><FontAwesomeIcon icon={faCalendarAlt} /></InputGroup.Text>
                              <Form.Control
                                readOnly={true}
                                required
                                type="text"
                                value={moment(new Date().getTime()).format("MM/DD/YYYY")}
                                placeholder="mm/dd/yyyy"
                                onChange={() => { }} />
                            </InputGroup>
                          )} />
                      </Col>

                      <Col style={{ width: "50px", paddingLeft: "50px" }}>
                        <label>~</label>
                      </Col>

                      <Col>
                        <Datetime
                          style={{ width: "150px" }}
                          timeFormat={false}
                          onChange={(value) => { inputContent.current.test_period = Date.parse(value.format('YYYY/MM/DD'))} }
                          renderInput={(props, openCalendar) => (
                            <InputGroup style={{ width: "150px" }}>
                              <InputGroup.Text><FontAwesomeIcon icon={faCalendarAlt} /></InputGroup.Text>
                              <Form.Control
                                required
                                type="text"
                                value={ gadget.isEmpty(inputContent.current.test_period) ? "MM/DD/YYYY" :  moment(inputContent.current.test_period).format("MM/DD/YYYY") }
                                placeholder="mm/dd/yyyy"
                                onFocus={openCalendar}
                                onChange={() => { }} />
                            </InputGroup>
                          )} />
                      </Col>
                    </Row>
                  </Form.Group>

                  <Form.Group id="test_department" className="mb-5">
                    <Row>
                      <Col>
                        <Form.Label>Test Groups</Form.Label>
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      <Col xl={10}>
                        <Form.Check inline type="radio" label="All" name="all"
                          onChange={handleSetDeptType} value={0} checked={testGroupType === 0} />
                        <Form.Check inline type="radio" label="LGE Group" name="lge"
                          onChange={handleSetDeptType} value={1} checked={testGroupType === 1} />
                        <Form.Check inline type="radio" label="FUT Group" name="fut"
                          onChange={handleSetDeptType} value={2} checked={testGroupType === 2} />
                      </Col>
                    </Row>
                    {testGroupType !== APP_TEST_GROUP.ALL &&
                    <>
                      <Row>
                        <Col>
                          <DeptSelectTag />
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <SelectedTestGroupTag />
                        </Col>
                      </Row>
                    </>}
                  </Form.Group>

                  <Form.Group className="mb-5">
                    <Row>
                      <Col xl={2}>
                        <Form.Label>App Type</Form.Label>
                      </Col>
                      <Col xl={10}>
                        <Form.Check inline type="radio" label="Android" name="android" disabled={entryPoint !== APP_GLOBAL.ENTRY_POINT_SIDEBAR}
                          onChange={handleSetAppType} checked={(inputContent.current.app_type === APP_REGISTER.ANDROID_TYPE)} />
                        <Form.Check inline type="radio" label="iOS" name="ios" disabled={entryPoint !== APP_GLOBAL.ENTRY_POINT_SIDEBAR}
                          onChange={handleSetAppType} checked={(inputContent.current.app_type === APP_REGISTER.IOS_TYPE)} />
                        <Form.Check inline type="radio" label="Web" name="webapp" disabled={entryPoint !== APP_GLOBAL.ENTRY_POINT_SIDEBAR}
                          onChange={handleSetAppType} checked={(inputContent.current.app_type === APP_REGISTER.WEB_APP_TYPE)} />
                      </Col>
                    </Row>
                  </Form.Group>

                  <Form.Group className="mb-5">
                    <Row>
                      <Col>
                        <Form.Label>App Name</Form.Label>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Control
                          className="input-box" rows="1" name="app_name" id="app_name" autoComplete="off" maxLength={APP_REGISTER.MAX_APP_NAME_LENGTH}
                          readOnly={entryPoint !== APP_GLOBAL.ENTRY_POINT_SIDEBAR}  //  || appNameCheckPass.current}
                          defaultValue={inputContent.current.app_name}
                          onChange={(event) => {handleInputEdit(event, APP_REGISTER.MAX_APP_NAME_LENGTH)}}/>
                      </Col>
                    </Row>
                  </Form.Group>

                  {inputContent.current.app_type === APP_REGISTER.ANDROID_TYPE &&
                    <Form.Group className="mb-5">
                      <Form.Label>Package name</Form.Label>
                      <Form.Control className="input-box mb-5" type="text" name="package_name" id="package_name" autoComplete="off"
                        placeholder={"ex) com.android.application"}
                        defaultValue={inputContent.current.package_name}
                        onChange={(event) => {handleInputEdit(event, APP_REGISTER.MAX_APP_PACKAGE_NAME_LENGTH)}} />

                      <Form.Label>Activity name</Form.Label> <span> (Package name까지 포함하여 입력해주세요.)</span>
                      <Form.Control className="input-box" type="text" name="activity_name" id="activity_name" autoComplete="off"
                        placeholder={"ex) com.android.application.MainActivity"}
                        defaultValue={inputContent.current.activity_name}
                        onChange={(event) => {handleInputEdit(event, APP_REGISTER.MAX_APP_PACKAGE_NAME_LENGTH)}} />
                    </Form.Group>
                  }

                  { inputContent.current.app_type === APP_REGISTER.IOS_TYPE &&
                    <>
                      <Form.Group className="mb-5">
                        <Col>
                          <Form.Label>URL Schema</Form.Label> <span> (http://collab.lge.com/main/pages/viewpage.action?pageId=1989450753)</span>
                        </Col>
                        <Form.Control
                          className="input-box" rows="1" name="url_schema" id="url_schema" autoComplete="off"
                          placeholder={"ex) lgplayground000"}
                          defaultValue={inputContent.current.url_schema}
                          onChange={(event) => {handleInputEdit(event, APP_REGISTER.MAX_APP_NAME_LENGTH)}} />
                      </Form.Group>
                    </>
                  }

                  <Dropdown.Divider className="my-4 border-indigo" />

                  <Form.Group className="mb-3">
                    <Form.Label htmlFor="app_screenshot">Attach Files (App / Icon / Screenshot)</Form.Label>
                    <p>
                      <span small="true">&nbsp;&nbsp;1). 앱 파일: apk, ipa, plist, zip 파일을 추가 할 수 있습니다.</span> <br/>
                      <span small="true">&nbsp;&nbsp;2). 아이콘 파일: 파일 이름을 'icon_'으로 시작 하도록 설정 하시기 바랍니다.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;예) icon_example.png</span> <br/>
                      <span small="true">&nbsp;&nbsp;3). Banner 파일: 파일 이름을 'banner_'으로 시작 하도록 설정 하시기 바랍니다.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;예) banner_example.png</span> <br/>
                      <span small="true">&nbsp;&nbsp;4). 스크린샷 파일:</span> <br/>
                      <span small="true">&nbsp;&nbsp;&nbsp;&nbsp;- 최소 4개 ~ 최대 10개의 이미지 파일을 추가 할 수 있습니다.</span> <br/>
                      <span small="true">&nbsp;&nbsp;&nbsp;&nbsp;- Drag & Drop을 사용하여 파일 순서를 조절 할 수 있습니다.</span>
                    </p>

                    <FileAttach
                      onFileUpload={files => {
                        // setAwsPath(files)
                        AWSS3Path.current = files
                      }} entryPoint={menuEntryPoint.current} boardFiles={AWSS3Path.current} appType={inputContent.current.app_type} />
                  </Form.Group>

                  <Form.Group className="mb-5">
                    <Form.Label>App Version</Form.Label>
                    <Form.Control
                      className="input-box" rows="1" type="text" name="app_version" id="app_version" autoComplete="off"
                      defaultValue={inputContent.current.app_version} onChange={(event) => {handleInputEdit(event, APP_REGISTER.MAX_UNLIMITED_LENGTH)}} />
                  </Form.Group>

                  <Dropdown.Divider className="my-4 border-indigo" />

                  <Form.Group className="mb-5">
                    <Row>
                      <Col>
                        <Form.Label>Short Description</Form.Label>
                      </Col>
                    </Row>
                    <Form.Control
                      className="input-box" rows="1" name="short_description" id="short_description" autoComplete="off" maxLength={APP_REGISTER.MAX_SHORT_DESCRIPTION_LENGTH}
                      defaultValue={inputContent.current.short_description} onChange={(event) => {handleInputEdit(event, APP_REGISTER.MAX_SHORT_DESCRIPTION_LENGTH)}}
                    />
                  </Form.Group>

                  <Form.Group className="mb-6">
                    <Form.Label>Description</Form.Label>
                    <ReactQuill style={{ height: "300px" }} value={inputContent.current.description || ''} maxLength={APP_REGISTER.MAX_DESCRIPTION_LENGTH}
                        onChange={(content, delta, source, editor) => handleEditDescription(editor, APP_REGISTER.MAX_DESCRIPTION_LENGTH)} />
                  </Form.Group>

                  <Form.Group className="mb-6">
                    <Form.Label>What's new</Form.Label>
                    <ReactQuill style={{ height: "150px" }} value={inputContent.current.whats_new || ''} maxLength={APP_REGISTER.MAX_WHATS_NEW_LENGTH}
                      onChange={(content, delta, source, editor) => handleEditWhatsNew(editor, APP_REGISTER.MAX_WHATS_NEW_LENGTH)} />
                  </Form.Group>
                </Form>
              </Col>

              <RegisterButton/>
            </Card.Body>

          </Card>
        </Container>
      </Container>

      <Modal as={Modal.Dialog} size="lg" centered show={getUploadModal.open}>
        <Modal.Header>
          <Modal.Title className="h5">{getUploadModal.title}</Modal.Title>
          <Button variant="close" as={Link} aria-label="Close" to={Routes.AppApproval.path} />
        </Modal.Header>
        <Modal.Body>
          <div className="mx-3">
            <FontAwesomeIcon icon={getUploadModal.icon} size="3x" />
            <label className="m-3">{getUploadModal.message}</label>
          </div>

          { getUploadModal.filename !== undefined &&
            <div style={{paddingLeft: "30px", paddingRight: "30px"}}>
              <label style={{ marginTop: "30px" }}>Progress</label>
              <div style={{ height: 30, width: "100%", backgroundColor: "#e0e0de", borderRadius: 50, marginBottom: "20px" }} >
                <div style={{ height: "100%", width: `${getUploadModal.totalProgress}%`, backgroundColor: "#262b40", transition: "width .2s ease-in-out", borderRadius: "inherit", textAlign: "right", paddingTop: "3px" }}>
                  <span style={{ padding: 5, color: "white", fontWeight: "bold" }}>{getUploadModal.uploadedFileCnt}/{getUploadModal.totalFileCnt}</span>
                </div>
              </div>

              <label>File: {getUploadModal.filename}</label>
              <div style={{ height: 30, width: "100%", backgroundColor: "#e0e0de", borderRadius: 50, marginBottom: "20px" }} >
                <div style={{ height: "100%", width: `${getUploadModal.progress}%`, backgroundColor: "#262b40", transition: "width .2s ease-in-out", borderRadius: "inherit", textAlign: "right", paddingTop: "3px" }}>
                  <span style={{ padding: 5, color: "white", fontWeight: "bold" }}>{getUploadModal.progress}%</span>
                </div>
              </div>
            </div>
          }
        </Modal.Body>
        <Modal.Footer style={{border: "0px"}}>
          <Button variant="link" as={Link} className="text-gray ms-auto" to={Routes.AppApproval.path}>
            {getUploadModal.button}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal as={Modal.Dialog} size="lg" centered show={getErrorModal.open}>
        <Modal.Header>
          <Modal.Title className="h5">{getErrorModal.title}</Modal.Title>
          <Button variant="close" aria-label="Close" onClick={() => setErrorModal({ open: false })} />
        </Modal.Header>
        <Modal.Body>
        <div className="mx-3">
            <FontAwesomeIcon icon={getErrorModal.icon} size="3x" />
            <label className="m-3">{getErrorModal.message}</label>
          </div>

          {/* <p>{getErrorModal.message}</p> */}
        </Modal.Body>
        <Modal.Footer style={{border: "0px"}}>
          <Button variant="link" className="text-gray ms-auto" onClick={() => setErrorModal({open: false})}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
};

export default Register;
