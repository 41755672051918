import React, { Component } from "react";
import { Card, Form } from '@themesberg/react-bootstrap';

import Rating from "react-rating";
import StarEmptyImg from "../../../assets/img/survey/star-empty.png";
import StarFullImg from "../../../assets/img/survey/star-full.png";

export default class SurveyResponseItem extends Component {
  constructor(props) {
    super(props);
  }

  responseFormCommentsHandler = (id, e) => {
    this.props.onUpdate(id, "comment", e.target.value);
  }

  responseFormSelectedHandler = (id, value, e) => {
    if (this.props.data.type === "checkbox") {
      var newSelected = Array.from(this.props.data.selected);

      var idx = -1;
      var changeSelected = [];
      if (e.target.checked === true) { //checked
        if (newSelected.includes(value) === false) {
          //state 배열에 추가.
          idx = newSelected.length;
          newSelected.splice(idx, 0, value);
          changeSelected = {
            selected: [
              ...newSelected
            ]
          }
        }
      } else { //unchecked
        if (newSelected.includes(value) === true) {
          //state 배열에서 삭제.
          idx = newSelected.indexOf(value);
          newSelected.splice(idx, 1);
          changeSelected = {
            selected: [
              ...newSelected
            ]
          }
        }
      }
    } else if (this.props.data.type === "radiogroup") {
      changeSelected = {
        selected: [
          value
        ]
      }
    }

    this.props.onUpdate(id, "selected", changeSelected);
  }

  responseFormRatingHandler = (id, value) => {
    var changeSelected = {
      selected: [
        value
      ]
    }

    this.props.onUpdate(id, "selected", changeSelected);
  }

  render() {
    var surveyItem = this.props.data;

    var responseTags = [];
    var questionTag = [];

    questionTag.push(
      <Form.Label style={{ fontWeight: "bold" }}>{surveyItem.id}.&nbsp;{surveyItem.question}&nbsp;{surveyItem.required ? "*" : ""}</Form.Label>
    );
    if (surveyItem.is_description === true) {
      questionTag.push(
        <div>
          <Form.Label>({surveyItem.description})</Form.Label>
        </div>
      );
    }

    var candidateTag = [];
    var i = 0;
    switch (surveyItem.type) {
      case 'checkbox':
        if (surveyItem.candidate.length > 0) {
          i = 0;
          while (i < surveyItem.candidate.length) {
            candidateTag.push(
              <Form.Check
                type="checkbox"
                label={surveyItem.candidate[i].text}
                id={surveyItem.id + "item" + surveyItem.candidate[i].value}
                htmlFor={surveyItem.id + surveyItem.candidate[i].value}
                onChange={this.responseFormSelectedHandler.bind(this, surveyItem.id, surveyItem.candidate[i].value)} />
            );
            i = i + 1;
          }
        }
        break;
      case 'radiogroup':
        if (surveyItem.candidate.length > 0) {
          i = 0;
          while (i < surveyItem.candidate.length) {
            candidateTag.push(
              <Form.Check
                type="radio"
                name={surveyItem.id + "radio"}
                label={surveyItem.candidate[i].text}
                id={surveyItem.id + "item" + surveyItem.candidate[i].value}
                htmlFor={surveyItem.id + surveyItem.candidate[i].value}
                onChange={this.responseFormSelectedHandler.bind(this, surveyItem.id, surveyItem.candidate[i].value)} />
            );
            i = i + 1;
          }
        }
        break;
      case 'rating':
        candidateTag.push(
          <div style={{ marginBottom: "5px" }}>
            <Rating
              start={0}
              stop={surveyItem.candidate_cnt}
              initialRating={surveyItem.selected.length === 1 ? surveyItem.selected[0] : 0}
              emptySymbol={<img src={StarEmptyImg} className="icon" />}
              fullSymbol={<img src={StarFullImg} className="icon" />}
              onChange={this.responseFormRatingHandler.bind(this, surveyItem.id)}
            />
          </div>
        );
        break;
      default:
    }

    var commentTag = [];
    if (surveyItem.is_comment === true) {
      commentTag.push(
        <Form.Control as="textarea" rows="4" onChange={this.responseFormCommentsHandler.bind(this, surveyItem.id)} />
      );
    }

    responseTags.push(
      <Card.Body>
        {questionTag}
        {candidateTag}
        {commentTag}
      </Card.Body>
    );

    return (
      <div>
        {responseTags}
      </div>
    );
  }
}