import React, { Component } from "react";
import { Dropdown } from '@themesberg/react-bootstrap';

import SurveyDataInfo from "./SurveyDataInfo";
import SurveyHeaderInfo from "./SurveyHeaderInfo";
import ReactDragListView from "react-drag-listview";
import SurveyEmptyImg from "../../../assets/img/survey/survey_empty.png";

export default class SurveyList extends Component {
  render() {
    var userType = this.props.userType;
    var appList = this.props.appList;
    var appId = this.props.appId;
    var appName = this.props.appName;
    var surveyList = this.props.data;
    var surveyId = this.props.surveyId;
    var surveyHeaderContent = this.props.surveyHeaderContent
    var surveyStartDate = this.props.surveyStartDate;
    var surveyEndDate = this.props.surveyEndDate;
    var onUpdateHeader = this.props.onUpdateHeader;
    var onUpdate = this.props.onUpdate;
    var onDelete = this.props.onDelete;
    var onChangeOrder = this.props.onChangeOrder;
    var onUpdatePeriod = this.props.onUpdatePeriod;
    var onSelectApp = this.props.onSelectApp;

    const dragProps = {
      onDragEnd(fromIndex, toIndex) {
        //console.log("fromIndex : " + fromIndex + " / toIndex :" + toIndex);
        var data = [...surveyList];
        var item = data.splice(fromIndex, 1)[0];
        //console.log(item);
        data.splice(toIndex, 0, item);
        onChangeOrder(data);
      },
      nodeSelector: 'li'
    }

    var listTags = [];
    if (surveyList.length === 0) {
      listTags.push(
        <div>
          <div className="survey-empty-title" style={{ fontSize: "25px", color: "darkgray", textAlign: "center" }}>
            Please click a question type from the Toolbox.
            <br />
            <img src={SurveyEmptyImg} style={{ marginTop: "20px" }} />
          </div>
        </div>
      );
    } else {
      listTags.push(
        <div>
          <ReactDragListView {...dragProps}>
            <ol style={{ paddingInlineStart: "0px" }}>
              {surveyList.map((surveyItem, index) => (
                <li style={{ listStyleType: "none" }} key={index}>
                  <SurveyDataInfo data={surveyItem} onUpdate={onUpdate} onDelete={onDelete} idx={index} />
                </li>
              ))}
            </ol>
          </ReactDragListView>
        </div>
      );
    }

    return (
      <div className="SurveyListPage">
        <div>
          <SurveyHeaderInfo
            userType={userType}
            appList={appList}
            appId={appId}
            appName={appName}
            surveyId={surveyId}
            header={surveyHeaderContent}
            startDate={surveyStartDate}
            endDate={surveyEndDate}
            onUpdateHeader={onUpdateHeader}
            onUpdatePeriod={onUpdatePeriod}
            onSelectApp={onSelectApp} />
        </div>
        <Dropdown.Divider className="my-3 border-indigo" />
        <div>
          {listTags}
        </div>
      </div>
    );
  }
}