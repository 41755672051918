import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";

import { faHome } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Breadcrumb, Button, Card, Col, Container, Dropdown, Form, Modal, Row, InputGroup } from '@themesberg/react-bootstrap';

import { APP_DETAIL, APP_TEST_GROUP } from './const/AppConst';

import axios from 'axios';
import Slider from "react-slick";
import auth from "../../utils/auth";
import pg from '../../utils/pg';

import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import "../../css/details.css";

import moment from "moment-timezone";
import gadget from "../../utils/gadget";


function Details(props) {
  const id = props.location.state.id;
  const entryPoint = props.location.state.entryPoint;

  const [getAppDetail, setAppDetail] = useState();

  const inputContent = useRef({ app_id: '', admin_comment: '' })
  const AWSS3Path = useRef({ });

  // Modal
  const [getConfirmModal, setConfirmModal] = useState({ open: false, title: '', message: '' });
  const [getValidCheckModal, setValidCheckModal] = useState({ open: false, title: '', message: '' });
  const [getResultModal, setResultModal] = useState({ open: false, title: '', message: '' });
  const history = useHistory();

  const [uploadApiKey, setUploadApiKey] = useState("");

  const randomInt = (min, max) => {
    return Math.round(min + (Math.random() * (max-min)));
  }

  const genUplaodApiKey = () => {
    /* UUID
    var curTime = new Date().getTime();

    if( window.performance && typeof window.performance.now === "function" )
    {
      curTime += performance.now();
    }

    let uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c)
    {
      var rnd = (curTime + Math.random() * 16) % 16 | 0;
      curTime = Math.floor(curTime / 16);
      return (c === 'x' ? rnd : (rnd & 0x3 | 0x8)).toString(16);
    });
    */
    const CERT_MAX_LEN = 16;
    let presetChar = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let genNo = "";
    for (let i = 0; i < CERT_MAX_LEN; i++) {
      genNo += presetChar[randomInt(0, presetChar.length - 1)];
    }
    setUploadApiKey(genNo);
  }

  const goAppRegister = async (appInfo) => {
    if (!gadget.isEmpty(getAppDetail.app_id)) {
      const response = await axios.get(APP_DETAIL.AWS_DETAIL_QUERY+ `?request_id=${getAppDetail.request_id}&app_id=${getAppDetail.app_id}&app_status=0`);

      if (response.data.isExist
        && entryPoint !== 'Request') {
        setResultModal({
          open: true,
          title: 'Error',
          icon: AWSS3Path.current.icon_file,
          message: '동일한 App이 승인 대기중 입니다.',
        });
        return;
      }
    }

    history.push({
      pathname: "/app/register",
      state: {
        id: appInfo.appData.id,
        entryPoint: entryPoint
      }
    });
  }

  const handleInputEdit = async (event) => {
    let getString = event.currentTarget;
    let key = getString.name;

    inputContent.current = { ...inputContent.current, [key]: getString.value }
  };

  const handleConfirmUpdate = (confirm) => {
    let title;
    let modalMessage;
    let emailTitle;
    let emailMessage;

    setConfirmModal({ open: false });

    try {
      switch (confirm.key) {
        case 'approve':
          title = '완료';
          modalMessage = `"${getAppDetail.app_name}"  앱 등록이 완료 되었습니다.`;
          emailTitle = 'App 등록 알림';
          emailMessage = `요청하신 ${getAppDetail.app_name} 앱이 등록 되었습니다.`;
          let appid_str = inputContent.current.app_id === '' ? getAppDetail.app_id : inputContent.current.app_id;

          if (!gadget.isEmpty(uploadApiKey)) {
            emailMessage += `\n\n`;
            emailMessage += `APP ID : `;
            emailMessage += appid_str;
            emailMessage += `\n\n`;
            emailMessage += `API KEY : `;
            emailMessage += uploadApiKey;
          }
          break;

        case 'reject':
          title = '반려';
          modalMessage = `"${getAppDetail.app_name}"  앱 등록을 반려 했습니다.`;
          emailTitle = 'App 반려 알림';
          emailMessage = `요청하신 ${getAppDetail.app_name} 앱이 반려 되었습니다.`;
          break;

        case 'remove':
          title = '삭제';
          modalMessage = `"${getAppDetail.app_name}"  앱을 삭제 했습니다.`;
          emailTitle = 'App 삭제 알림';
          emailMessage = `요청하신 ${getAppDetail.app_name} 앱이 삭제 되었습니다.`;
          break;

        default:
          break;
      }

      axios.patch(APP_DETAIL.AWS_DETAIL_QUERY, {
        id: getAppDetail.id,
        app_id: inputContent.current.app_id === '' ? getAppDetail.app_id : inputContent.current.app_id,
        app_name: getAppDetail.app_name,
        app_card_type: getAppDetail.app_card_type,
        admin_comment: inputContent.current.admin_comment,
        register_date: new Date().getTime(),
        app_status: confirm.app_status,
        api_key: uploadApiKey,
      });

      // Send FCM Push notification
      // if (confirm.app_status === APP_DETAIL.APPROVE && getAppDetail.test_dept_type === APP_TEST_GROUP.ALL) {
      if (confirm.app_status === APP_DETAIL.APPROVE) {
          axios.post(APP_DETAIL.SEND_PUSH_MESSAGE,
          {
            title: "앱 등록 알림",
            body: getAppDetail.app_name + " 앱이 등록되었습니다.",
            type: "app",
            app_id: getAppDetail.app_id ? getAppDetail.app_id : inputContent.current.app_id,
            group_id : getAppDetail.test_dept_type
          },
          {
            headers: {
              'Content-Type': 'application/json'
            }
          }
        );
      }

      pg.sendEmail(getAppDetail.request_id, emailTitle, emailMessage);

      setResultModal({
        open: true,
        title: title,
        icon: AWSS3Path.current.icon_file,
        message: modalMessage,
      });
    } catch (error) {
      console.log(error);
      setResultModal({
        open: true,
        title: 'Error',
        message: 'App 승인 중 Network Error가 발생되었습니다.'
      });
    }
  }

  const isValidCheck = (key) => {
    let message = '';

    if (key === 'approve') {
      if (gadget.isEmpty(inputContent.current.app_id) && gadget.isEmpty(getAppDetail.app_id)) {
        message = 'App ID 입력해 주세요.';
      } else if (getAppDetail.app_card_type === null) {
        message = 'App Card Type을 선택해 주세요.';
      } else if (gadget.isEmpty(uploadApiKey)) {
        message = 'API Key를 생성해 주세요.'
      }
    } else if (key === 'reject' || key === 'remove') {
      if (auth.getUserType() === APP_DETAIL.ADMIN_USER
          || auth.getUserID() === getAppDetail.request_id) {
        if (gadget.isEmpty(inputContent.current['admin_comment'])) {
          message = 'App 반려/삭제 사유를 입력해 주세요.';
        }
      } else {
        message = 'App 반려/삭제 권한이 없습니다.';
      }
    }

    if (!gadget.isEmpty(message)) {
      setValidCheckModal({
        open: true,
        title: '오류',
        message: message,
        icon: AWSS3Path.current.icon_file,
      });
      return false;
    }

    return true;
  };

  const handleSetAppCardType = async (event) => {
    let appCardType = event.target.name;

    setAppDetail({
      ...getAppDetail,
      'app_card_type': (appCardType === 'firebase') ? 1 : (appCardType === 'aws db' ? 2 : (appCardType === 'both' ? 3 : 0))
    })
  }

  const handleAppStatus = async (event) => {
    let key = event.currentTarget.id;
    let title;
    let message;
    let app_status;

    if (!isValidCheck(key)) {
      return;
    }

    switch (key) {
      case 'approve':
        title = '앱 등록';
        message = `"${getAppDetail.app_name}"  앱 등록을 승인 하시겠습니까?`;
        app_status = APP_DETAIL.APPROVE;
        break;

      case 'reject':
        title = '앱 등록 반려';
        message = `"${getAppDetail.app_name}"  앱 등록을 반려 하시겠습니까?`;
        app_status = APP_DETAIL.ADMIN_REJECT;
        break;

      case 'remove':
        title = '앱 삭제';
        message = `"${getAppDetail.app_name}"  앱을 삭제 하겠습니까?`;
        app_status = auth.getUserType() === APP_DETAIL.ADMIN_USER
            ? app_status = APP_DETAIL.ADMIN_REMOVE : app_status = APP_DETAIL.DEVELOPER_REMOVE;
        break;

      default:
        console.log('Detail: AdminButton error');
        return;
    }

    setConfirmModal({
      open: true,
      title: title,
      icon: AWSS3Path.current.icon_file,
      message: message,

      key: key,
      app_status: app_status,
    });
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block" }}
        onClick={onClick} />
    );
  }

  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block" }}
        onClick={onClick} />
    );
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(APP_DETAIL.AWS_DETAIL_QUERY+ `?id=${id}`);

        let resDB = response.data.appDetails;
        let S3Path = response.data.S3Path;
        let S3Screenshot;

        if (!gadget.isEmpty(S3Path.banner_file)) {
          S3Screenshot = [S3Path.banner_file];
          S3Screenshot = S3Screenshot.concat(S3Path.screenshot)
        } else {
          S3Screenshot = S3Path.screenshot;
        }

        AWSS3Path.current = { app_file: S3Path.app_file, icon_file: S3Path.icon_file, screenshot: S3Screenshot };

        setAppDetail(resDB);
        if (!gadget.isEmpty(resDB.api_key)) {
          setUploadApiKey(resDB.api_key)
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, [id]);

  const getDisplayScreenshot = (resolution) => {
    if (gadget.isEmpty(getAppDetail)) {
      return 1;
    }

    switch(resolution) {
      case APP_DETAIL.HIGH_SCREEN_WIDTH:
        return getAppDetail.screenshot.length >= 3 ? 3 : getAppDetail.screenshot.length;
      case APP_DETAIL.MIDDLE_SCREEN_WIDTH:
        return getAppDetail.screenshot.length >= 2 ? 2 : getAppDetail.screenshot.length;
      case APP_DETAIL.LOW_SCREEN_WIDTH:
        return 1;
      default:
        return 4;
    }
  }

  const SliderSettings = {
    dotsClass: "slick-dots slick-thumb",
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: getDisplayScreenshot(0),
    slidesToScroll: 1,
    variableWidth: true,
    adaptiveHeight: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: APP_DETAIL.HIGH_SCREEN_WIDTH,
        settings: { slidesToShow: getDisplayScreenshot(APP_DETAIL.HIGH_SCREEN_WIDTH) }
      },
      {
        breakpoint: APP_DETAIL.MIDDLE_SCREEN_WIDTH,
        settings: { slidesToShow: getDisplayScreenshot(APP_DETAIL.MIDDLE_SCREEN_WIDTH) }
      },
      {
        breakpoint: APP_DETAIL.LOW_SCREEN_WIDTH,
        settings: { slidesToShow: getDisplayScreenshot(APP_DETAIL.LOW_SCREEN_WIDTH) }
      }
    ]
  };

  const DetailHeader = (props) => {
    return (
      <Row noGutters className="page-header py-4">
        {/*<PageTitle sm="4" title="Detail" subtitle="Apps" className="text-sm-left" />*/}
        <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
          <Breadcrumb.Item><FontAwesomeIcon icon={faHome} /></Breadcrumb.Item>
          <Breadcrumb.Item>Apps</Breadcrumb.Item>
          <Breadcrumb.Item active>Detail</Breadcrumb.Item>
        </Breadcrumb>
        <h4>Detail information</h4>
      </Row>
    );
  }

  const DetailsButton = (props) => {
    return (
      <div className="button-list text-right">
        {/* { (auth.getUserType() === APP_DETAIL.ADMIN_USER || auth.getUserType() === APP_DETAIL.DEVELOPER_USER) &&
          <Button className="mb-2 mr-1 me-2 detail-button" variant="primary" href={AWSS3Path.current.app_file}>다운로드</Button>
        } */}

        { (auth.getUserType() === APP_DETAIL.ADMIN_USER && getAppDetail.app_status === APP_DETAIL.REQUEST)
          ? <>
              <Button className="mb-2 mr-1 me-2 detail-button" variant="secondary" id="update" name="update" onClick={() => { goAppRegister({ appData: getAppDetail }) }}>업데이트</Button>
              <Button className="mb-2 mr-1 me-2 detail-button" variant="success" id="approve" name="approve" onClick={handleAppStatus}>승인</Button>
              <Button className="mb-2 mr-1 me-2 detail-button" variant="danger" id="reject" name="reject" onClick={handleAppStatus}>반려</Button>
            </>
          : (auth.getUserType() === APP_DETAIL.DEVELOPER_USER || auth.getUserType() === APP_DETAIL.ADMIN_USER)
              ? <Button className="mb-2 mr-1 me-2 detail-button" variant="secondary" id="update" name="update" onClick={() => { goAppRegister({ appData: getAppDetail }) }}>업데이트</Button>
              : <></>
        }

        { (auth.getUserType() === APP_DETAIL.ADMIN_USER || auth.getUserType() === APP_DETAIL.DEVELOPER_USER) &&
          <Button className="mb-2 mr-1 me-2 detail-button" variant="danger" id="remove" name="remove" onClick={handleAppStatus}>앱 삭제</Button>
        }
      </div>
    );
  }

  const ConfirmModal = (props) => {
    return (
      <>
        <Modal as={Modal.Dialog} size="lg" centered show={getConfirmModal.open}>
          <Modal.Header>
            <i className="fas fa-question-circle fa-2x mx-2"></i>
            <h5 style={{paddingTop: "15px"}}>&nbsp;{getConfirmModal.title}</h5>
            <Button variant="close" aria-label="Close" onClick={ () => {setConfirmModal({open: false})} }/>
          </Modal.Header>
          <Modal.Body>
            <div className="mx-3">
              <img src={getConfirmModal.icon} width="60px" height="60px" alt="app icon"/>
              <label className="m-3">{getConfirmModal.message}</label>
            </div>
          </Modal.Body>
          <Modal.Footer style={{border: "0px"}}>
            <Button variant="link" className="mx-5" onClick={() => {setConfirmModal({open: false})}}>
              취소
            </Button>
            <Button variant="link" onClick={ () => handleConfirmUpdate(getConfirmModal) }>
              확인
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal as={Modal.Dialog} size="lg" centered show={getValidCheckModal.open}>
          <Modal.Header>
            <i className="fas fa-check-circle fa-2x mx-2"></i>
            <h5 style={{paddingTop: "15px"}}>&nbsp;{getValidCheckModal.title}</h5>
            <Button variant="close" aria-label="Close" onClick={() => {setValidCheckModal({open: false})}}/>
          </Modal.Header>
          <Modal.Body>
            <div className="mx-3">
              <img src={getValidCheckModal.icon} max-width="60px" height="60px" alt="app icon"/>
              <label className="m-3">{getValidCheckModal.message}</label>
            </div>
          </Modal.Body>
          <Modal.Footer style={{border: "0px"}}>
            <Button onClick={ () => {setValidCheckModal({open: false})} }>
              확인
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal as={Modal.Dialog} size="lg" centered show={getResultModal.open}>
          <Modal.Header>
            <i className="fas fa-check-circle fa-2x mx-2"></i>
            <h5 style={{paddingTop: "15px"}}>&nbsp;{getResultModal.title}</h5>
            <Button variant="close" aria-label="Close" onClick={() => {setResultModal({open: false})}}/>
          </Modal.Header>
          <Modal.Body>
            <div className="mx-3">
              <img src={getResultModal.icon} max-width="60px" height="60px" alt="app icon"/>
              <label className="m-3">{getResultModal.message}</label>
            </div>
          </Modal.Body>
          <Modal.Footer style={{border: "0px"}}>
            <Button variant="link" onClick={ () => {setResultModal({open: false}); history.goBack();} }>
              확인
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }

  return (
    <Container fluid className="main-content-container">
      <DetailHeader/>

      { getAppDetail !== undefined && AWSS3Path.current !== undefined &&
        <Container>
          <Card>
            <Card.Body>
              <Row xs={12}>
                <Col xs={2} className="icon-area" >
                  <Card.Img className="app-icon" src={AWSS3Path.current.icon_file} alt="icon file" />
                </Col>

                <Col className="app-content-header">
                  <Row>
                    <h4 className="align-middle mb-1">{getAppDetail.app_name}</h4>
                    <h5 className="align-middle mb-3">{getAppDetail.short_description}</h5>

                    <h6 className="app-content mb-2">Version: {getAppDetail.app_version}</h6>
                    <h6 className="app-content mb-2">Author: {getAppDetail.request_id}</h6>
                    <h6 className="app-content mb-2">Updated: {moment(getAppDetail.register_date).format('DD / MM / YYYY')}</h6>
                    <h6 className="app-content mb-2">Test period: {moment(getAppDetail.test_period).format('DD / MM / YYYY')}</h6>
                  </Row>
                </Col>
              </Row>

              <Dropdown.Divider className="my-4 border-indigo mb-3" />

              <div className="description p-5">
                <Col md={12}>
                  <Slider className="slider-image mb-6 ml-50" {...SliderSettings}>
                    { AWSS3Path.current.screenshot !== undefined && AWSS3Path.current.screenshot.length !== 0
                      ? AWSS3Path.current.screenshot.map((screenshot, index) => (
                          <div key={index}>
                            { index === 0
                              ? <img className="banner" src={screenshot} alt="screenshot" />
                              : <img className="image" src={screenshot} alt="screenshot" />
                            }
                          </div>
                        ))
                      : ""
                    }
                  </Slider>
                </Col>
              </div>

              <Col className="mx-4 mb-6">
                <h6 className="title mb-4">● Description</h6>
                <div className="mx-4" dangerouslySetInnerHTML={{ __html: getAppDetail.description }}/>
              </Col>

              <Col className="mx-4 mb-6">
                <h6 className="title">● What's new</h6>
                <div className="mx-4" dangerouslySetInnerHTML={{ __html: getAppDetail.whats_new }}/>
              </Col>

              <Col className="mx-4 mb-6">
                { auth.getUserType() === APP_DETAIL.ADMIN_USER
                  ? <>
                      <Form.Group className="mb-5">
                        <Row>
                          <Col xl={2}>
                            <h6 className="title">● App Card Type</h6>
                          </Col>
                          <Col xl={10}>
                            <Form.Check inline type="radio" label="Firebase" name="firebase"
                              onChange={handleSetAppCardType} checked={(getAppDetail.app_card_type === 1)} />
                            <Form.Check inline type="radio" label="AWS DB" name="aws db" disabled={false}
                              onChange={handleSetAppCardType} checked={(getAppDetail.app_card_type === 2)} />
                            <Form.Check inline type="radio" label="Both" name="both"
                              onChange={handleSetAppCardType} checked={(getAppDetail.app_card_type === 3)} />
                            <Form.Check inline type="radio" label="None" name="none"
                              onChange={handleSetAppCardType} checked={(getAppDetail.app_card_type === 0)} />
                          </Col>
                        </Row>
                      </Form.Group>

                      { gadget.isEmpty(getAppDetail.app_id) &&
                        <Col className="mb-5">
                          <h6 className="title">● App ID</h6>
                          <Form.Control rows="5" name="app_id" id="app_id" defaultValue={getAppDetail.app_id} onChange={(event) => handleInputEdit(event)}/>
                        </Col>
                      }
                      <Col className="mb-5">
                        <h6 className="title">● Admin comment</h6>
                        <Form.Control rows="5" name="admin_comment" id="admin_comment" defaultValue={getAppDetail.admin_comment} onChange={(event) => handleInputEdit(event)}/>
                      </Col>
                      { (getAppDetail.app_status === 0) &&
                        <Col>
                          <h6 className="title">● API Key</h6>
                          <Form.Group>
                            <InputGroup>
                              <Form.Control
                                id="g_cert"
                                className="input-box"
                                type="text"
                                maxLength={8}
                                placeholder="'API-KEY 생성' 버튼을 눌러 Key를 생성하세요."
                                value={uploadApiKey}
                                style={{marginRight:"1px"}}
                              />
                              <Button variant="outline-primary" onClick={genUplaodApiKey}>API-KEY 생성</Button>
                            </InputGroup>
                            </Form.Group>
                        </Col>
                      }
                    </>
                  : auth.getUserID() === getAppDetail.request_id
                    ?
                    <>
                      <Col>
                        <h6 className="title">● Admin comment</h6>
                        <Form.Control rows="5" name="admin_comment" id="admin_comment" defaultValue={getAppDetail.admin_comment} onChange={(event) => handleInputEdit(event)}/>
                      </Col>
                    </>
                    : auth.getUserType() === APP_DETAIL.ADMIN_USER || (auth.getUserType() === APP_DETAIL.DEVELOPER_USER && getAppDetail.app_status === APP_DETAIL.ADMIN_REJECT)
                      ? <>
                          <h6 className="title">● Admin comment</h6>
                          <div className="mx-4" dangerouslySetInnerHTML={{ __html: getAppDetail.admin_comment }}/>
                        </>
                      : <></>
                }
              </Col>
              <DetailsButton/>
            </Card.Body>
          </Card>

          <ConfirmModal/>
        </Container>
      }
      <br/>
    </Container>
  );
}

export default Details;
