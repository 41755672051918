import React, { Component } from "react";
import { Row, Col, Form } from '@themesberg/react-bootstrap';

import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ko from 'date-fns/locale/ko';
import "../styles/SurveyHeaderContainer.css"

import { SURVEY_USER_TYPE } from "../const/surveyConst";

export default class SurveyHeaderInfo extends Component {
  //constructor(props) {
  //    super(props);
  //}

  selectAppHandler = (e) => {
    let nameTxt = e.target.options[e.target.selectedIndex].text;
    console.log(nameTxt);
    console.log(e.target.value);
    if (e.target.value.split(':')[0] === 'android') {
      nameTxt = nameTxt.slice(0, nameTxt.length-10);
    } else if (e.target.value.split(':')[0] === 'ios') {
      nameTxt = nameTxt.slice(0, nameTxt.length-6);
    } else if (e.target.value.split(':')[0] === 'web') {
      nameTxt = nameTxt.slice(0, nameTxt.length-6);
    } else {
      //console.log(nameTxt);
    }
    this.props.onSelectApp(e.target.value, nameTxt);
  }

  inputFormHeaderHandler = (tagName, e) => {
    var newHeader = Array.from(this.props.header);

    if (tagName === "surveyHeaderTitle") {
      newHeader = {
        survey_title: e.target.value,
        survey_description: this.props.header.survey_description
      }
    } else if (tagName === "surveyHeaderDesc") {
      newHeader = {
        survey_title: this.props.header.survey_title,
        survey_description: e.target.value
      }
    }
    this.props.onUpdateHeader(newHeader);
  }

  handleSurveyPeriod = (se, date) => {
    this.props.onUpdatePeriod(se, new Date(date).getTime());
    if ((se === "start_date") && (new Date(date).getDate() > new Date(this.props.endDate).getDate())) {
      this.props.onUpdatePeriod("end_date", new Date(date).getTime());
    }
  }

  render() {
    var startDate;
    var endDate;

    var calDate;
    if (this.props.startDate === 0) {
      //startDate = new Date();
      calDate = new Date();
      startDate = new Date(calDate.getFullYear(), calDate.getMonth(), calDate.getDate());
    } else {
      //startDate = new Date(this.props.startDate);
      calDate = new Date(this.props.startDate);
      startDate = new Date(calDate.getFullYear(), calDate.getMonth(), calDate.getDate());
    }
    if (this.props.endDate === 0) {
      endDate = startDate;
    } else {
      if (this.props.endDate < startDate) {
        //endDate = startDate;
        calDate = new Date(startDate);
        endDate = new Date(calDate.getFullYear(), calDate.getMonth(), calDate.getDate(), 23, 59, 59);
      } else {
        //endDate = new Date(this.props.endDate);
        calDate = new Date(this.props.endDate);
        endDate = new Date(calDate.getFullYear(), calDate.getMonth(), calDate.getDate(), 23, 59, 59);
      }
    }
    registerLocale("ko", ko);

    var headerTags = [];

    var typeListTag = [];
    var appListTag = [];
    if (this.props.surveyId !== "") {
      headerTags.push(
        <Form.Group className="mb-3">
          <Form.Label>설문 대상</Form.Label>
          <Form.Select value={this.props.appId} onChange={this.selectAppHandler.bind(this)}>
            <option value={this.props.appId}>
              {
                ((this.props.appId.split(':')[0] === "lgpg") || (this.props.appId.split(':')[0] === "general"))
                  ? this.props.appName
                  : this.props.appName + ' (' + this.props.appId.split(':')[0] + ')'
                /* this.props.appName ? this.props.appName + ' (' + this.props.appId.split(':')[0] + ')' : "-" */
              }
            </option>
          </Form.Select>
        </Form.Group>
      );
    } else {
      for (let app of this.props.appList) {
        appListTag.push(<option value={app.app_id}>{app.app_name + ' (' + app.app_id.split(':')[0] + ')'}</option>);
      }

      if (this.props.userType === SURVEY_USER_TYPE.ADMIN) {
        typeListTag.push(<option value="0">앱 또는 일반 설문을 선택하세요.</option>);
        typeListTag.push(
          <optgroup label="일반">
            <option value={'lgpg:000000000'}>LG Playground</option>
            <option value={'general:000000000'}>일반설문</option>
          </optgroup>
        );
        typeListTag.push(
          <optgroup label="앱">
            {appListTag}
          </optgroup>
        );
      } else {
        console.log("appListCount = ", this.props.appList.length);
        if (this.props.appList.length <= 0) {
          typeListTag.push(<option value="0">대상 앱이 없습니다.</option>);
        } else {
          typeListTag.push(<option value="0">앱을 선택하세요.</option>);
          typeListTag.push(...appListTag);
        }
      }

      var currentAppId = this.props.appId ? this.props.appId : "0";
      headerTags.push(
        <Form.Group className="mb-3">
          <Form.Label>설문 대상</Form.Label>
          <Form.Select value={currentAppId} onChange={this.selectAppHandler.bind(this)}>
            {typeListTag}
          </Form.Select>
        </Form.Group>
      );
    }

    headerTags.push(
      <Form.Group className="mb-3">
        <Form.Label>설문 제목</Form.Label>
        <Form.Control
          className="input-box"
          type="text"
          placeholder="Input survey title here"
          value={this.props.header.survey_title}
          onChange={this.inputFormHeaderHandler.bind(this, "surveyHeaderTitle")}
        />
      </Form.Group>
    );
    headerTags.push(
      <Form.Group className="mb-3">
        <Form.Label>설문 설명</Form.Label>
        <Form.Control
          className="input-box"
          as="textarea"
          rows="2"
          placeholder="Enter a survey description"
          value={this.props.header.survey_description}
          onChange={this.inputFormHeaderHandler.bind(this, "surveyHeaderDesc")}
        />
      </Form.Group>
    );

    headerTags.push(
      <Form.Group className="mb-3">
        <Form.Label htmlFor="app_name">설문 기간</Form.Label>
        {/*
                <div className="survey-perioid-input-container">
                    <DatePicker
                        locale="ko"
                        selected={startDate}
                        selectsStart
                        minDate={new Date()}
                        dateFormat="yyyy년 MM월 dd일"
                        useWeekdaysShort={true}
                        onChange={this.handleSurveyPeriod.bind(this, "start_date")}
                        startDate={startDate}
                        endDate={endDate}
                    />
                    <label>&nbsp;~&nbsp;</label>
                    <DatePicker
                        locale="ko"
                        selected={endDate}
                        selectsEnd
                        minDate={startDate}
                        dateFormat="yyyy년 MM월 dd일"
                        useWeekdaysShort={true}
                        onChange={this.handleSurveyPeriod.bind(this, "end_date")}
                        startDate={startDate}
                        endDate={endDate}
                    />
                </div>
                */}
        <div className="survey-perioid-input-container">
          <Row>
            <Col style={{ padding: "0px 0px 0px 12px" }}>
              <DatePicker
                locale="ko"
                selected={startDate}
                selectsStart
                minDate={new Date()}
                dateFormat="yyyy년 MM월 dd일"
                useWeekdaysShort={true}
                onChange={this.handleSurveyPeriod.bind(this, "start_date")}
                startDate={startDate}
                endDate={endDate}
              />
            </Col>
            <Col style={{ padding: "0px 0px 0px 12px" }}>
              <label>~</label>
            </Col>
            <Col style={{ padding: "0px 0px 0px 12px" }}>
              <DatePicker
                locale="ko"
                selected={endDate}
                selectsEnd
                minDate={startDate}
                dateFormat="yyyy년 MM월 dd일"
                useWeekdaysShort={true}
                onChange={this.handleSurveyPeriod.bind(this, "end_date")}
                startDate={startDate}
                endDate={endDate}
              />
            </Col>
          </Row>
        </div>
      </Form.Group>
    );

    return (
      <div className="SurveyHeader">
        <Col xs={12} className="edit">
          <Form>
            {headerTags}
          </Form>
        </Col>
      </div>
    );
  }
}
