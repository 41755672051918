import React from "react";
import { Redirect } from "react-router-dom";
import auth from '../utils/auth'

const Login = () => {
  return (
    <>
      {/*<Redirect to={auth.getLoginURL()} />*/}
      {auth.isLogin() ? <Redirect to="/" /> : window.location.replace(auth.getLoginURL())}
    </>
  )
}

export default Login;
