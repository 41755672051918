import React, { useState, useEffect } from "react";
import ReactQuill, { Quill } from 'react-quill';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Container, Col, Row, Button, Card, Form, Modal } from '@themesberg/react-bootstrap';
import { Breadcrumb } from '@themesberg/react-bootstrap';
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router-dom";

import auth from "../../utils/auth";
import pg from "../../utils/pg";

import "../../css/post.css";
import gadget from "../../utils/gadget";

export default (props) => {
  const propId = props.location.state.id;
  const propTitle = props.location.state.title;
  const propContents = props.location.state.contents;
  const propAuthor = props.location.state.author;
  const history = useHistory();

  const [post, setPost] = useState({ id: 0, title: "", contents: "", author: "" });
  const [comment, setComment] = useState();
  const [commentList, setCommentList] = useState([]);
  const [show, setShow] = useState(false);
  const [disableRegister, setDisableRegister] = useState(false);
  const [reload, setReload] = useState(false);

  const handleUpdate = (event) => {
    console.log("handleSubmitPermission");
    event.preventDefault();
  }

  const toggle = () => {
    setShow(!show);
  }

  const registerComment = async () => {
    setDisableRegister(true);
    await pg.registerComment(post.id, comment, auth.getUserID(), new Date().getTime());
    setDisableRegister(false);
    setReload(!reload);
    setComment("");
  }

  const deleteComment = async (id, commentId) => {
    await pg.deleteComment(id, commentId);
    setReload(!reload);
  }

  const handleChange = (event) => {
    setComment(event.target.value);
  };

  useEffect(() => {
    setPost({ id: propId, title: propTitle, contents: propContents, author: propAuthor });

    pg.getComment(propId)
      .then(res => {
        if (res) {
          setCommentList(res);
        }
      }).catch((err) => {
        //setError(err);
      })
  }, [reload]);

  const onDelete = async () => {
    await pg.deletePost(post);
    //alert(post.title + "" + post.contents + post.author + new Date().getTime());

    window.location.replace("/board/board");
  }

  const CommentRow = () => {
    return (commentList.map(t =>
      <Row key={t.id}>
        <Col md={9} className="mb-1">- {t.comment}</Col>
        <Col md={3} className="mb-1 text-right">
          <Row>
            <div style={{ fontSize: "14px" }}>{t.commenter === "undefined" ? "익명" : gadget.maskEmail(t.commenter)}
              {(t.commenter === auth.getUserID()) &&
                <Button variant="close" size="xs" onClick={() => { deleteComment(post.id, t.id) }} />
              }
            </div>
          </Row>
          <Row>
            <div style={{ fontSize: "12px" }}>{new Date(t.date).toLocaleDateString()} {new Date(t.date).toLocaleTimeString()}</div>
          </Row>
        </Col>
      </Row>
    )
    )
  }

  return (
    <Container fluid className="main-content-container">

      {/* Page Header */}
      <Row noGutters className="page-header py-4">
        {/*<PageTitle sm="4" title="List" subtitle="Apps" className="text-sm-left" />*/}
        <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
          <Breadcrumb.Item><FontAwesomeIcon icon={faHome} /></Breadcrumb.Item>
          <Breadcrumb.Item href="/board/board">Board</Breadcrumb.Item>
          <Breadcrumb.Item active>Detail</Breadcrumb.Item>
        </Breadcrumb>
        <h4>Post Detail</h4>
        <p className="mb-0">
          { }
        </p>
      </Row>
      <Container>
        <Row>
          <Col xs={12} xl={12}>
            <Card border="light" className="bg-white shadow-sm mb-4">
              {/*<Card.Header>
                <h5>Change permission</h5>
              </Card.Header>*/}
              <Card.Header>
                <div><h5>{post.title}</h5></div>
              </Card.Header>
              <Card.Body>
                <Form className="label-text" onSubmit={handleUpdate}>
                  <Row>
                    <Col md={12} className="mb-3">
                      {/*<Form.Label htmlFor="input-title mt-3">Title</Form.Label>
                      <Form.Control disabled id="input-title"
                        name="title"
                        value={post.title}/>*/}
                      {/*<ReactQuill readOnly value={post.title} maxLength={1000}
                        modules={{toolbar: false}} />*/}

                    </Col>
                  </Row>
                  <Row>
                    <Col md={12} className="mb-3">
                      {/*<Form.Label htmlFor="input-contents mt-3">Contents</Form.Label>
                      <Form.Control disabled as="textarea" id="input-contents" rows="10"
                        name="contents"
                        value={post.contents}
                      />*/}
                      {/*<ReactQuill readOnly value={post.contents} style={{ height: "500px" }} maxLength={1000}
                        modules={{toolbar: false}} />*/}
                      <div className="contents" style={{ minHeight: "400px" }} dangerouslySetInnerHTML={{ __html: post.contents }} />
                    </Col>
                  </Row>
                </Form>
              </Card.Body>
              <Card.Footer>
                <CommentRow />
                <Row className="mt-2">
                  <Col md={10}>
                    <Form.Control autoComplete="off"
                      id="input-comment"
                      name="comment"
                      placeholder="Comment"
                      onChange={handleChange}
                      value={comment}
                    />
                  </Col>
                  <Col md={2} className="text-right">
                    <Button disabled={disableRegister} style={{ width: "100px" }} variant="primary" onClick={registerComment}>Register</Button>
                  </Col>
                </Row>
              </Card.Footer>
            </Card>
            {
              (post.author === auth.getUserID()) &&
              <Row className="text-right">
                <Col className="mb-3">
                  <Button className="mb-2 mr-1 me-2" style={{ width: "100px" }} variant="primary"
                    onClick={() => {
                      history.push({
                        pathname: "/board/update",
                        state: {
                          id: post.id,
                          title: post.title,
                          contents: post.contents
                        }
                      })
                    }}>Modify</Button>
                  <Button className="mb-2 mr-1" style={{ width: "100px" }} variant="danger" onClick={toggle}>Delete</Button>
                </Col>
              </Row>
            }
          </Col>
          <Modal show={show} onHide={toggle}>
            <Modal.Header>Confirm</Modal.Header>
            <Modal.Body>
              <div className="mb-3">Are you sure you want to delete this post?</div>
            </Modal.Body>
            <Modal.Footer>
              <Button className="mb-2 mr-1" onClick={onDelete}>
                OK
              </Button>
              <Button className="mb-2 mr-1" onClick={toggle}>
                Cancel
              </Button>
            </Modal.Footer>
          </Modal>
        </Row>
      </Container>
    </Container>
  );
};
