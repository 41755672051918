import React, { Component } from "react";
import { Card, Form } from '@themesberg/react-bootstrap';

import Rating from "react-rating";
import StarEmptyImg from "../../../assets/img/survey/star-empty.png";
import StarFullImg from "../../../assets/img/survey/star-full.png";

export default class SurveyDetailsItem extends Component {
  constructor(props) {
    super(props);
  }


  render() {
    var surveyItem = this.props.data;

    var responseTags = [];
    var questionTag = [];

    questionTag.push(
      <Form.Label style={{ fontWeight: "bold" }}>{surveyItem.id}.&nbsp;{surveyItem.question}&nbsp;{surveyItem.required ? "*" : ""}</Form.Label>
    );
    if (surveyItem.is_description === true) {
      questionTag.push(
        <div>
          <Form.Label>({surveyItem.description})</Form.Label>
        </div>
      );
    }

    var candidateTag = [];
    var i = 0;
    switch (surveyItem.type) {
      case 'checkbox':
        if (surveyItem.candidate.length > 0) {
          i = 0;
          while (i < surveyItem.candidate.length) {
            candidateTag.push(
              <Form.Check
                type="checkbox"
                label={surveyItem.candidate[i].text}
                id={surveyItem.id + "item" + surveyItem.candidate[i].value}
                htmlFor={surveyItem.id + surveyItem.candidate[i].value}
                disabled />
            );
            i = i + 1;
          }
        }
        break;
      case 'radiogroup':
        if (surveyItem.candidate.length > 0) {
          i = 0;
          while (i < surveyItem.candidate.length) {
            candidateTag.push(
              <Form.Check
                type="radio"
                name={surveyItem.id + "radio"}
                label={surveyItem.candidate[i].text}
                id={surveyItem.id + "item" + surveyItem.candidate[i].value}
                htmlFor={surveyItem.id + surveyItem.candidate[i].value}
                disabled />
            );
            i = i + 1;
          }
        }
        break;
      case 'rating':
        candidateTag.push(
          <div style={{ marginBottom: "5px" }}>
            <Rating
              start={0}
              stop={surveyItem.candidate_cnt}
              initialRating={surveyItem.selected.length === 1 ? surveyItem.selected[0] : 0}
              emptySymbol={<img src={StarEmptyImg} className="icon" />}
              fullSymbol={<img src={StarFullImg} className="icon" />}
              disabled />
          </div>
        );
        break;
      default:
    }

    var commentTag = [];
    if (surveyItem.is_comment === true) {
      commentTag.push(
        <Form.Control as="textarea" rows="4" disabled />
      );
    }

    responseTags.push(
      <Card.Body>
        {questionTag}
        {candidateTag}
        {commentTag}
      </Card.Body>
    );

    return (
      <div>
        {responseTags}
      </div>
    );
  }
}