import React from "react";

import { Container, Image } from '@themesberg/react-bootstrap';
import AndroidGuide from "../assets/img/install-guide-android.jpg"
import IOSGuide from "../assets/img/install-guide-ios.jpg"
import queryString from 'query-string';

export default () => {
  let qs = queryString.parse(window.location.search);

  return (
    <Container fluid className="position-relative justify-content-between px-0 m-0">
      <div>
        <div>
          {qs.type === 'android' ? <Image src={AndroidGuide} style={{ width: "100%" }} /> : <Image src={IOSGuide} style={{ width: "100%" }} />}
        </div>
      </div>
    </Container>
  );
};
