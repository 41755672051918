import React, { Component } from "react";
import { Button, Form, Col, Row } from '@themesberg/react-bootstrap';

import Rating from "react-rating";
//import AutosizeInput from 'react-input-autosize';
import AutosizeInputRenewal from './AutosizeInputRenewal';
import InputRangeNumber from "./InputRangeNumber";
import "../styles/SurveyDataInfo.css";
import StarEmptyImg from "../../../assets/img/survey/star-empty.png";
import StarFullImg from "../../../assets/img/survey/star-full.png";

export default class SurveyDataInfo extends Component {
  constructor(props) {
    super(props);
    this.inputFormRequiredHandler = this.inputFormRequiredHandler.bind(this);
    this.inputFormDescriptionHandler = this.inputFormDescriptionHandler.bind(this);
    this.inputFormCommentHandler = this.inputFormCommentHandler.bind(this);
  }

  state = {
    qComponentWidth:100
  }

  inputFormRequiredHandler(e) {
    var changeRequiredItem = {
      id: this.props.data.id,
      type: this.props.data.type,
      question: this.props.data.question,
      required: e.target.checked,
      is_description: this.props.data.is_description,
      description: this.props.data.description,
      candidate_cnt: this.props.data.candidate_cnt,
      candidate: this.props.data.candidate,
      selected: this.props.data.selected,
      is_comment: this.props.data.is_comment,
      comment: this.props.data.comment
    }

    this.props.onUpdate(changeRequiredItem.id, changeRequiredItem);
  }

  inputFormDescriptionHandler(e) {
    var changeDescItem = {
      id: this.props.data.id,
      type: this.props.data.type,
      question: this.props.data.question,
      required: this.props.data.required,
      is_description: e.target.checked,
      description: this.props.data.description,
      candidate_cnt: this.props.data.candidate_cnt,
      candidate: this.props.data.candidate,
      selected: this.props.data.selected,
      is_comment: this.props.data.is_comment,
      comment: this.props.data.comment
    }

    this.props.onUpdate(changeDescItem.id, changeDescItem);
  }

  inputFormQuestionEditHandler = (e) => {
    var changeDescItem = {
      id: this.props.data.id,
      type: this.props.data.type,
      question: e.target.value,
      required: this.props.data.required,
      is_description: this.props.data.is_description,
      description: this.props.data.description,
      candidate_cnt: this.props.data.candidate_cnt,
      candidate: this.props.data.candidate,
      selected: this.props.data.selected,
      is_comment: this.props.data.is_comment,
      comment: this.props.data.comment
    }

    this.props.onUpdate(changeDescItem.id, changeDescItem);
  }

  inputFormDescriptionEditHandler = (e) => {
    var changeDescItem = {
      id: this.props.data.id,
      type: this.props.data.type,
      question: this.props.data.question,
      required: this.props.data.required,
      is_description: this.props.data.is_description,
      description: e.target.value,
      candidate_cnt: this.props.data.candidate_cnt,
      candidate: this.props.data.candidate,
      selected: this.props.data.selected,
      is_comment: this.props.data.is_comment,
      comment: this.props.data.comment
    }

    this.props.onUpdate(changeDescItem.id, changeDescItem);
  }

  inputFormCommentHandler(e) {
    var changeDescItem = {
      id: this.props.data.id,
      type: this.props.data.type,
      question: this.props.data.question,
      required: this.props.data.required,
      is_description: this.props.data.is_description,
      description: this.props.data.description,
      candidate_cnt: this.props.data.candidate_cnt,
      candidate: this.props.data.candidate,
      selected: this.props.data.selected,
      is_comment: e.target.checked,
      comment: this.props.data.comment
    }

    this.props.onUpdate(changeDescItem.id, changeDescItem);
  }

  inputFormCandidateEditHandler = (targetName, e) => {
    var newCandidate = Array.from(this.props.data.candidate);

    var i = 0;
    while (i < newCandidate.length) {
      if (targetName === newCandidate[i].value) {
        newCandidate[i] = {
          value: targetName,
          text: e.target.value
        }
      }
      i = i + 1;
    }

    var changeCandidateItem = {
      id: this.props.data.id,
      type: this.props.data.type,
      question: this.props.data.question,
      required: this.props.data.required,
      is_description: this.props.data.is_description,
      description: this.props.data.description,
      candidate_cnt: this.props.data.candidate_cnt,
      candidate: newCandidate,
      selected: this.props.data.selected,
      is_comment: this.props.data.is_comment,
      comment: this.props.data.comment
    }

    this.props.onUpdate(changeCandidateItem.id, changeCandidateItem);
  }

  inputFormCandidateCountHandler = (id, changedCnt) => {
    var oldCandidate = Array.from(this.props.data.candidate);
    var newCandidate = [];

    var i = 0;
    if (changedCnt < oldCandidate.length) {
      i = 0;
      while (i < changedCnt) {
        if (i < oldCandidate.length) {
          newCandidate.push(oldCandidate[i]);
        }
        i = i + 1;
      }
    } else if (changedCnt > oldCandidate.length) {
      i = 0;
      while (i < oldCandidate.length) {
        newCandidate.push(oldCandidate[i]);
        i = i + 1;
      }
      if (this.props.data.type === "checkbox" || this.props.data.type === "radiogroup") {
        newCandidate.push({
          value: i + 1,
          text: ""
        });
      } else {
        newCandidate.push({
          value: i + 1,
          text: "" + (i + 1)
        });
      }
    }

    var changeCandidateItem = {
      id: this.props.data.id,
      type: this.props.data.type,
      question: this.props.data.question,
      required: this.props.data.required,
      is_description: this.props.data.is_description,
      description: this.props.data.description,
      candidate_cnt: changedCnt,
      candidate: newCandidate,
      selected: this.props.data.selected,
      is_comment: this.props.data.is_comment,
      comment: this.props.data.comment
    }

    this.props.onUpdate(changeCandidateItem.id, changeCandidateItem);
  }

  updateDimensions = () => {
    console.log("Window Resize");
    var questionTD = document.getElementsByClassName('SurveyListPage')[0];
    var questionTdWidth = questionTD.offsetWidth;
    this.setState({
      qComponentWidth:questionTdWidth
    });
  };
  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions);
    this.updateDimensions();
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  render() {
    var surveyItem = this.props.data;

    var tags = [];

    /*
    var optTags = [];
    var optTag = [];
    optTag.push(<Form.Check inline type="checkbox" label="Required&nbsp;&nbsp;" name="isRequired" checked={surveyItem.required} onChange={this.inputFormRequiredHandler} />)
    optTag.push(<Form.Check inline type="checkbox" label="Description&nbsp;&nbsp;" name="isDescription" checked={surveyItem.is_description} onChange={this.inputFormDescriptionHandler} />)
    if (surveyItem.type === "checkbox" || surveyItem.type === "radiogroup" || surveyItem.type === "rating") {
      optTag.push(<Form.Check inline type="checkbox" label="Other comments&nbsp;&nbsp;" name="isComment" checked={surveyItem.is_comment} onChange={this.inputFormCommentHandler} />)
      if (surveyItem.type === "checkbox" || surveyItem.type === "radiogroup") {
        optTag.push(<InputRangeNumber min={2} max={10} label="Options" id={surveyItem.id} value={surveyItem.candidate_cnt} onNumChange={this.inputFormCandidateCountHandler} />);
      } else {
        optTag.push(<InputRangeNumber min={5} max={10} label="Options" id={surveyItem.id} value={surveyItem.candidate_cnt} onNumChange={this.inputFormCandidateCountHandler} />);
      }
    }

    optTags.push(
      <Navbar variant="none" style={{ paddingTop: "5px", paddingBottom: "5px" }}>
        <Container className="position-relative" style={{ padding: "0px" }}>
          <Navbar.Collapse>
            {optTag}
          </Navbar.Collapse>
        </Container>
      </Navbar>
    );
    */

    var optTags = [];
    optTags.push(
      <div className="OptionBox">
        <Col xs={12} className="edit">
          <Form>
            <Form.Group>
              <Row className="mt-1 pt-2">
                <Col xl={2} style={{paddingLeft:"12px", paddingRight:"12px", display: "flex", boxSizing: "border-box", whiteSpace: "normal", flexDirection:"column", justifyContent:"center"}}>
                  <Form.Check inline type="checkbox" label="Required&nbsp;&nbsp;" name="isRequired" checked={surveyItem.required} onChange={this.inputFormRequiredHandler} />
                </Col>
                <Col xl={2} style={{paddingLeft:"12px", paddingRight:"12px", display: "flex", boxSizing: "border-box", whiteSpace: "normal", flexDirection:"column", justifyContent:"center"}}>
                  <Form.Check inline type="checkbox" label="Description&nbsp;&nbsp;" name="isDescription" checked={surveyItem.is_description} onChange={this.inputFormDescriptionHandler} />
                </Col>
                <Col xl={2} style={{paddingLeft:"12px", paddingRight:"12px", display: "flex", boxSizing: "border-box", whiteSpace: "normal", flexDirection:"column", justifyContent:"center"}}>
                  { (surveyItem.type === "checkbox" || surveyItem.type === "radiogroup" || surveyItem.type === "rating")
                    ? <Form.Check inline type="checkbox" label="Comments&nbsp;&nbsp;" name="isComment" checked={surveyItem.is_comment} onChange={this.inputFormCommentHandler} />
                    : <></>
                  }
                </Col>
                <Col xl={4} style={{paddingLeft:"12px", paddingRight:"12px", display: "flex", boxSizing: "border-box", whiteSpace: "normal", flexDirection:"column", justifyContent:"center"}}>
                  { (surveyItem.type === "checkbox" || surveyItem.type === "radiogroup" || surveyItem.type === "rating")
                    ? (surveyItem.type === "checkbox" || surveyItem.type === "radiogroup")
                      ? <InputRangeNumber min={2} max={10} label="Options" id={surveyItem.id} value={surveyItem.candidate_cnt} onNumChange={this.inputFormCandidateCountHandler} />
                      : <InputRangeNumber min={5} max={10} label="Options" id={surveyItem.id} value={surveyItem.candidate_cnt} onNumChange={this.inputFormCandidateCountHandler} />
                    : <></>
                  }
                </Col>
              </Row>
            </Form.Group>
          </Form>
        </Col>
      </div>
    );

    var questionTags = [];
    questionTags.push(
      <span>
        <span style={{ fontWeight: "bold", fontSize: "medium" }}>{(this.props.idx + 1) + ". "}</span>
        <span>
          <AutosizeInputRenewal
            className="Survey-Question"
            placeholder="Input question here"
            placeholderIsMinWidth
            value={surveyItem.question}
            onChange={this.inputFormQuestionEditHandler.bind(this)}
            style={{ width: "inherit" }}
            inputStyle={{ borderTop: "none", borderLeft: "none", borderRight: "none", backgroundColor: "transparent" }}
            maxWidth={(this.props.idx+1 < 10) ? this.state.qComponentWidth - 53 : this.state.qComponentWidth - 60}
          />
        </span>
      </span>
    );

    if (surveyItem.required === true) {
      questionTags.push(<span style={{ fontWeight: "bold", fontSize: "medium" }}>&nbsp;*</span>);
    }

    if (surveyItem.is_description === true) {
      tags.push(
        <AutosizeInputRenewal className="Survey-Description"
          placeholder="Enter description"
          placeholderIsMinWidth
          value={surveyItem.description}
          onChange={this.inputFormDescriptionEditHandler.bind(this)}
          style={{ width: "inherit" }}
          inputStyle={{ borderTop: "none", borderLeft: "none", borderRight: "none", backgroundColor: "transparent" }}
          maxWidth={this.state.qComponentWidth - 53}
        />
      );
    }

    if (surveyItem.type === "checkbox") {
      if (surveyItem.candidate.length > 0) {
        let i = 0;
        while (i < surveyItem.candidate.length) {
          tags.push(<div>
            <Form.Check inline type="checkbox" readOnly />
            <AutosizeInputRenewal className="Survey-Candidate"
              placeholder={"item " + (i + 1)}
              placeholderIsMinWidth
              value={surveyItem.candidate[i].text}
              onChange={this.inputFormCandidateEditHandler.bind(this, surveyItem.candidate[i].value)}
              style={{}}
              inputStyle={{ borderTop: "none", borderLeft: "none", borderRight: "none", backgroundColor: "transparent" }}
              maxWidth={this.state.qComponentWidth - 86}
            />
          </div>);
          i = i + 1;
        }
      }
    } else if (surveyItem.type === "radiogroup") {
      if (surveyItem.candidate.length > 0) {
        let i = 0;
        while (i < surveyItem.candidate.length) {
          tags.push(<div>
            <Form.Check inline type="radio" readOnly />
            <AutosizeInputRenewal className="Survey-Candidate"
              placeholder={"item " + (i + 1)}
              placeholderIsMinWidth
              value={surveyItem.candidate[i].text}
              onChange={this.inputFormCandidateEditHandler.bind(this, surveyItem.candidate[i].value)}
              style={{}}
              inputStyle={{ borderTop: "none", borderLeft: "none", borderRight: "none", backgroundColor: "transparent" }}
              maxWidth={this.state.qComponentWidth - 86}
            />
          </div>);
          i = i + 1;
        }
      }
    } else if (surveyItem.type === "rating") {
      tags.push(<div className="Survey-Candidate-Rating">
        <Rating
          //https://react.rocks/example/react-rating
          start={0}
          stop={surveyItem.candidate_cnt}
          initialRating={3}
          emptySymbol={<img src={StarEmptyImg} className="icon" alt="Start-empty" />}
          //placeholderSymbol={<img src="./images/star-red.png" className="icon" />}
          fullSymbol={<img src={StarFullImg} className="icon" alt="Start-full" />}
        />
      </div>);
    }
    if (surveyItem.is_comment === true) {
      tags.push(
        <Form.Group className="mb-3" style={{ marginTop: "10px" }}>
          <Form.Control
            className="input-box"
            as="textarea"
            rows="2"
            placeholder=""
            readOnly="readonly"
          />
        </Form.Group>
      );
    }

    return (
      <table className="SurveyBorder">
        <colgroup>
          <col width="20px" />
          <col width="*" />
          <col width="20px" />
        </colgroup>
        <tbody>
          <tr>
            <td colSpan="2" style={{ padding: "10px" }}>
              {optTags}
            </td>
            <td className="text-right" style={{ padding: "10px 5px" }}>
              <Button variant="outline-gray" size="sm" className="me-1" onClick={() => { this.props.onDelete(this.props.data.id) }}>X</Button>
            </td>
          </tr>
          <tr>
            <td colSpan="3">
              <hr size="1px" width="99%" color="darkgray" style={{ marginTop: "0px" }} noshade="true" />
            </td>
          </tr>
          <tr>
            <td colSpan="3" style={{ padding: "10px 10px 10px 10px" }} className="td_box">
              {questionTags}
            </td>
          </tr>
          <tr>
            <td colSpan="3" style={{ padding: "10px 20px 10px 28px" }}>
              {tags}
            </td>
          </tr>
        </tbody>
      </table>
    );
  }
}