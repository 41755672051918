import React from "react";
import "../../css/post.css";

import { Badge, Image } from '@themesberg/react-bootstrap';

import { ReactComponent as LikeIcon } from "../../assets/img/like.svg";
import { ReactComponent as UnlikeIcon } from "../../assets/img/unlike.svg";
import { ReactComponent as ReplyIcon } from "../../assets/img/reply.svg";
//import { ReactComponent as ShareIcon } from "../../assets/img/share.svg";
//import { ReactComponent as SaveIcon } from "../../assets/img/save.svg";
import { ReactComponent as ViewIcon } from "../../assets/img/view.svg";
import LGPGLogo from "../../assets/img/lgpg-logo.png"
import gadget from "../../utils/gadget";

// const useStyles = makeStyles(() => ({
//   small: {
//     width: "1.5rem",
//     height: "1.5rem",
//     marginRight: "0.625rem",
//     fontSize: "1rem",
//   },
// }));

function Post({ id, title, contents, author, date, views, likes, category, commentCount, iconPath }) {
  //const classes = useStyles();

  return (
    <div className="post">
      <div className="pt-2 ps-2 mb-2">
        {
          category === "1" ? <Badge pill bg={"success"} className="badge-md">개선제안</Badge> :
            category === "2" ? <Badge pill bg={"info"} className="badge-md">문의</Badge> :
              category === "3" ? <Badge pill bg={"danger"} className="badge-md">오류</Badge> :
                category === "4" ? <Badge pill bg={"dark"} className="badge-md">기타</Badge> : "-"
        }
      </div>
      <div className="post__header pt-0">
        {/*<Avatar
          className={classes.small}
          alt={username.toUpperCase()}
          src="/static/image/avatar/1.jpg"
        />*/}

        <h5>
          {iconPath ? <Image src={iconPath} className="image image-xs me-1" /> : <Image src={LGPGLogo} className="image image-xs me-1" />}
          {title}
        </h5>
      </div>

      {/*String(contentType).includes("video") ? (
        <video
          className="post__image"
          autoPlay
          controls
          muted
          src={imageUrl}
          type={String(contentType)}
        />
      ) : (
        <img className="post__image" src={imageUrl} alt="" />
      )*/}
      <div className="post__body">
        {/*<div className="" dangerouslySetInnerHTML={{ __html: contents }} />*/}
        <div className="post__contents mb-2">
          {contents.replace(/<img[^>]+\>/ig, "(사진)").replace(/(<([^>]+)>)/ig, "").substring(0, 100)}
        </div>
        <div align="right" className="post__text mt-2">
          {author === "undefined" ? "익명" : gadget.maskEmail(author)}
        </div>
        <div align="right" style={{ fontSize: "12px" }}>{new Date(date).toLocaleDateString()} {new Date(date).toLocaleTimeString()}</div>

        <section className="ltpMr  Slqrh">
          <span className="iconview">
            <button className="post__buttonIcon">
              <ViewIcon fill="#262626" height="1.2rem" width="1.2rem" />
            </button>
          </span>

          <span className="mt-2 me-2">
            {views}
          </span>

          <span className="iconlike">
            <button
              className="post__buttonIcon"
            >
              {false ? (
                <LikeIcon fill="#ed4956" height="1.2rem" width="1.2rem" />
              ) : (
                <UnlikeIcon fill="#262626" height="1.2rem" width="1.2rem" />
              )}
            </button>
          </span>

          <span className="mt-2">
            {likes}
          </span>
          <span className="iconcomment">
            <button className="post__buttonIcon">
              <ReplyIcon fill="#262626" height="1.2rem" width="1.2rem" />
            </button>
          </span>

          <span className="mt-2">
            {commentCount}
          </span>
          {/*
          <span className="iconshare">
            <button className="post__buttonIcon">
              <ShareIcon fill="#262626" height="1.5rem" width="1.5rem" />
            </button>
          </span>
          */}
          {/*<span className="iconsave">
            <button className="post__buttonIcon">
              <SaveIcon fill="#262626" height="1.2rem" width="1.2rem" />
            </button>
          </span>
          */}

        </section>

        {/*
          <span className="post__likeCount">
          <strong>좋아요 {favoriteCnt}개</strong>
        </span>
        */}
        {/*
        <div className="post__comments">
          {comments.map(({ id, comment }) => (
            <p key={id}>
              <strong>{comment.username}</strong> {comment.text}
            </p>
          ))}
        </div>
          */}
      </div>
      {/*
        <form className="post__commentBox">
          <input
            className="post__input"
            type="text"
            placeholder="Add a comment..."
            value={comment}
            onChange={(e) => setComment(e.target.value)}
          />
          <button
            disabled={!comment}
            className="post__button"
            type="submit"
            onClick={postComment}
          >
            Post
          </button>
        </form>
        */}
    </div>
  );
}

export default Post;
