import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import { Col, Row, Card, Form, Button, Modal } from '@themesberg/react-bootstrap';
import { Routes } from "../routes";
import pg from "../utils/pg";
import auth from "../utils/auth";

import axios from 'axios';
import { PG_CONFIG } from "../data/pgConfig"

export const UserProfile = (props) => {
  const [request, setRequest] = useState({ requestState: "", requestType: "1", requestReason: "" });
  const [show, setShow] = useState(false);
  const [department, setDepartment] = useState("");
  const [disabledChange, setDisabledChange] = useState(false);

  const toggle = () => {
    setShow(!show);
  }

  const handleRequestChange = (event) => {
    // console.log('===== debug name:', event.target.name);
    // console.log('===== debug value:', event.target.value);
    setRequest({ ...request, [event.target.name]: event.target.value });
  };

  const handleDepartmentChange = (event) => {
    setDepartment(event.target.value);
  };

  const onClickDepartment = async () => {
    setDisabledChange(true);
    await pg.updateUserDepartment(auth.getUserInfo().userNumber, department);
    setDisabledChange(false);
  }

  const handleSubmitPermission = async (event) => {
    console.log("handleSubmitPermission");
    event.preventDefault();

    //setRequest({ ...request, requestState: 1 });

    pg.requestUserPermission(auth.getUserInfo().userNumber, auth.getUserID(), request);

    setShow(true);
  }

  useEffect(() => {
    axios.get(PG_CONFIG.apiGatewayUrl + '/user/info/' + auth.getUserInfo().userNumber)
      .then((res) => {
        setDepartment(res.data.department);
      })
  }, []);

  useEffect(() => {
    //setLoading(true);

    pg.getRequestedPermission(auth.getUserInfo().userNumber)
      .then(res => {
        if (res) {
          let req = {
            requestType: res.request_type,
            requestReason: res.request_reason,
            requestState: res.request_state
          }
          setRequest(req);
        }
      })
  }, []);

  return (
    <>
      <Card border="light" className="bg-white shadow-sm mb-4">
        <Card.Header>
          <h5>Profile</h5>
        </Card.Header>
        <Card.Body>
          <Row>
            <Col md={8} className="mb-3">
              <Form.Group id="userId">
                <Form.Label>User ID</Form.Label>
                <Form.Control disabled type="text" value={auth.getUserInfo().userID} />
              </Form.Group>
            </Col>
            <Col md={4} className="mb-3">
              <Form.Group id="userType">
                <Form.Label>Current User Type</Form.Label>
                <Form.Control disabled type="text"
                  value={auth.getUserTypeString()} />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Form.Label htmlFor="input-department mt-3">Department</Form.Label>
            <Col md={8} className="mb-3">
              <Form.Control disabled id="input-department"
                name="department"
                value={ department ? department.replaceAll(";", " - ") : "" }
                onChange={handleDepartmentChange}
              />
            </Col>
            <Col md={4} className="mb-3">
              {/*<Button variant="primary" disabled={disabledChange} onClick={onClickDepartment}>Change</Button>*/}
            </Col>
          </Row>
        </Card.Body>
        <Card.Footer>
          <Form className="label-text" onSubmit={handleSubmitPermission}>
            <Row>
              <Col md={12} className="mb-3">
                <Form.Label htmlFor="input-request-type">Request User Type</Form.Label>
                <Form.Select id="input-request-type"
                  name="requestType"
                  value={request.requestType}
                  onChange={handleRequestChange}>
                  <option value="1">Player</option>
                  <option value="2">Developer</option>
                  <option value="3">Analyst</option>
                  <option value="4">Admin</option>
                </Form.Select>
              </Col>
            </Row>
            <Row>
              <Col md={12} className="mb-3">
                <Form.Label htmlFor="input-reason mt-3">Request Reason</Form.Label>
                <Form.Control as="textarea" id="input-reason" rows="5"
                  name="requestReason"
                  value={request.requestReason}
                  placeholder={"권한 요청 사유를 상세히 작성바랍니다."}
                  onChange={handleRequestChange}
                />
              </Col>
            </Row>
            <Row>
              <div><h6>[User Type별 권한]</h6></div>
              <div> * Player: 앱 리스트 보기 </div>
              <div> * Developer: 앱 등록/수정, 대시보드, 설문 등록/결과 </div>
              <div> * Analyst: 대시보드, 설문 결과 </div>
              <div> * Admin: 전체 권한 </div>
            </Row>
            <div className="mt-3" align="right">
              <Button variant="primary" type="submit">Submit</Button>
            </div>
          </Form>
        </Card.Footer>
      </Card>
      <Modal show={show} onHide={toggle}>
        <Modal.Header>Permission</Modal.Header>
        <Modal.Body>
          <div className="mb-3">Your request to change permission have been submitted.</div>
        </Modal.Body>
        <Modal.Footer>
          <Button as={Link} className="mb-2 mr-1" to={Routes.AppList.path} >
            OK
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
