import React, { Component } from "react";
import SurveyList from "./SurveyList";
import SurveySelectForm from "./SurveySelectForm";
import "../styles/SurveyPage.css";

export default class SurveyPage extends Component {
  state = {};

  handleCreate = (form) => {
    var lastId = this.props.lastId;
    var newContent = {};

    lastId = lastId + 1;
    if (form.type === "singleinput") {
      //console.log("Add single text");
      newContent = {
        id: lastId,
        type: form.type,
        question: "Question " + lastId,
        required: false,
        is_description: false,
        description: "",
        candidate_cnt: 0,
        candidate: [],
        selected: [],
        is_comment: true,
        comment: ""
      };
    } else if (form.type === "checkbox") {
      //console.log("Add checkbox");
      newContent = {
        id: lastId,
        type: form.type,
        question: "Question " + lastId,
        required: false,
        is_description: false,
        description: "",
        candidate_cnt: 4,
        candidate: [
          {
            value: 1,
            text: ""
          },
          {
            value: 2,
            text: ""
          },
          {
            value: 3,
            text: ""
          },
          {
            value: 4,
            text: ""
          }
        ],
        selected: [],
        is_comment: false,
        comment: ""
      };
    } else if (form.type === "radiogroup") {
      //console.log("Add radiogroup");
      newContent = {
        id: lastId,
        type: form.type,
        question: "Question " + lastId,
        required: false,
        is_description: false,
        description: "",
        candidate_cnt: 3,
        candidate: [
          {
            value: 1,
            text: ""
          },
          {
            value: 2,
            text: ""
          },
          {
            value: 3,
            text: ""
          }
        ],
        selected: [],
        is_comment: false,
        comment: ""
      };
    } else if (form.type === "rating") {
      //console.log("Add rating");
      newContent = {
        id: lastId,
        type: form.type,
        question: "Question " + lastId,
        required: false,
        is_description: false,
        description: "",
        candidate_cnt: 5,
        candidate: [
          {
            value: 1,
            text: "1"
          },
          {
            value: 2,
            text: "2"
          },
          {
            value: 3,
            text: "3"
          },
          {
            value: 4,
            text: "4"
          },
          {
            value: 5,
            text: "5"
          }
        ],
        selected: [],
        is_comment: true,
        comment: ""
      };
    }
    this.props.onAddSurvey(newContent);
  };

  handleUpdateSurveyHeader = (changeHeader) => {
    this.props.onUpdateSurveyHeader(changeHeader);
  }

  handleUpdate = (id, changeItem) => {
    this.props.onUpdateSurvey(id, changeItem);
  };

  handleDelete = (id) => {
    this.props.onDeleteSurvey(id);
  };

  handleChangeOrder = (newOrderList) => {
    this.props.onChangeOrder(newOrderList);
  };

  handleUpdateSurveyPeriod = (se, millis) => {
    this.props.onUpdatePeriod(se, millis);
  }

  handleSelectApp = (appId, appName) => {
    this.props.onSelectApp(appId, appName);
  }

  render() {
    var userType = this.props.userType;
    var appList = this.props.appList;
    var appId = this.props.appId;
    var appName = this.props.appName;
    var surveyId = this.props.surveyId;
    var surveyCardList = this.props.surveylist;
    var surveyHeaderContent = this.props.surveyHeaderContent;
    var surveyStartDate = this.props.surveyStartDate;
    var surveyEndDate = this.props.surveyEndDate;

    return (
      <div>
        <div>
          <SurveyList
            userType={userType}
            surveyId={surveyId}
            surveyHeaderContent={surveyHeaderContent}
            surveyStartDate={surveyStartDate}
            surveyEndDate={surveyEndDate}
            onUpdateHeader={this.handleUpdateSurveyHeader}
            onUpdatePeriod={this.handleUpdateSurveyPeriod}
            appList={appList}
            appId={appId}
            appName={appName}
            data={surveyCardList}
            onUpdate={this.handleUpdate}
            onDelete={this.handleDelete}
            onChangeOrder={this.handleChangeOrder}
            onSelectApp={this.handleSelectApp} />

          <SurveySelectForm onCreate={this.handleCreate} />

        </div>
      </div>
    );
  }
}
