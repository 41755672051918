import React from 'react';
import { Redirect, Route, Switch } from "react-router-dom";
import { Routes } from "../routes";

// pages
// import Presentation from "./Presentation";
// import Upgrade from "./Upgrade";
// import DashboardOverview from "./dashboard/DashboardOverview";
// import Transactions from "./Transactions";
// import Settings from "./Settings";
// import BootstrapTables from "./tables/BootstrapTables";
// import Signin from "./examples/Signin";
// import Signup from "./examples/Signup";
// import ForgotPassword from "./examples/ForgotPassword";
// import ResetPassword from "./examples/ResetPassword";
// import Lock from "./examples/Lock";
import NotFoundPage from "./examples/NotFound";
import ServerError from "./examples/ServerError";

// documentation pages
// import DocsOverview from "./documentation/DocsOverview";
// import DocsDownload from "./documentation/DocsDownload";
// import DocsQuickStart from "./documentation/DocsQuickStart";
// import DocsLicense from "./documentation/DocsLicense";
// import DocsFolderStructure from "./documentation/DocsFolderStructure";
// import DocsBuild from "./documentation/DocsBuild";
// import DocsChangelog from "./documentation/DocsChangelog";

// components
import Navbar from "../components/Navbar";
import Sidebar from "../components/Sidebar";
// import Footer from "../components/Footer";
// import Preloader from "../components/Preloader";

// import Accordion from "./components/Accordion";
// import Alerts from "./components/Alerts";
// import Badges from "./components/Badges";
// import Breadcrumbs from "./components/Breadcrumbs";
// import Buttons from "./components/Buttons";
// import Forms from "./components/Forms";
// import Modals from "./components/Modals";
// import Navs from "./components/Navs";
// import Navbars from "./components/Navbars";
// import Pagination from "./components/Pagination";
// import Popovers from "./components/Popovers";
// import Progress from "./components/Progress";
// import Tables from "./components/Tables";
// import Tabs from "./components/Tabs";
// import Tooltips from "./components/Tooltips";
// import Toasts from "./components/Toasts";

// lg playground
import AppApproval from './app/Approval';
import AppDetails from './app/Details';
import AppList from './app/List';
import AppRegister from './app/Register';
import AppTestPage from './app/TestPage';
import Analytics from "./dashboard/Analytics";
import Crashlytics from './dashboard/Crashlytics';
import InstallGuide from './InstallGuide';
import LGPGUploader from './LGPGUploader';
import Login from './Login';
import LoginRedirect from './LoginRedirect';
import Logout from './Logout';
import MainMobile from './MainMobile';
import MainPage from './MainPage';
import Permission from './Permission';
import Profile from './Profile';

// lg playground - survey
import SurveyApproval from './survey/SurveyApproval';
import SurveyComplete from './survey/SurveyComplete';
import SurveyCreator from './survey/SurveyCreator';
import SurveyCreatorMobile from './survey/SurveyCreatorMobile';
import SurveyDetails from './survey/SurveyDetails';
import SurveyMyListMobile from './survey/SurveyMyListMobile';
import SurveyRequest from './survey/SurveyRequest';
import SurveyRequestMobile from './survey/SurveyRequestMobile';
import SurveyResponse from './survey/SurveyResponse';
import SurveyResult from './survey/SurveyResult';
import SurveyResultMobile from './survey/SurveyResultMobile';
import SurveySummary from './survey/SurveySummary';
import SurveySummaryMobile from './survey/SurveySummaryMobile';

// lg playground - group
import GroupLGEs from './group/GroupLGEs';
import GroupFUTs from './group/GroupFUTs';
import GroupDEVs from './group/GroupDEVs';

// lg playground - group
import Notice from './notice/SendNotice';

// lg playground - board
import Board from './board/Board';
import BoardMobile from './board/BoardMobile';
import NewPost from './board/NewPost';
import NewPostMobile from './board/NewPostMobile';
import PostDetail from './board/PostDetail';
import PostDetailMobile from './board/PostDetailMobile';
import UpdatePost from './board/UpdatePost';
import UpdatePostMobile from './board/UpdatePostMobile';

const RouteWithLoader = ({ component: Component, ...rest }) => {
  // const [loaded, setLoaded] = useState(false);

  // useEffect(() => {
  //   const timer = setTimeout(() => setLoaded(true), 1000);
  //   return () => clearTimeout(timer);
  // }, []);

  return (
    <Route {...rest} render={props => (<> {/*<Preloader show={loaded ? false : true} />*/} <Component {...props} /> </>)} />
  );
};

const RouteWithSidebar = ({ component: Component, ...rest }) => {
  // const [loaded, setLoaded] = useState(false);

  // useEffect(() => {
  //   const timer = setTimeout(() => setLoaded(true), 1000);
  //   return () => clearTimeout(timer);
  // }, []);

  // const localStorageIsSettingsVisible = () => {
  //   return localStorage.getItem('settingsVisible') === 'false' ? false : true
  // }

  // const [showSettings, setShowSettings] = useState(localStorageIsSettingsVisible);

  // const toggleSettings = () => {
  //   setShowSettings(!showSettings);
  //   localStorage.setItem('settingsVisible', !showSettings);
  // }

  return (
    <Route {...rest} render={props => (
      <>
        {/*<Preloader show={loaded ? false : true} />*/}
        <Sidebar />

        <main className="content">
          <Navbar />
          <Component {...props} />
          {/*<Footer toggleSettings={toggleSettings} showSettings={showSettings} />*/}
        </main>
      </>
    )}
    />
  );
};

function isMobile() {
  var UserAgent = navigator.userAgent;

  if ((UserAgent.match(/iPhone|iPod|Android|Windows CE|BlackBerry|Symbian|Windows Phone|webOS|Opera Mini|Opera Mobi|POLARIS|IEMobile|lgtelecom|nokia|SonyEricsson/i) != null) ||
      (UserAgent.match(/LG|SAMSUNG|Samsung/) != null)) {
    return true;
  } else {
    return false;
  }
}

export default () => (
  <Switch>
    {/* LG Playground*/}
    <Route exact path="/" component={() => { if (isMobile()) { return <Redirect to={"/mobile"} /> } else { return <Redirect to={"/main"} /> } }} />
    <Route exact path="/admin" component={() => { return <Redirect to={"/app/list"} /> }} />
    <RouteWithLoader exact path={Routes.Main.path} component={MainPage} />
    <RouteWithLoader exact path={Routes.MainMobile.path} component={MainMobile} />
    <RouteWithLoader exact path={Routes.Login.path} component={Login} />
    <RouteWithLoader exact path={Routes.Logout.path} component={Logout} />
    <RouteWithLoader exact path={Routes.LoginRedirect.path} component={LoginRedirect} />
    <RouteWithLoader exact path={Routes.InstallGuide.path} component={InstallGuide} />
    <RouteWithSidebar exact path={Routes.Analytics.path} component={Analytics} />
    <RouteWithSidebar exact path={Routes.Crashlytics.path} component={Crashlytics} />

    <RouteWithSidebar exact path={Routes.AppList.path} component={AppList} />
    <RouteWithSidebar exact path={Routes.AppDetails.path} component={AppDetails} />
    <RouteWithSidebar exact path={Routes.AppRegister.path} component={AppRegister} />
    <RouteWithSidebar exact path={Routes.AppApproval.path} component={AppApproval} />
    <RouteWithSidebar exact path={Routes.AppTestPage.path} component={AppTestPage} />

    <RouteWithSidebar exact path={Routes.UserProfile.path} component={Profile} />
    <RouteWithSidebar exact path={Routes.UserPermission.path} component={Permission} />

    <RouteWithSidebar exact path={Routes.SurveyRequest.path} component={SurveyRequest} />
    <RouteWithSidebar exact path={Routes.SurveyCreator.path} component={SurveyCreator} />
    <RouteWithSidebar exact path={Routes.SurveyApproval.path} component={SurveyApproval} />
    <RouteWithSidebar exact path={Routes.SurveyDetails.path} component={SurveyDetails} />
    <RouteWithSidebar exact path={Routes.SurveyResult.path} component={SurveyResult} />
    <RouteWithSidebar exact path={Routes.SurveySummary.path} component={SurveySummary} />
    <RouteWithLoader exact path={Routes.SurveyRequestMobile.path} component={SurveyRequestMobile} />
    <RouteWithLoader exact path={Routes.SurveyCreatorMobile.path} component={SurveyCreatorMobile} />
    <RouteWithLoader exact path={Routes.SurveyResultMobile.path} component={SurveyResultMobile} />
    <RouteWithLoader exact path={Routes.SurveySummaryMobile.path} component={SurveySummaryMobile} />
    <RouteWithLoader exact path={Routes.SurveyResponse.path} component={SurveyResponse} />
    <RouteWithLoader exact path={Routes.SurveyComplete.path} component={SurveyComplete} />
    <RouteWithLoader exact path={Routes.SurveyMyListMobile.path} component={SurveyMyListMobile} />

    <RouteWithSidebar exact path={Routes.GroupLGE.path} component={GroupLGEs} />
    <RouteWithSidebar exact path={Routes.GroupFUT.path} component={GroupFUTs} />
    <RouteWithSidebar exact path={Routes.GroupDEV.path} component={GroupDEVs} />

    <RouteWithSidebar exact path={Routes.Notice.path} component={Notice} />

    <RouteWithSidebar exact path={Routes.Board.path} component={Board} />
    <RouteWithSidebar exact path={Routes.NewPost.path} component={NewPost} />
    <RouteWithSidebar exact path={Routes.UpdatePost.path} component={UpdatePost} />
    <RouteWithSidebar exact path={Routes.PostDetail.path} component={PostDetail} />
    <RouteWithLoader exact path={Routes.BoardMobile.path} component={BoardMobile} />
    <RouteWithLoader exact path={Routes.NewPostMobile.path} component={NewPostMobile} />
    <RouteWithLoader exact path={Routes.PostDetailMobile.path} component={PostDetailMobile} />
    <RouteWithLoader exact path={Routes.UpdatePostMobile.path} component={UpdatePostMobile} />

    <RouteWithLoader exact path={Routes.NotFound.path} component={NotFoundPage} />
    <RouteWithLoader exact path={Routes.ServerError.path} component={ServerError} />

    <RouteWithLoader exact path={Routes.LGPGUploader.path} component={LGPGUploader} />

    {/* origin */}
    {/*
    <RouteWithLoader exact path={Routes.Presentation.path} component={Presentation} />
    <RouteWithLoader exact path={Routes.Signin.path} component={Signin} />
    <RouteWithLoader exact path={Routes.Signup.path} component={Signup} />
    <RouteWithLoader exact path={Routes.ForgotPassword.path} component={ForgotPassword} />
    <RouteWithLoader exact path={Routes.ResetPassword.path} component={ResetPassword} />
    <RouteWithLoader exact path={Routes.Lock.path} component={Lock} />
    <RouteWithLoader exact path={Routes.NotFound.path} component={NotFoundPage} />
    <RouteWithLoader exact path={Routes.ServerError.path} component={ServerError} />
    */}
    {/* pages */}
    {/*
    <RouteWithSidebar exact path={Routes.DashboardOverview.path} component={DashboardOverview} />
    <RouteWithSidebar exact path={Routes.Upgrade.path} component={Upgrade} />
    <RouteWithSidebar exact path={Routes.Transactions.path} component={Transactions} />
    <RouteWithSidebar exact path={Routes.Settings.path} component={Settings} />
    <RouteWithSidebar exact path={Routes.BootstrapTables.path} component={BootstrapTables} />
    */}
    {/* components */}
    {/*
    <RouteWithSidebar exact path={Routes.Accordions.path} component={Accordion} />
    <RouteWithSidebar exact path={Routes.Alerts.path} component={Alerts} />
    <RouteWithSidebar exact path={Routes.Badges.path} component={Badges} />
    <RouteWithSidebar exact path={Routes.Breadcrumbs.path} component={Breadcrumbs} />
    <RouteWithSidebar exact path={Routes.Buttons.path} component={Buttons} />
    <RouteWithSidebar exact path={Routes.Forms.path} component={Forms} />
    <RouteWithSidebar exact path={Routes.Modals.path} component={Modals} />
    <RouteWithSidebar exact path={Routes.Navs.path} component={Navs} />
    <RouteWithSidebar exact path={Routes.Navbars.path} component={Navbars} />
    <RouteWithSidebar exact path={Routes.Pagination.path} component={Pagination} />
    <RouteWithSidebar exact path={Routes.Popovers.path} component={Popovers} />
    <RouteWithSidebar exact path={Routes.Progress.path} component={Progress} />
    <RouteWithSidebar exact path={Routes.Tables.path} component={Tables} />
    <RouteWithSidebar exact path={Routes.Tabs.path} component={Tabs} />
    <RouteWithSidebar exact path={Routes.Tooltips.path} component={Tooltips} />
    <RouteWithSidebar exact path={Routes.Toasts.path} component={Toasts} />
    */}
    {/* documentation */}
    {/*
    <RouteWithSidebar exact path={Routes.DocsOverview.path} component={DocsOverview} />
    <RouteWithSidebar exact path={Routes.DocsDownload.path} component={DocsDownload} />
    <RouteWithSidebar exact path={Routes.DocsQuickStart.path} component={DocsQuickStart} />
    <RouteWithSidebar exact path={Routes.DocsLicense.path} component={DocsLicense} />
    <RouteWithSidebar exact path={Routes.DocsFolderStructure.path} component={DocsFolderStructure} />
    <RouteWithSidebar exact path={Routes.DocsBuild.path} component={DocsBuild} />
    <RouteWithSidebar exact path={Routes.DocsChangelog.path} component={DocsChangelog} />
    */}
    <Redirect to={Routes.NotFound.path} />
  </Switch>
);
