/* eslint-disable camelcase */
import axios from 'axios';
import qs from 'qs'
import emp from './emp'
import storage from './storage'
import { EMP_CONFIG } from '../data/empConfig'
import { PG_CONFIG } from '../data/pgConfig'


const LogMessage = {
  login: {
    invalidRedirectParameter: 'Invalid redirect parameter. ',
    successToGetAccessToken: 'Success to get access token. ',
    failToGetAccessToken: 'Fail to get access token. ',
    failToGetUserSession: 'Fail to get user session.',
    failToGetUserInfo: 'Fail to get user information.',
    lockedUser: 'Locked user'
  }
}


const auth = {
  getLoginURL() {
    const params = {
      authSvr: EMP_CONFIG.authSvr,
      client_id: EMP_CONFIG.client_id,
      //biz_code: EMP_CONFIG.biz_code,
      country: EMP_CONFIG.country,
      language: EMP_CONFIG.language,
      state: EMP_CONFIG.state,
      show_thirdparty_login: EMP_CONFIG.show_thirdparty_login,
      svcCode: EMP_CONFIG.svcCode,
      redirect_uri: EMP_CONFIG.redirect_uri,
      expire_session: EMP_CONFIG.expire_session
    }
    const loginUrlParameters = qs.stringify(params)
    return `${EMP_CONFIG.frontBaseUrl}${EMP_CONFIG.loginUrl}?${loginUrlParameters}`
  },
  getLogoutURL() {
    const params = {
      authSvr: EMP_CONFIG.authSvr,
      client_id: EMP_CONFIG.client_id,
      //biz_code: EMP_CONFIG.biz_code,
      country: EMP_CONFIG.country,
      language: EMP_CONFIG.language,
      state: EMP_CONFIG.state,
      show_thirdparty_login: EMP_CONFIG.show_thirdparty_login,
      svcCode: EMP_CONFIG.svcCode,
      redirect_uri: EMP_CONFIG.redirect_uri,
      expire_session: EMP_CONFIG.expire_session
    }
    const loginUrlParameters = qs.stringify(params)
    return `${EMP_CONFIG.frontBaseUrl}${EMP_CONFIG.loginUrl}?${loginUrlParameters}`
  },
  async login(oauth2url, code, user_number) {
    const auth_info = await emp.getAccessToken(oauth2url, code)
    if (!auth_info.access_token || !auth_info.expires_in || !auth_info.refresh_token) {
      throw new Error(LogMessage.login.failToGetAccessToken)
    }

    const response = await axios.get(PG_CONFIG.apiGatewayUrl + '/user/info/' + user_number);

    let user_type = 1;
    let user_department = "";
    if (response) {
      user_type = response.data.user_type;
      user_department = response.data.department;
    }
    if (user_type === undefined) {
      user_type = 1;
    }

    console.log('*** user type=', user_type);
    console.log('*** access token=', auth_info.access_token);

    const info = await emp.getUserProfile(auth_info.access_token)
    let user_id, id_type;
    if (!info) {
      console.log('*** error')
    } else {
      console.log('*** user info ***' + JSON.stringify(info))
      //user_id = info.account.userID;
      user_id = info.account.displayUserID;
      id_type = info.account.userIDType;
    }

    let userInfo = {
      authAccessToken: auth_info.access_token,
      authRefreshToken: auth_info.refresh_token,
      authExpiresIn: auth_info.expires_in,
      authValidationTime: Math.floor((new Date()).getTime() / 1000) + parseInt(auth_info.expires_in),
      userNumber: user_number,
      userType: user_type,
      userID: user_id,
      userDepartment: user_department,
      idType: id_type
    }
    storage.set('userInfo', userInfo);
    storage.set('isLogin', true);

    return userInfo
  },
  setUserInfo(userInfo) {
    storage.set('userInfo', userInfo)
  },
  getUserID() {
    const userInfo = storage.get('userInfo');
    if (userInfo) {
      return userInfo.userID;
    }
    return undefined;
  },
  getUserInfo() {
    return storage.get('userInfo') || {}
  },
  setUserType(user_type) {
    let userInfo = storage.get('userInfo');

    if (userInfo) {
      let newInfo = { ...userInfo, userType: user_type }
      //newInfo.userType = user_type;
      storage.set('userInfo', newInfo)
    }
  },
  getUserType() {
    // user type
    // 0: default
    // 1: player
    // 2: developer
    // 3: analyst
    // 4: admin
    const userinfo = storage.get('userInfo');
    if (userinfo) {
      return userinfo.userType;
    }
    return 0;
  },
  getUserTypeString() {
    const userinfo = storage.get('userInfo');
    if (userinfo) {
      if (userinfo.userType === 1) return "Player";
      else if (userinfo.userType === 2) return "Developer";
      else if (userinfo.userType === 3) return "Analyst";
      else if (userinfo.userType === 4) return "Admin";
    }
    return "None";
  },
  isLogin() {
    const isLogin = storage.get('isLogin') || false
    const userInfo = storage.get('userInfo')

    if (isLogin
        && userInfo !== undefined && userInfo.authValidationTime >= Math.floor((new Date()).getTime() / 1000)) {
      return true;
    } else {
      storage.remove('userInfo')
      storage.remove('isLogin')
      storage.clear()

      return false;
    }
  },
  logout() {
    storage.remove('userInfo')
    storage.remove('isLogin')
    storage.clear()
  }
}
export default auth
