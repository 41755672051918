/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";

import { Button, Container, Row, Col, Card, Table, Modal, Form } from '@themesberg/react-bootstrap';
import { Breadcrumb } from '@themesberg/react-bootstrap';
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import auth from "../utils/auth";
import pg from "../utils/pg";

function Permission(props) {
  const [permissionList, setPermissionList] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [showReject, setShowReject] = useState(false);
  const [reload, setReload] = useState(false);
  const [modalId, setModalId] = useState("");
  const [modalUserId, setModalUserId] = useState("");
  const [modalType, setModalType] = useState("");
  let comment = '';

  const openApproveModal = (event) => {
    console.log('openApproveModal', event.target.id, event.target.name, event.target.value);
    comment = '';
    setShow(true);
    setModalId(event.target.id);
    setModalUserId(event.target.name);
    setModalType(event.target.value);
  }

  const toggle = () => {
    setShow(false);
  }

  const openRejectModal = (event) => {
    console.log('openRejectModal', event.target.id, event.target.name);
    comment = '';
    setShowReject(true);
    setModalId(event.target.id);
    setModalUserId(event.target.name);
  }

  const toggleReject = () => {
    setShowReject(false);
  }

  const handleChange = (event) => {
    comment = event.target.value;
  };

  const onClickApprove = async () => {
    console.log('onClickApprove', modalId, modalType);
    await pg.updatePermissionApprove(modalId, modalType);

    pg.sendEmail(
      modalUserId,
      "권한 요청 승인 결과",
      "권한 요청이 승인되었습니다."
      + "\n\n"
      + "변경된 권한 적용을 위해 로그아웃 후 다시 로그인바랍니다."
      + "\n\n"
      + "Comment: " + comment
    );

    setReload(!reload);
    setShow(false);
  };

  const onClickReject = async () => {
    console.log('onClickReject', modalId);
    await pg.updatePermissionReject(modalId);

    pg.sendEmail(
      modalUserId,
      "권한 요청 승인 결과",
      "권한 요청이 반려되었습니다."
      + "\n\n"
      + "아래 사유를 확인하시고 재요청바랍니다."
      + "\n\n"
      + "Comment: " + comment
    );

    setReload(!reload);
    setShowReject(false);
  };

  const ConfirmModal = () => {
    return (
      <>
        <Modal show={show} onHide={toggle}>
          <Modal.Header>Confirm</Modal.Header>
          <Modal.Body><div className="mb-3">Are you sure you want to approve the permission?</div>
            <Form.Group>
              <Form.Control required type="text" placeholder="Comment"
                onChange={handleChange} />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button className="mb-2 mr-1" onClick={onClickApprove}>
              OK
            </Button>
            <Button className="mb-2 mr-1" onClick={toggle}>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal show={showReject} onHide={toggleReject}>
          <Modal.Header closeButton>Confirm</Modal.Header>
          <Modal.Body><div className="mb-3">Are you sure you want to reject the permission?</div>
            <Form.Group>
              <Form.Control required type="text" placeholder="Comment"
                onChange={handleChange} />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button className="mb-2 mr-1" onClick={onClickReject}>
              OK
            </Button>
            <Button className="mb-2 mr-1" onClick={toggleReject}>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    )
  }

  const PermissionTable = () => {
    const TableRow = (request) => {
      //const { appName, platform, version, requester, date, status } = props;

      const id = request.id;
      const userId = request.user_id;
      const requestType = request.request_type;
      const reason = request.request_reason;

      return (
        <>
          <tr className="permission">
            <td className="border-0">
              {/*<img src={awsPath.icon_file.name} className="rounded-circle me-2" width='30px' height='30px' />*/}
              <div><span className="h6">{userId}</span></div>
            </td>
            <td className="fw-bold border-0 h6">
              {(requestType === 1) ? "Player" :
                (requestType === 2) ? "Developer" :
                  (requestType === 3) ? "Analyst" :
                    (requestType === 4) ? "Admin" : "None"
              }
            </td>
            <td className="border-0">
              {reason ? reason : "-"}
            </td>
            <td className="fw-bold border-0 float-end">
              <Button variant="success" size="sm" className="m-1 my-0" style={{ width: "80px" }} id={id} name={userId} value={requestType} onClick={openApproveModal}>
                Approve
              </Button>
              <Button variant="danger" size="sm" className="m-1 my-0" style={{ width: "80px" }} id={id} name={userId} onClick={openRejectModal}>
                Reject
              </Button>
              <ConfirmModal />
            </td>
          </tr>
        </>
      );
    };

    return (
      <Card border="light" className="shadow-sm">
        <Card.Body className="pb-0">
          <Table responsive className="table-centered table-nowrap rounded mb-0">
            <thead className="thead-light">
              <tr>
                <th className="border-0">User ID</th>
                <th className="border-0">Request Type</th>
                <th className="border-0">Reason</th>
                <th className="border-0"></th>
              </tr>
            </thead>
            <tbody>
              {permissionList.map(r => <TableRow key={`request-${r.id}`} {...r} />)}
            </tbody>
          </Table>
        </Card.Body>
      </Card>
    );
  };

  useEffect(() => {
    setLoading(true);
    pg.getRequestedPermissions()
      .then(res => {
        if (res) {
          setPermissionList(res);
        }
        setLoading(false);
      }).catch((err) => {
        //setError(err);
      }).finally(() => {
        setLoading(false);
      })
  }, [reload]);

  return (
    <>
      {!auth.isLogin() && <Redirect to="/login" />}
      {auth.getUserType() !== 4 && <Redirect to="/examples/404" />}
      <Container fluid className="main-content-container">
        {/* Page Header */}
        <Row noGutters className="page-header py-4">
          {/*<PageTitle sm="4" title="Approval" subtitle="Apps" className="text-sm-left" />*/}
          <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
            <Breadcrumb.Item><FontAwesomeIcon icon={faHome} /></Breadcrumb.Item>
            <Breadcrumb.Item>User</Breadcrumb.Item>
            <Breadcrumb.Item active>Permission</Breadcrumb.Item>
          </Breadcrumb>
          <h4>Permission approval</h4>
          <p className="mb-0">
            { }
          </p>
        </Row>

        <PermissionTable />
      </Container>
    </>
  );
}

export default Permission;
