import React from "react";
import { Col, Row, Image, Container } from '@themesberg/react-bootstrap';

import CompleteImage from "../../assets/img/survey/survey_complete.png";

export default () => {
  let completeResult = new URLSearchParams(window.location.search).get("result");
  console.log(completeResult);

  let message1 = "설문이 완료되었습니다.";
  let message2 = "응답해 주셔서 감사합니다.";

  if (completeResult === "fail") {
    message1 = "이미 응답하였거나 오류가 발생하였습니다.";
    message2 = "감사합니다.";
  } else {
    message1 = "설문이 완료되었습니다.";
    message2 = "응답해 주셔서 감사합니다.";
  }

  return (
    <main>
      <section className="vh-100 d-flex align-items-center justify-content-center">
        <Container>
          <Row>
            <Col xs={12} className="text-center d-flex align-items-center justify-content-center">
              <div>
                <Image src={CompleteImage} className="img-fluid w-50" />

                <h3 className="text-primary mt-5">
                  {message1}
                </h3>
                <h5 className="text-primary">
                  {message2}
                </h5>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </main>
  );
};
