import React, { Component } from 'react';
import { Redirect } from "react-router-dom";

import { Container, Row, Card, Table, Pagination, Nav, Col, Form, InputGroup } from '@themesberg/react-bootstrap';
import axios from 'axios';
import { PG_CONFIG } from "../../data/pgConfig";
import { SURVEY_STATUS, SURVEY_USER_TYPE } from "./const/surveyConst";

import { Breadcrumb } from '@themesberg/react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faAngleDoubleLeft, faAngleDoubleRight, faAngleLeft, faAngleRight, faSearch, faTrash } from "@fortawesome/free-solid-svg-icons";

import moment from 'moment';

import auth from "../../utils/auth";
import "./styles/SurveyList.css";

export default class SurveyResult extends Component {
  state = {
    survey_result_list: [],
    total_items:0, //전체 항목 수
    total_pages:1, //항목 갯수에 따른 전제 페이지 수
    page_limit:10, //한 페이지에 보여주는 최대 목록 수
    page_range_limit:5, //하단의 Page 범위. 최대 page 표시 갯수
    active_page:1, //현재 Active Page
    search_type:1, //검색 타입 (1:Subject, 2:App Name, 3:Requester)
    search_text:"", //검색어
    search_typing_text:"" //검색어 입력중 임시 Text
  }

  loadItem = async () => {
    var userInfo = auth.getUserInfo();

    let response = null;
    try {
      let currentDate = new Date().getTime();

      let params = null;
      if ((userInfo.userType === SURVEY_USER_TYPE.ADMIN) || (userInfo.userType === SURVEY_USER_TYPE.ANALYST)) {
        if ((this.state.search_type === 1 || this.state.search_type === 2 || this.state.search_type === 3) && this.state.search_text !== "") {
          params = {
            survey_status: SURVEY_STATUS.APPROVED,
            current_date:currentDate,
            search_type: this.state.search_type,
            search_text: this.state.search_text
          };
        } else {
          params = {
            survey_status: SURVEY_STATUS.APPROVED,
            current_date:currentDate
          };
        }
      } else if (userInfo.userType === SURVEY_USER_TYPE.DEVELOPER) {
        if ((this.state.search_type === 1 || this.state.search_type === 2 || this.state.search_type === 3) && this.state.search_text !== "") {
          params = {
            user_number: userInfo.userNumber,
            survey_status: SURVEY_STATUS.APPROVED,
            current_date:currentDate,
            search_type: this.state.search_type,
            search_text: this.state.search_text
          };
        } else {
          params = {
            user_number: userInfo.userNumber,
            survey_status: SURVEY_STATUS.APPROVED,
            current_date:currentDate
          };
        }
      }
      console.log("Params: ", params);
      response = await axios.get(PG_CONFIG.apiGatewayUrl + '/survey/list/count', {
        params,
        timeout: 2000
      });

      if (response !== null) {
        let pageCnt;
        if (response.data[0].items > 0) {
          let remainder = response.data[0].items % this.state.page_limit;
          let result = parseInt(response.data[0].items / this.state.page_limit);
          if (remainder > 0) {
            pageCnt = result + 1;
          } else {
            pageCnt = result;
          }
        } else {
          pageCnt = 1;
        }
        console.log("Item count: ", response.data[0].items, " / Page count: ", pageCnt);
        this.setState({
          total_items: response.data[0].items,
          total_pages: pageCnt
        });
      }

      await this.getSurveyList(this.state.page_limit, this.state.active_page)
      .then(res => {
        if (res !== null) {
          const mySurveyData = res.data;
          let survyeListInfo = [];
          for (let mySurvey of mySurveyData) {
            survyeListInfo.push(mySurvey);
          }
          this.setState({
            survey_result_list: survyeListInfo
            //active_page:1
          });
        }
      }).catch((err) => {
        console.log("Error : ", err);
      }).finally(() => {
        //
      });
    } catch (error) {
      console.log(error);
    }
  }

  componentDidMount() {
    //this.loadItem();
    let activePage = new URLSearchParams(this.props.location.search).get("page");
    let searchType = new URLSearchParams(this.props.location.search).get("search_type");
    let searchText = new URLSearchParams(this.props.location.search).get("search_text");

    //console.log("search page: ", searchPage);
    //console.log("search type: ", searchType);
    //console.log("search text: ", searchText);

    if ((activePage !== null && activePage !== undefined)
        && (searchType !== null && searchType !== undefined)
        && (searchText !== null && searchText !== "")) {
      this.setState({
        active_page : parseInt(activePage),
        search_type : parseInt(searchType),
        search_text : searchText,
        search_typing_text : searchText
      }, () => this.loadItem());
      //this.onSelectPage(parseInt(searchPage));
    } else {
      if (activePage !== null && activePage !== undefined) {
        this.setState({
          active_page : parseInt(activePage),
        }, () => this.loadItem());
      } else {
        this.loadItem();
      }
    }
  }

  setURLParamsActivePage = (page) => {
    let searchType = new URLSearchParams(this.props.location.search).get("search_type");
    let searchText = new URLSearchParams(this.props.location.search).get("search_text");

    if ((searchType !== null && searchType !== undefined) && (searchText !== null && searchText !== "")) {
      this.props.history.replace(`?page=${page}&search_type=${this.state.search_type}&search_text=${this.state.search_typing_text}`);
    } else {
      this.props.history.replace(`?page=${page}`);
    }
  }

  getSurveyList = async (limit, page) => {
    console.log(limit, page);
    var userInfo = auth.getUserInfo();

    let response = null;
    let currentDate = new Date().getTime();

    let params = {
      limit:limit,
      page:page
    };

    if (userInfo.userType === SURVEY_USER_TYPE.ADMIN) {
      if ((this.state.search_type === 1 || this.state.search_type === 2 || this.state.search_type === 3) && this.state.search_text !== "") {
        params = {
          survey_status: SURVEY_STATUS.APPROVED,
          start_date:currentDate,
          search_type: this.state.search_type,
          search_text: this.state.search_text,
          limit:limit,
          page:page
        };
      } else {
        params = {
          survey_status: SURVEY_STATUS.APPROVED,
          start_date:currentDate,
          limit:limit,
          page:page
        };
      }
    } else if (userInfo.userType === SURVEY_USER_TYPE.DEVELOPER) {
      if ((this.state.search_type === 1 || this.state.search_type === 2 || this.state.search_type === 3) && this.state.search_text !== "") {
        params = {
          user_number: userInfo.userNumber,
          survey_status: SURVEY_STATUS.APPROVED,
          start_date:currentDate,
          search_type: this.state.search_type,
          search_text: this.state.search_text,
          limit:limit,
          page:page
        };
      } else {
        params = {
          user_number: userInfo.userNumber,
          survey_status: SURVEY_STATUS.APPROVED,
          start_date:currentDate,
          limit:limit,
          page:page
        };
      }
    }
    response = await axios.get(PG_CONFIG.apiGatewayUrl + '/survey/list', {
      params,
      timeout: 2000
    });
    //console.log(response.data);
    this.setURLParamsActivePage(page);
    return response;
  }

  onFirstPage = async () => {
    if (this.state.active_page === 1) return;

    await this.getSurveyList(this.state.page_limit, 1)
      .then(res => {
        if (res !== null) {
          const mySurveyData = res.data;
          let survyeListInfo = [];
          for (let mySurvey of mySurveyData) {
            survyeListInfo.push(mySurvey);
          }
          this.setState({
            survey_result_list: survyeListInfo,
            active_page:1
          });
        }
        //setLoading(false);
      }).catch((err) => {
        //setError(err);
      }).finally(() => {
        //setLoading(false);
      });
  }

  onLastPage = async () => {
    if (this.state.active_page === this.state.total_pages) return;

    await this.getSurveyList(this.state.page_limit, this.state.total_pages)
      .then(res => {
        if (res !== null) {
          const mySurveyData = res.data;
          let survyeListInfo = [];
          for (let mySurvey of mySurveyData) {
            survyeListInfo.push(mySurvey);
          }
          this.setState({
            survey_result_list: survyeListInfo,
            active_page:this.state.total_pages
          });
        }
        //setLoading(false);
      }).catch((err) => {
        //setError(err);
      }).finally(() => {
        //setLoading(false);
      });
  }

  onPreviousPageRange = async () => {
    console.log("previousPage: ");

    let currentPageRange = 0;
    let currentPageRemainder = (this.state.active_page) % this.state.page_range_limit;
    let currentPageResult = parseInt((this.state.active_page) / this.state.page_range_limit);

    if (currentPageRemainder > 0) {
      currentPageRange = currentPageResult + 1;
    } else {
      currentPageRange = currentPageResult;
    }

    if (currentPageRange === 1) return;

    let prevResultPage = ((currentPageRange-2)*this.state.page_range_limit)+1;

    await this.getSurveyList(this.state.page_limit, prevResultPage)
      .then(res => {
        if (res !== null) {
          const mySurveyData = res.data;
          let survyeListInfo = [];
          for (let mySurvey of mySurveyData) {
            survyeListInfo.push(mySurvey);
          }
          this.setState({
            survey_result_list: survyeListInfo,
            active_page:prevResultPage
          });
        }
        //setLoading(false);
      }).catch((err) => {
        //setError(err);
      }).finally(() => {
        //setLoading(false);
      });
  }

  onNextPageRange = async () => {
    console.log("nextPage: ");

    let currentPageRange = 0;
    let currentPageRemainder = (this.state.active_page) % this.state.page_range_limit;
    let currentPageResult = parseInt((this.state.active_page) / this.state.page_range_limit);

    if (currentPageRemainder > 0) {
      currentPageRange = currentPageResult + 1;
    } else {
      currentPageRange = currentPageResult;
    }

    console.log("C:", currentPageRange, "T:",this.state.total_pages);

    let totalPageRange = 0;
    let totalPageRemainder = this.state.total_pages%this.state.page_range_limit;
    let totalPageResult = parseInt(this.state.total_pages/this.state.page_range_limit);

    if (totalPageRemainder > 0) {
      totalPageRange = totalPageResult + 1;
    } else {
      totalPageRange = totalPageResult;
    }

    if (currentPageRange === totalPageRange) return;

    let nextResultPage = (currentPageRange*this.state.page_range_limit)+1;

    await this.getSurveyList(this.state.page_limit, nextResultPage)
      .then(res => {
        if (res !== null) {
          const mySurveyData = res.data;
          let survyeListInfo = [];
          for (let mySurvey of mySurveyData) {
            survyeListInfo.push(mySurvey);
          }
          this.setState({
            survey_result_list: survyeListInfo,
            active_page:nextResultPage
          });
        }
        //setLoading(false);
      }).catch((err) => {
        //setError(err);
      }).finally(() => {
        //setLoading(false);
      });
  }

  /*
  onPreviousPage = async () => {
    console.log("previous: ", this.state.active_page);
    if (this.state.active_page === 1) return;

    console.log("previous");
    await this.getSurveyList(this.state.page_limit, this.state.active_page-1)
      .then(res => {
        if (res !== null) {
          const mySurveyData = res.data;
          let survyeListInfo = [];
          for (let mySurvey of mySurveyData) {
            survyeListInfo.push(mySurvey);
          }
          this.setState({
            survey_result_list: survyeListInfo,
            active_page:this.state.active_page-1
          });
        }
        //setLoading(false);
      }).catch((err) => {
        //setError(err);
      }).finally(() => {
        //setLoading(false);
      });
  }

  onNextPage = async () => {
    console.log("next: ", this.state.active_page, this.state.total_pages);
    if (this.state.active_page === this.state.total_pages) return;

    console.log("next");
    await this.getSurveyList(this.state.page_limit, this.state.active_page+1)
      .then(res => {
        if (res !== null) {
          const mySurveyData = res.data;
          let survyeListInfo = [];
          for (let mySurvey of mySurveyData) {
            survyeListInfo.push(mySurvey);
          }
          this.setState({
            survey_result_list: survyeListInfo,
            active_page:this.state.active_page+1
          });
        }
        //setLoading(false);
      }).catch((err) => {
        //setError(err);
      }).finally(() => {
        //setLoading(false);
      });
  }
  */

  onSelectPage = async (page) => {
    await this.getSurveyList(this.state.page_limit, page)
      .then(res => {
        if (res !== null) {
          const mySurveyData = res.data;
          let survyeListInfo = [];
          for (let mySurvey of mySurveyData) {
            survyeListInfo.push(mySurvey);
          }
          this.setState({
            survey_result_list: survyeListInfo,
            active_page:page
          });
        }
        //setLoading(false);
      }).catch((err) => {
        //setError(err);
      }).finally(() => {
        //setLoading(false);
      });
  }

  selectSearchHandler = (e) => {
    console.log("Change search type: ", e.target.value);
    this.setState({
      active_page : 1,
      search_type : parseInt(e.target.value),
      search_text : "",
      search_typing_text : ""
    }, () => this.loadItem());
    this.props.history.replace(`?page=${this.state.active_page}`);
  }

  inputSearchText = (e) => {
    console.log(e.target.value);
    this.setState({
      search_typing_text : e.target.value
    });
  }

  clearSearchText = () => {
    console.log("Clear search text");
    this.setState({
      active_page : 1,
      search_text : "",
      search_typing_text : ""
    }, () => this.loadItem());
    this.props.history.replace(`?page=${this.state.active_page}`);
  }

  executeSearch = () => {
    console.log("execute search");
    this.setState({
      active_page : 1,
      search_text : this.state.search_typing_text
    }, () => this.loadItem());
    this.props.history.replace(`?page=${this.state.active_page}&search_type=${this.state.search_type}&search_text=${this.state.search_typing_text}`);
  }

  onEnterKeySearch = (e) => {
    if (e.key === 'Enter' ) {
      this.executeSearch();
    }
  }

  TableRow = (requestSurvey) => {
    const surveyData = requestSurvey;
    const surveyContents = JSON.parse(surveyData.survey_contents);
    const surveySubject = surveyContents.survey_header.survey_title;
    const surveyAppId = surveyData.app_id;
    const surveyAppName = surveyData.app_name;
    const surveyPeriod = moment(surveyData.start_date).format("YYYY.MM.DD") + "~" + moment(surveyData.end_date).format("YYYY.MM.DD");
    const surveyRequester = surveyData.request_id;
    const currentTime = new Date().getTime();

    let questionList = [];
    for (let question of surveyContents.survey_list) {

      let answerList = [];
      for (let ans of question.candidate) {
        answerList.push({
          value: ans.value,
          text: ans.text,
          amount: 0
        })
      }

      questionList.push({
        id: question.id,
        type: question.type,
        question: question.question,
        answer: answerList,
        //select:[],
        comment: []
      });
    }

    //console.log(questionList);

    return (
      <tr className="survey-list" onClick={() => {
        this.props.history.push({
          pathname: "/survey/summary",
          state: {
            id: surveyData.id,
            survey_header: {
              survey_title: surveyContents.survey_header.survey_title,
              survey_description: surveyContents.survey_header.survey_description
            },
            app_id: surveyData.app_id,
            app_name: surveyData.app_name,
            period: surveyPeriod,
            request_id: surveyData.request_id,
            question_list: questionList,
            running_state: ((currentTime >= surveyData.start_date) && (currentTime <= surveyData.end_date)) ? "진행중" : (currentTime > surveyData.end_date) ? "종료" : "대기"
          }
        })
      }}>
        <td className="border-0">
          {surveySubject}
        </td>
        <td className="fw-bold border-0">
          {
            surveyAppName
            ? ((surveyAppId.split(':')[0] === 'lgpg') || (surveyAppId.split(':')[0] === 'general'))
              ? surveyAppName
              : surveyAppName + ' (' + surveyAppId.split(':')[0] + ')'
            : "-"
            /* surveyAppName ? surveyAppName + ' (' + surveyAppId.split(':')[0] + ')' : "-" */
          }
        </td>
        <td className="border-0">
          {surveyPeriod ? surveyPeriod : "-"}
        </td>
        <td className="fw-bold border-0">
          {surveyRequester ? surveyRequester : "-"}
        </td>
        <td className="border-0">
          {((currentTime >= surveyData.start_date) && (currentTime <= surveyData.end_date)) ? "진행중" : (currentTime > surveyData.end_date) ? "종료" : "대기"}
        </td>
      </tr>
    );
  }

  render() {
    //console.log(this.state.survey_result_list);
    let searchFrom = [];
    let searchOptionForm =[];
    searchOptionForm.push(<option value={1}>설문제목</option>);
    searchOptionForm.push(<option value={2}>설문대상</option>);
    if (auth.getUserInfo().userType === SURVEY_USER_TYPE.ADMIN) {
      searchOptionForm.push(<option value={3}>작성자</option>);
    }
    searchFrom.push(
      <Nav className="row-vh d-flex flex-row justify-content-center pb-3">
        <Col xs={3} md={3} lg={3} xl={3}>
          <Form.Group className="mb-1">
            <Form.Select value={this.state.search_type} onChange={this.selectSearchHandler.bind(this)}>
              {searchOptionForm}
            </Form.Select>
          </Form.Group>
        </Col>
        <Col xs={4} md={4} lg={4} xl={4}>
          <InputGroup className="mb-1">
            <Form.Control type="text" placeholder="Search" value={this.state.search_typing_text} onChange={this.inputSearchText.bind(this)} onKeyPress={this.onEnterKeySearch.bind(this)} />
            <InputGroup.Text>
              { this.state.search_typing_text === "" ? <span></span> : <FontAwesomeIcon icon={faTrash} style={{cursor:"pointer", marginRight:"5px"}} onClick={this.clearSearchText.bind(this)} /> }
              <FontAwesomeIcon icon={faSearch} style={{cursor:"pointer"}} onClick={this.executeSearch.bind(this)}/>
            </InputGroup.Text>
          </InputGroup>
        </Col>
      </Nav>
    );

    var requestedList = [];
    if (this.state.survey_result_list.length <= 0) {
      requestedList.push(
        <tbody>
          <tr>
            <td colSpan="5">
              <div style={{ textAlign: "center" }}>진행중이거나 완료된 설문이 없습니다.</div>
            </td>
          </tr>
        </tbody>
      );
    } else {
      requestedList.push(
        <tbody>
          {this.state.survey_result_list.map(r => <this.TableRow key={`request-${r.id}`} {...r} />)}
        </tbody>
      )
    }

    let myPagination = [];

    let viewPageRange = 0;
    let viewPageRemainder = this.state.active_page % this.state.page_range_limit;
    let viewPageResult = parseInt(this.state.active_page / this.state.page_range_limit);

    if (viewPageRemainder > 0) {
      viewPageRange = viewPageResult + 1;
    } else {
      viewPageRange = viewPageResult;
    }

    for (let i = ((viewPageRange-1)*this.state.page_range_limit)+1; (i <= this.state.total_pages) && (i <= ((viewPageRange)*this.state.page_range_limit)); i++) {
      myPagination.push(
        <Pagination.Item active={this.state.active_page === i} onClick={this.onSelectPage.bind(this, i)} style={{minWidth:"45px", textAlign:"center"}}>{i}</Pagination.Item>
      );
    }

    return (
      <>
        {!auth.isLogin() && <Redirect to="/login" />}
        {auth.getUserType() < 2 && <Redirect to="/examples/404" />}
        <div>
          <Container fluid className="main-content-container">
            {/* Page Header */}
            <Row noGutters className="page-header py-4">
              {/*<PageTitle sm="4" title="Survey Result" subtitle="Survey" className="text-sm-left" />*/}
              <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
                <Breadcrumb.Item><FontAwesomeIcon icon={faHome} /></Breadcrumb.Item>
                <Breadcrumb.Item>Survey</Breadcrumb.Item>
                <Breadcrumb.Item active>Result</Breadcrumb.Item>
              </Breadcrumb>
              <h4>Survey result</h4>
              <p className="mb-0">
                { }
              </p>
            </Row>
            {/* Result List */}
            <Card border="light" className="shadow-sm">
              <Card.Body className="pb-0">
                <Table responsive className="table-centered table-nowrap rounded mb-0">
                  <thead className="thead-light">
                    <tr>
                      <th className="border-0">설문제목</th>
                      <th className="border-0">설문대상 (Type)</th>
                      <th className="border-0">설문기간</th>
                      <th className="border-0">작성자</th>
                      <th className="border-0">설문상태</th>
                    </tr>
                  </thead>
                  {requestedList}
                </Table>
                <Card.Footer className="px-3 border-0 d-lg-flex align-items-center justify-content-between">
                  <Nav>
                    <Pagination className="mb-2 mb-lg-0">
                      <Pagination.First onClick={this.onFirstPage.bind(this)} title="처음" style={{width:"45px", textAlign:"center"}}>
                        <FontAwesomeIcon icon={faAngleDoubleLeft} />
                      </Pagination.First>
                      <Pagination.Prev onClick={this.onPreviousPageRange.bind(this)} title="이전"  style={{width:"45px", textAlign:"center"}}>
                        <FontAwesomeIcon icon={faAngleLeft} />
                      </Pagination.Prev>
                      {myPagination}
                      <Pagination.Next onClick={this.onNextPageRange.bind(this)} title="다음"  style={{width:"45px", textAlign:"center"}}>
                        <FontAwesomeIcon icon={faAngleRight} />
                      </Pagination.Next>
                      <Pagination.Last onClick={this.onLastPage.bind(this)} title="마지막"  style={{width:"45px", textAlign:"center"}}>
                        <FontAwesomeIcon icon={faAngleDoubleRight} />
                      </Pagination.Last>
                    </Pagination>
                  </Nav>
                  <small className="fw-bold">
                    {/*Showing <b>{boardItem.length}</b> out of <b>25</b> entries*/}
                    Showing <b>{this.state.survey_result_list.length}</b> entries
                  </small>
                </Card.Footer>
              </Card.Body>
              {/* Search */}
              {searchFrom}
            </Card>
          </Container>
        </div>
      </>
    );
  }
}