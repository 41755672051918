import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import { Container } from '@themesberg/react-bootstrap';
import FadeLoader from "react-spinners/FadeLoader";
import qs from 'qs';
import auth from '../utils/auth'
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent, setUserId } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyB-7F_DUTmRi6GHfbGMIzVV-MEMNNEcuAA",
  authDomain: "playground-84ef0.firebaseapp.com",
  projectId: "playground-84ef0",
  storageBucket: "playground-84ef0.appspot.com",
  messagingSenderId: "167893440502",
  appId: "1:167893440502:web:42615d14f53cd0035ad9d7",
  measurementId: "G-VLZJXSJ85T"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const LoginRedirect = ({ location }) => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setLoading] = useState(false);

  const query = qs.parse(location.search, { ignoreQueryPrefix: true });
  console.log('query:', query);

  const { state, result } = query
  const { oauth2_backend_url, code, user_number } = query

  useEffect(() => {
    setLoading(true);
    auth.login(oauth2_backend_url, code, user_number)
      .then(res => {
        setData(res);
        setLoading(false);
      }).catch((err) => {
        setError(err);
      }).finally(() => {
        setLoading(false);
      })
  }, [oauth2_backend_url, code, user_number]);

  console.log('state:', state);
  console.log('result:', result);
  if (state === 'login' && result === '0') {
    return <Redirect to="/app/list" />
  }

  console.log('oauth2_backend_url:', oauth2_backend_url);
  console.log('code:', code);
  console.log('user_number:', user_number);

  // Firebase custom user_id setting
  logEvent(analytics, "user_number", user_number);
  setUserId(analytics, user_number);

  if (!oauth2_backend_url || !code || !user_number) {
    return <Redirect to="/examples/500" />
  }

  return (
    <>
      {isLoading &&
        <Container fluid className="main-content-container px-4 pb-4">
          <div className="loading" style={{ position: "fixed", top: "45%", left: "45%", transform: "translate(-50%, -50%)" }}>
            <FadeLoader height="15px" width="5px" color="#6b5ce7" radius="2px" margin="2px" />
          </div>
        </Container>}
      {error !== null && <Redirect to="/errors" />}
      {data !== null && <Redirect to="/app/list" />}
    </>
  )
}

export default LoginRedirect;
