import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";

import { Button, Container, Row, Col, Card, Table, Modal, Form } from '@themesberg/react-bootstrap';
import { Breadcrumb } from '@themesberg/react-bootstrap';
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import auth from "../../utils/auth";
import pg from "../../utils/pg";


const GroupDEVs = () => {
  const [groupDEVList, setGroupDEVList] = useState([]);

  const openDeleteModal = (event) => {

  }

  const DevGroupTable = () => {
    const TableRow = (request) => {
      return (
        <tr className="LGEGroup">
          <td className="border-0">
            {/* Group ID */}
          </td>
          <td className="fw-bold border-0 h6">
            {/* Group Name */}
          </td>
          <td className="fw-bold border-0 float-end">
            <Button variant="danger" size="sm" className="m-1 my-0" style={{ width: "80px" }} id={0/*GroupID*/} name={"DEV1팀"/*GroupName*/} onClick={openDeleteModal}>
              Delete
            </Button>
          </td>
        </tr>
      )
    }

    return (
      <Card border="light" className="shadow-sm">
        <Card.Body className="pb-0">
          <Table responsive className="table-centered table-nowrap rounded mb-0">
            <thead className="thead-light">
              <tr>
                <th className="border-0">No</th>
                <th className="border-0">Group Name</th>
                <th className="border-0"></th>
              </tr>
            </thead>
            {
              groupDEVList.length <= 0 ?
                <tbody>
                  <tr className="LGEGroup">
                    <td colSpan="3" className="border-0">
                      <div style={{ textAlign: "center" }}>등록된 개발 그룹이 없습니다.</div>
                    </td>
                  </tr>
                </tbody>
              :
                <tbody>
                  {groupDEVList.map(r => <TableRow key={`request-${r.id}`} {...r} />)}
                </tbody>
            }
          </Table>
        </Card.Body>
      </Card>
    )
  };

  return (
    <>
      {!auth.isLogin() && <Redirect to="/login" />}
      {auth.getUserType() !== 4 && <Redirect to="/examples/404" />}
      <Container fluid className="main-content-container">
        {/* Page Header */}
        <Row noGutters className="page-header py-4">
          {/*<PageTitle sm="4" title="Approval" subtitle="Apps" className="text-sm-left" />*/}
          <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
            <Breadcrumb.Item><FontAwesomeIcon icon={faHome} /></Breadcrumb.Item>
            <Breadcrumb.Item>Group</Breadcrumb.Item>
            <Breadcrumb.Item active>Developer Group</Breadcrumb.Item>
          </Breadcrumb>
          <h4>Developer Group</h4>
          <p className="mb-0">
            { }
          </p>
        </Row>
        {/* Group List */}
        <DevGroupTable />
      </Container>
    </>
  );
};

export default GroupDEVs;