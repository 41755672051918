import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Card, Col, Container, Form, FormCheck, Modal, Navbar, Row } from '@themesberg/react-bootstrap';
import ImageResize from 'quill-image-resize';
import React, { useEffect, useMemo, useRef, useState } from "react";
import ReactQuill, { Quill } from 'react-quill';
import { useHistory } from "react-router-dom";

import axios from 'axios';
import { PG_CONFIG } from "../../data/pgConfig";
import pg from "../../utils/pg";

Quill.register('modules/ImageResize', ImageResize);

export default (props) => {
  const propUserId = props.location.state.userId;
  const [post, setPost] = useState({ title: "", contents: "", author: "", date: "", deviceType: 0 });
  const [category, setCategory] = useState(1);
  const [appList, setAppList] = useState();
  const [appId, setAppId] = useState();
  const [disableCreate, setDisableCreate] = useState(false);
  const history = useHistory();
  const quillRef = useRef();
  const [anonymous, setAnonymous] = useState(false);
  const [show, setShow] = useState(false);

  const toggle = () => {
    setShow(!show);
  }

  const imageHandler = () => {
    // get editor
    console.log('imageHandler');
    const editor = quillRef.current.getEditor();

    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");

    function initialize() {
      document.body.onfocus = checkIt;
      console.log('initializing');
    }

    function checkIt() {
      document.body.onfocus = null;
      console.log('checked');
    }

    input.onclick = async () => {
      console.log('onclick');
      initialize();
    }

    input.click();

    input.onchange = async () => {
      document.body.appendChild(input);

      const file = input.files[0];

      let putUrl;
      let path = 'image/' + new Date().getTime() + '/' + file.name;
      await pg.getImageUploadUrl(path).then(
        res => {
          putUrl = res.putPresignedUrl;
        }
      )

      //let getUrl = PG_CONFIG.apiGatewayUrl + "/board/image/presignedurl?presigned_url=" + path;
      let cloudfrontUrl = 'https://d3owgbzckljy3p.cloudfront.net/' + path;
      //console.log('putPresignedUrl: ' + putUrl, 'getPresignedUrl: ' + getUrl);

      //const { preSignedPutUrl: presignedURL, readObjectUrl: imageURL } = (await getS3PresignedURL(file.name)).data;
      //await uploadImage(presignedURL, file);
      await uploadImageToS3(putUrl, file);

      try {
        const range = editor.getSelection();
        editor.insertEmbed(range.index, "image", cloudfrontUrl);
        editor.setSelection(range.index + 1);
      } catch (err) {
        console.log("upload err:", err);
      }

      document.body.removeChild(input);
    };
  };

  const uploadImageToS3 = async (presignedUrl, file) => {
    const response = await fetch(
      new Request(presignedUrl, {
        method: 'PUT',
        body: file,
        header: new Headers({
          "Content-Type": "image/png",
        }),
      })
    )
  }

  const modules = useMemo(() => {
    return {
      toolbar: {
        container: [
          [{ header: [1, 2, 3, false] }],
          ['bold', 'italic', 'underline', 'strike'],
          //[{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
          ['image'],
          [{ 'align': [] }, { 'color': [] }, { 'background': [] }]
        ],
        handlers: {
          image: imageHandler,
        },
      },
      ImageResize: {
        parchment: Quill.import('parchment')
      }
    };
  }, []);

  const formats = [//'font',
    'header',
    'bold', 'italic', 'underline', 'strike',
    //'list', 'bullet', 'indent',
    'image',
    'align', 'color', 'background',
  ];

  const handleChange = (event) => {
    setPost({ ...post, [event.target.name]: event.target.value });
  };

  const handleAnonymousCheck = (event) => {
    setAnonymous(event.target.checked);
  }

  const handleEditTitle = (content, delta, source, editor) => {
    setPost({ ...post, title: content });
  }

  const handleEditContents = (content, delta, source, editor) => {
    console.log(content);
    setPost({ ...post, contents: content });
  }

  const handleCreate = async (event) => {
    console.log("handleCreate");
    event.preventDefault();
    setDisableCreate(true);

    if (anonymous) {
      post.author = undefined;
    }

    if (/Android/i.test(navigator.userAgent)) {
      post.deviceType = 1;
    } else if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
      post.deviceType = 2;
    } else {
      post.deviceType = 0;
    }

    let hashed = "";
    if (anonymous) {
      const cryptojs = require('crypto-js');
      const secret = process.env.REACT_APP_BOARD_CRYPTO_SECRET;
      let encrypt = cryptojs.HmacSHA256(post.password, secret);
      hashed = cryptojs.enc.Hex.stringify(encrypt);
    }

    await pg.createNewPost(post, new Date().getTime(), category, appId, hashed);
    //alert(post.title + "" + post.contents + post.author + new Date().getTime());

    //window.location.replace("/board/mobile");
    history.goBack();
  }

  useEffect(() => {
    axios.get(PG_CONFIG.apiGatewayUrl + '/apps/list' + `?app_status=1&offset=0`).then(res => {
      setAppList(res.data.appList);
      //console.log("debug:", res.data.appList);
    });
  }, []);

  useEffect(() => {
    setPost({ ...post, author: propUserId });
  }, []);

  return (
    <div>
      <Navbar className="sticky-top" style={{ 'background-color': '#f5f8fb' }}>
        <Container fluid className="position-relative justify-content-between px-3">
          <Col>
            <h4 className="mb-0">
              <FontAwesomeIcon icon={faArrowLeft} className="me-2" onClick={() => { history.goBack() }} />
              새글 쓰기
            </h4>
          </Col>
        </Container>
      </Navbar>

      <main className="content mt-2 mx-1">
        <div>
          <Row>
            <Col xs={12} xl={12}>
              <Card border="light" className="bg-white shadow-sm">
                <Card.Body className="p-3">
                  <Form className="label-text" onSubmit={handleCreate}>
                    <Row>
                      <Form.Group className="mb-2">
                        <Form.Label htmlFor="input-app">앱</Form.Label>
                        <Form.Select id="input-app" value={appId} onChange={(e) => { setAppId(e.target.value) }}>
                          <option value="0">LG Playground</option>
                          {
                            appList ?
                              appList.map(({ app_id, app_name }) => (<option key={app_id} value={app_id}>{app_name + '(' + app_id.split(':')[0] + ')'}</option>)) : ""
                          }
                        </Form.Select>
                      </Form.Group>
                    </Row>
                    <Row>
                      <Form.Group className="mb-2">
                        <Form.Label htmlFor="input-category">유형</Form.Label>
                        <Form.Select id="input-category" value={category} onChange={(e) => { setCategory(e.target.value) }}>
                          <option value='1'>개선제안</option>
                          <option value='2'>문의</option>
                          <option value='3'>오류</option>
                          <option value='4'>기타</option>
                        </Form.Select>
                      </Form.Group>
                    </Row>
                    <Row>
                      <Form.Group className="mb-2">
                        <Form.Label htmlFor="input-title">제목</Form.Label>
                        <Form.Control id="input-title"
                          name="title"
                          value={post.title}
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </Row>
                    <Row>
                      <Col md={12} className="" style={{ height: "320px" }}>
                        <Form.Label htmlFor="input-contents mt-3">내용</Form.Label>
                        {/*<Form.Control as="textarea" id="input-contents" rows="10"
                        name="contents"
                        value={post.contents}
                        placeholder={"내용을 작성바랍니다."}
                        onChange={handleChange}
                      />*/}
                        <ReactQuill className="" placeholder={"내용을 작성바랍니다."}
                          ref={quillRef}
                          value={post.contents}
                          style={{ height: "200px" }}
                          modules={modules}
                          formats={formats}
                          onChange={(content, delta, source, editor) => handleEditContents(content, delta, source, editor)} />
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={4} className="d-flex align-items-center">
                        <Form.Check type="checkbox">
                          <FormCheck.Input id="anonymous-id" className="ms-1 me-2" checked={anonymous} onChange={handleAnonymousCheck} />
                          <FormCheck.Label htmlFor="anonymous-id" className="mb-0">익명</FormCheck.Label>
                        </Form.Check>
                      </Col>
                      <Col xs={8} className="text-right">
                        <Button className="mb-2 mr-1 me-2" disabled={disableCreate} style={{ width: "70px" }} variant="primary"
                          onClick={toggle}>등록</Button>
                        <Button className="mb-2 mr-1" style={{ width: "70px" }} variant="primary"
                          onClick={() => { history.goBack() }}>취소</Button>
                      </Col>
                    </Row>
                  </Form>
                </Card.Body>
              </Card>
            </Col>

            <Modal centered show={show} onHide={toggle}>
              <Modal.Header><strong>Password</strong></Modal.Header>
              <Modal.Body>
                <div className="mb-3">{anonymous ? "익명 글 작성 시 Password를 설정해 주세요." : "게시글을 등록하시겠습니까?"}</div>
                {anonymous &&
                  <Form.Group>
                    <Form.Control required type="password" id="input-password" name="password" placeholder="Password"
                      onChange={handleChange} />
                  </Form.Group>
                }
              </Modal.Body>
              <Modal.Footer>
                <Button className="mb-2 mr-1" variant="danger" onClick={handleCreate}>
                  확인
                </Button>
                <Button className="mb-2 mr-1" onClick={toggle}>
                  취소
                </Button>
              </Modal.Footer>
            </Modal>
          </Row>
        </div>
      </main>
    </div>
  );
};
