import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";

import { Button, Container, Row, Col, Card, Table, Modal, Form } from '@themesberg/react-bootstrap';
import { Breadcrumb } from '@themesberg/react-bootstrap';
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import auth from "../../utils/auth";
import pg from "../../utils/pg";


const SendNotice = () => {
  const [requestNotice, setRequestNotice] = useState({ requestTitle: "", requestMessage: "" });
  /* Add Modal */
  const [showSendModal, setShowSendModal] = useState(false);
  /* Alert Modal */
  const [showAlertModal, setShowAlertModal] = useState(false);
  const [alertModalTitle, setAlertModalTitle] = useState("");
  const [alertModalMessage, setAlertModalMessage] = useState("");

  const handleRequestMsgChange = (event) => {
    setRequestNotice({ ...requestNotice, [event.target.name]: event.target.value });
  };

  const handleSubmitMsg = () => {
    if (requestNotice.requestTitle === "") {
      setShowAlertModal(true);
      setAlertModalTitle("메시지 오류");
      setAlertModalMessage("메시지 제목이 없습니다.");
    } else if (requestNotice.requestMessage === "") {
      setShowAlertModal(true);
      setAlertModalTitle("메시지 오류");
      setAlertModalMessage("메시지 내용이 없습니다.");
    } else {
      setShowSendModal(true);
      console.log(requestNotice);
    }
  }

  const onSendModalConfirmHandler = async () => {
    setShowSendModal(false);

    /* FCM Push */
    try {
      await pg.sendFCMPush(
        requestNotice.requestTitle,
        requestNotice.requestMessage,
        "app",
        "",
        "",
        ""
      );

      setShowAlertModal(true);
      setAlertModalTitle("메시지 전송 완료");
      setAlertModalMessage("메시지 전송이 완료되었습니다.");
      setRequestNotice({ requestTitle: "", requestMessage: "" });
    } catch (error) {
      setShowAlertModal(true);
      setAlertModalTitle("메시지 전송 오류");
      setAlertModalMessage("메시지 전송중 오류가 발생하였습니다.");
    }
  }

  const toggleSendModal = () => {
    setShowSendModal(false);
  }

  const toggleAlertModal = () => {
    setShowAlertModal(false);
  }

  return (
    <>
      {!auth.isLogin() && <Redirect to="/login" />}
      {auth.getUserType() !== 4 && <Redirect to="/examples/404" />}
      <Container fluid className="main-content-container">
        {/* Page Header */}
        <Row noGutters className="page-header py-4">
          {/*<PageTitle sm="4" title="Approval" subtitle="Apps" className="text-sm-left" />*/}
          <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
            <Breadcrumb.Item><FontAwesomeIcon icon={faHome} /></Breadcrumb.Item>
            <Breadcrumb.Item active>Notice</Breadcrumb.Item>
          </Breadcrumb>
          <h4>Notice</h4>
          <p className="mb-0">
            { }
          </p>
        </Row>
        <Card border="light" className="bg-white shadow-sm mb-4">
          <Card.Header>
            <h5>Send Push Message</h5>
          </Card.Header>
          <Card.Body>
            <Form className="label-text">
              <Row>
                <Col md={12} className="mb-3">
                  <Form.Label htmlFor="input-title mt-3">Title</Form.Label>
                  <Form.Control type="text" id="input-title"
                    name="requestTitle"
                    value={requestNotice.requestTitle}
                    placeholder={"제목을 작성해 주세요. (최대 50자)"}
                    maxLength={50}
                    onChange={handleRequestMsgChange}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={12} className="mb-3">
                  <Form.Label htmlFor="input-message mt-3">Message</Form.Label>
                  <Form.Control as="textarea" id="input-message" rows="5"
                    name="requestMessage"
                    value={requestNotice.requestMessage}
                    placeholder={"메시지를 작성해 주세요. (최대 200자)"}
                    maxLength={200}
                    onChange={handleRequestMsgChange}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Label className="float-end text-warning">※ 주의 : 모든 LG Playground 앱 사용자에게 메시지가 전달됩니다.</Form.Label>
                </Col>
              </Row>
              <div className="mt-3" align="right">
                <Button variant="primary" onClick={handleSubmitMsg}>Send Message</Button>
              </div>
            </Form>
          </Card.Body>
        </Card>
        {/* Add Modal */}
        <Modal as={Modal.Dialog} centered show={showSendModal} onHide={toggleSendModal}>
          <Modal.Header>
            <Modal.Title className="h6">Push 메시지 전송 </Modal.Title>
            <Button variant="close" aria-label="Close" onClick={toggleSendModal} />
          </Modal.Header>
          <Modal.Body>
            <p>아래 내용으로 메시지를 전달하시겠습니까?</p>
            <Form className="rounded-3 bg-light p-2">
              <p className="p-2 mb-0">{requestNotice.requestTitle}</p>
              <hr className="m-0" />
              <p className="p-2 mb-0">{requestNotice.requestMessage}</p>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={onSendModalConfirmHandler}>전송</Button>
            <Button variant="link" className="text-gray ms-auto" onClick={toggleSendModal}>닫기</Button>
          </Modal.Footer>
        </Modal>
        {/* Alert Modal */}
        <Modal as={Modal.Dialog} centered show={showAlertModal} onHide={toggleAlertModal}>
          <Modal.Header>
            <Modal.Title className="h6">{alertModalTitle}</Modal.Title>
            <Button variant="close" aria-label="Close" onClick={toggleAlertModal} />
          </Modal.Header>
          <Modal.Body>
            <p>{alertModalMessage}</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="link" className="text-gray ms-auto" onClick={toggleAlertModal}>닫기</Button>
          </Modal.Footer>
        </Modal>
      </Container>
    </>
  );
};

export default SendNotice;