import React, { useRef, useState, useMemo } from "react";
import { useHistory } from "react-router-dom";
import ReactQuill, { Quill } from 'react-quill';
import ImageResize from 'quill-image-resize';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Container, Col, Row, Button, Card, Form, Navbar } from '@themesberg/react-bootstrap';
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

import pg from "../../utils/pg";
import { PG_CONFIG } from "../../data/pgConfig"

Quill.register('modules/ImageResize', ImageResize);

export default (props) => {
  const propId = useRef(props.location.state.id);
  const [propTitle, setPropTitle] = useState(props.location.state.title);
  const propContents = useRef(props.location.state.contents);
  const [disableCreate, setDisableCreate] = useState(false);
  const history = useHistory();
  const quillRef = useRef();

  const imageHandler = () => {
    // get editor
    console.log('imageHandler');
    const editor = quillRef.current.getEditor();

    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    document.body.appendChild(input);

    input.click();

    input.onchange = async () => {
      const file = input.files[0];

      let putUrl;
      let path = 'image/' + new Date().getTime() + '/' + file.name;
      await pg.getImageUploadUrl(path).then(
        res => {
          putUrl = res.putPresignedUrl;
        }
      )

      let getUrl = PG_CONFIG.apiGatewayUrl + "/board/image/presignedurl?presigned_url=" + path;
      //console.log('putPresignedUrl: ' + putUrl, 'getPresignedUrl: ' + getUrl);

      //const { preSignedPutUrl: presignedURL, readObjectUrl: imageURL } = (await getS3PresignedURL(file.name)).data;
      //await uploadImage(presignedURL, file);
      await uploadImageToS3(putUrl, file);

      try {
        const range = editor.getSelection();
        editor.insertEmbed(range.index, "image", getUrl);
        editor.setSelection(range.index + 1);
      } catch (err) {
        console.log("upload err:", err);
      }

      document.body.removeChild(input);
    };
  };

  const uploadImageToS3 = async (presignedUrl, file) => {
    const response = await fetch(
      new Request(presignedUrl, {
        method: 'PUT',
        body: file,
        header: new Headers({
          "Content-Type": "image/png",
        }),
      })
    )
  }

  const modules = useMemo(() => {
    return {
      toolbar: {
        container: [
          [{ header: [1, 2, 3, false] }],
          ['bold', 'italic', 'underline', 'strike'],
          //[{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
          ['image'],
          [{ 'align': [] }, { 'color': [] }, { 'background': [] }]
        ],
        handlers: {
          image: imageHandler,
        },
      },
      ImageResize: {
        parchment: Quill.import('parchment')
      }
    };
  }, []);

  const formats = [//'font',
    'header',
    'bold', 'italic', 'underline', 'strike',
    //'list', 'bullet', 'indent',
    'image',
    'align', 'color', 'background',
  ];

  const handleChange = (event) => {
    // console.log('===== debug name:', event.target.name);
    // console.log('===== debug value:', event.target.value);
    //setPost({ ...post, [event.target.name]: event.target.value });
    setPropTitle(event.target.value);
  };

  const handleEditContents = (content, delta, source, editor) => {
    //setPost({ ...post, contents: content });
    propContents.current = content;
  }


  const handleUpdate = async (event) => {
    console.log("handleUpdate");
    event.preventDefault();
    setDisableCreate(true);

    await pg.updatePost(propId.current, propTitle, propContents.current);
    //alert(post.title + "" + post.contents + post.author + new Date().getTime());

    //window.location.replace("/board/board");
    history.go(-2);
  }

  return (
    <div>
      <Navbar className="sticky-top" style={{ 'background-color': '#f5f8fb' }}>
        <Container fluid className="position-relative justify-content-between px-3">
          <Col>
            <h4 className="mb-0">
              <FontAwesomeIcon icon={faArrowLeft} className="me-2" onClick={() => { history.goBack() }} />
              수정하기
            </h4>
          </Col>
        </Container>
      </Navbar>
      <main className="content mt-2 mx-1">
        <div>
          <Row>
            <Col xs={12} xl={12}>
              <Card border="light" className="bg-white shadow-sm mb-4">
                {/*<Card.Header>
                <h5>Change permission</h5>
              </Card.Header>*/}
                <Card.Body className="p-3">
                  <Form className="label-text" onSubmit={handleUpdate}>
                    <Row>
                      <Col md={12} className="mb-3">
                        <Form.Label htmlFor="input-title mt-3">제목</Form.Label>
                        <Form.Control id="input-title"
                          name="title"
                          value={propTitle}
                          onChange={handleChange} />
                        {/*<ReactQuill className="mb-3" placeholder={"내용을 작성바랍니다."}
                        value={post.contents} style={{ height: "500px" }} maxLength={1000}
                        modules={modules}
                        formats={formats}
                        onChange={(content, delta, source, editor) => handleEditContents(content, delta, source, editor)} />*/}
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12} className="mb-3" style={{ height: "500px" }}>
                        <Form.Label htmlFor="input-contents mt-3">내용</Form.Label>
                        {/*<Form.Control as="textarea" id="input-contents" rows="10"
                        name="contents"
                        value={post.contents}
                        onChange={handleChange}
                      />*/}
                        <ReactQuill className="mb-3" placeholder={"내용을 작성바랍니다."}
                          ref={quillRef}
                          value={propContents.current} style={{ height: "400px" }}
                          modules={modules}
                          formats={formats}
                          onChange={(content, delta, source, editor) => handleEditContents(content, delta, source, editor)} />
                      </Col>
                    </Row>
                    {/*<Row>
                    <Col className="mb-3">
                      <Form.Group id="userId">
                        <Form.Label>User ID</Form.Label>
                        <Form.Control disabled type="text" value={auth.getUserInfo().userID} />
                      </Form.Group>
                    </Col>
                  </Row>*/}
                    <Row className="text-right">
                      <Col className="mb-3">
                        <Button className="mb-2 mr-1 me-2" disabled={disableCreate} style={{ width: "70px" }} variant="primary" type="submit">수정</Button>
                        <Button className="mb-2 mr-1" style={{ width: "70px" }} variant="primary"
                          onClick={() => { history.goBack() }}>취소</Button>
                      </Col>
                    </Row>
                  </Form>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </div>
      </main>
    </div>
  );
};
