const isDevelopment = process.env.NODE_ENV !== "production";

const EMP_B2C_KR_CONFIG = {
  frontBaseUrl: "https://kr.m.lgaccount.com/spx",
  //'frontBaseUrl': 'https://qt-kr.m.lgaccount.com/membership-spx-webapp',
  loginUrl: "/login/signIn",
  logoutUrl: "/logout/sign_out",
  client_id: process.env.REACT_APP_EMP_CLIENT_ID,
  client_secret: process.env.REACT_APP_EMP_CLIENT_SECRET,
  svcCode: "SVC618",
  country: "KR",
  language: "ko-kr",
  authSvr: "oauth2",
  state: "login",
  show_thirdparty_login: "AMZ,GGL,NAV,APPL",
  oauthUrl: "https://kr.lgeapi.com",
  tokenUrl: "/oauth/1.0/oauth2/token",
  expire_session: "Y",
};

const EMP_CONFIG_DEV = {
  ...EMP_B2C_KR_CONFIG,
  redirect_uri: "http://localhost:3000/redirect",
};

const EMP_CONFIG_PDT = {
  ...EMP_B2C_KR_CONFIG,
  redirect_uri: "https://lgplayground.lge.com/redirect",
  // 'frontBaseUrl': 'https://kr.lgaccount.com',
  // 'client_id': process.env.REACT_APP_EMP_CLIENT_ID,
  // 'client_secret': process.env.REACT_APP_EMP_CLIENT_SECRET
};

export const EMP_CONFIG = isDevelopment ? EMP_CONFIG_DEV : EMP_CONFIG_PDT;
