/* eslint-disable */
import React, { useState, useEffect } from "react";
import { useHistory, Redirect } from "react-router-dom";

import { Container, Col, Row, Card, Table, Nav, Pagination, Button, Dropdown, Form, InputGroup } from '@themesberg/react-bootstrap';
import { Breadcrumb } from '@themesberg/react-bootstrap';
import { faHome, faAngleDown, faPlus, faSearch, faAngleDoubleLeft, faAngleDoubleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { Routes } from "../../routes";

import auth from "../../utils/auth";
import pg from "../../utils/pg";
import gadget from "../../utils/gadget";
import "../../css/board.css";


function Board(props) {
  const history = useHistory();
  const [boardItem, setBoardItem] = useState([]);
  const [curPage, setCurPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isLoading, setLoading] = useState(false);
  const [category, setCategory] = useState("0");
  const [search, setSearch] = useState({ type: 1, text: "" });
  const page_limit = 10;
  const page_range_limit = 5;

  const onNextItem = async () => {
    if (curPage === totalPages) return;

    await pg.getBoardList(page_limit, curPage + 1, search.type, search.text, category)
      .then(res => {
        if (res) {
          setBoardItem(res);
        }
        setLoading(false);
      }).catch((err) => {
        //setError(err);
      }).finally(() => {
        setLoading(false);
      })

    setCurPage(curPage + 1);
  };

  const onPreviousItem = async () => {
    if (curPage === 1) return;

    await pg.getBoardList(page_limit, curPage - 1, search.type, search.text, category)
      .then(res => {
        if (res) {
          setBoardItem(res);
        }
        setLoading(false);
      }).catch((err) => {
        //setError(err);
      }).finally(() => {
        setLoading(false);
      })

    setCurPage(curPage - 1);
  };

  const onPageItem = async (page) => {
    await pg.getBoardList(page_limit, page, search.type, search.text, category)
      .then(res => {
        if (res) {
          setBoardItem(res);
        }
        setLoading(false);
      }).catch((err) => {
        //setError(err);
      }).finally(() => {
        setLoading(false);
      })

    setCurPage(page);
  };

  //Select *, Count(*) Over () AS total From board WHERE title LIKE '%LG%' ORDER BY id DESC LIMIT 10 OFFSET 0
  const changeSearchType = (e) => {
    console.log("Change search type: ", e.target.value);
    setSearch({ ...search, type: parseInt(e.target.value) });
    //searchTypingText.current = "";
  }

  const inputSearchText = (e) => {
    console.log(e.target.value);
    //setSearchTypingText(e.target.value);
    //searchTypingText.current = e.target.value;
    setSearch({ ...search, text: e.target.value });
  }

  const clearSearchText = () => {
    console.log("Clear search text");
    setSearchText("");
    //setSearchTypingText("");
  }

  const executeSearch = async () => {
    console.log("execute search");
    //setSearchText(searchTypingText.current);

    await loadItem();
  }

  const onEnterKeySearch = (e) => {
    if (e.key === 'Enter') {
      executeSearch();
    }
  }

  const loadItem = async () => {
    setLoading(true);
    await pg.getBoardList(page_limit, curPage, search.type, search.text, category)
      .then(res => {
        if (res) {
          setBoardItem(res);
        }

        let totalCount = res[0].total;
        let pageCnt;
        if (totalCount > 0) {
          let remainder = totalCount % page_limit;
          let result = parseInt(totalCount / page_limit);
          if (remainder > 0) {
            pageCnt = result + 1;
          } else {
            pageCnt = result;
          }
        } else {
          pageCnt = 1;
        }
        setTotalPages(pageCnt);
        setLoading(false);
      }).catch((err) => {
        //setError(err);
      }).finally(() => {
        setLoading(false);
      })
  }

  const PaginationItem = () => {
    let myPagination = [];
    let viewPageRange = 0;
    let viewPageRemainder = curPage % page_range_limit;
    let viewPageResult = parseInt(curPage / page_range_limit);

    if (viewPageRemainder > 0) {
      viewPageRange = viewPageResult + 1;
    } else {
      viewPageRange = viewPageResult;
    }

    for (let i = ((viewPageRange - 1) * page_range_limit) + 1; (i <= totalPages) && (i <= ((viewPageRange) * page_range_limit)); i++) {
      myPagination.push(
        <Pagination.Item active={curPage === i} onClick={() => onPageItem(i)}>{i}</Pagination.Item>
      );
    }

    return myPagination;
  }

  useEffect(() => {
    setLoading(true);
    pg.getBoardList(page_limit, curPage, 0, "", category)
      .then(res => {
        if (res) {
          setBoardItem(res);
        }

        let totalCount = res[0].total;
        let pageCnt;
        if (totalCount > 0) {
          let remainder = totalCount % page_limit;
          let result = parseInt(totalCount / page_limit);
          if (remainder > 0) {
            pageCnt = result + 1;
          } else {
            pageCnt = result;
          }
        } else {
          pageCnt = 1;
        }
        setTotalPages(pageCnt);
        setLoading(false);
      }).catch((err) => {
        //setError(err);
      }).finally(() => {
        setLoading(false);
      })
  }, [category]);

  const TransactionsTable = () => {

    const TableRow = (props) => {
      const { id, title, contents, author, date, views, category, commentCount } = props;
      const date_string = new Date(date).toLocaleDateString();

      return (
        <tr className="board-list" onClick={() => {
          history.push({
            pathname: "/board/detail",
            state: {
              id: id,
              title: title,
              contents: contents,
              author: author
            }
          })

          pg.updatePostViews(id);
        }}>
          <td>
            {id}
          </td>
          <td>
            {
              category === "0" ? "전체" :
                category === "1" ? "개선제안" :
                  category === "2" ? "문의" :
                    category === "3" ? "오류" :
                      category === "4" ? "기타" : "-"
            }
          </td>
          <td>
            <span className="fw-normal">
              {title} [{commentCount}]
            </span>
          </td>
          <td>
            <span className="fw-normal">
              {author === "undefined" ? "익명" : gadget.maskEmail(author)}
            </span>
          </td>
          <td>
            <span className="fw-normal">
              {
                (date_string === new Date().toLocaleDateString()) ?
                  new Date(date).toLocaleTimeString() : date_string
              }
            </span>
          </td>
          <td>
            <span className="fw-normal">
              {views}
            </span>
          </td>
        </tr>
      );
    };

    return (
      <Card border="light" className="table-wrapper table-responsive shadow-sm">
        <Card.Body className="">
          <Table hover className="user-table align-items-center mb-4">
            <thead className="thead-light">
              <tr>
                <th className="border-bottom" width="5%">#</th>
                <th className="border-bottom" width="5%">Category</th>
                <th className="border-bottom" width="60%">Title</th>
                <th className="border-bottom" width="10%">Author</th>
                <th className="border-bottom" width="10%">Date</th>
                <th className="border-bottom" width="10%">Views</th>
              </tr>
            </thead>
            <tbody>
              {boardItem.map(t => <TableRow key={`transaction-${t.date}`} {...t} />)}
            </tbody>
          </Table>

          <Card.Footer className="px-3 border-0 d-lg-flex align-items-center justify-content-between">
            <Nav>
              <Pagination className="mb-2 mb-lg-0">
                <Pagination.Prev onClick={() => onPreviousItem()} title="Prev">
                  <FontAwesomeIcon icon={faAngleDoubleLeft} />
                </Pagination.Prev>
                <PaginationItem />
                <Pagination.Next onClick={() => onNextItem()} title="Next">
                  <FontAwesomeIcon icon={faAngleDoubleRight} />
                </Pagination.Next>
              </Pagination>
            </Nav>

            {/*<small className="fw-bold">
              Showing <b>{boardItem.length}</b> out of <b>25</b> entries
              Showing <b>{boardItem.length}</b> entries
            </small>*/}

            <Button as={Link} className="mb-2 align-items-right" variant="primary" size="sm" to={Routes.NewPost.path}>
              <FontAwesomeIcon icon={faPlus} className="me-2" />New
            </Button>
          </Card.Footer>
        </Card.Body>
      </Card>
    );
  };

  return (
    //(auth.getUserType() >= 1) &&
    <>
      {!auth.isLogin() && <Redirect to="/login" />}
      <Container fluid className="main-content-container">
        {/* Page Header */}
        <Row noGutters className="page-header py-4">
          {/*<PageTitle sm="4" title="Approval" subtitle="Apps" className="text-sm-left" />*/}
          <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
            <Breadcrumb.Item><FontAwesomeIcon icon={faHome} /></Breadcrumb.Item>
            <Breadcrumb.Item active>Board</Breadcrumb.Item>
          </Breadcrumb>
          <h4>Board</h4>
          <p className="mb-0">
            { }
          </p>
        </Row>

        <Dropdown className="btn-toolbar mb-2" onSelect={(eventKey) => { setCategory(eventKey) }}>
          <Dropdown.Toggle as={Button} variant="outline-gray-900" size="sm" className="me-2">
            {category === "1" ? "개선제안" :
              category === "2" ? "문의" :
                category === "3" ? "오류" :
                  category === "4" ? "기타" : "전체"
            } <FontAwesomeIcon icon={faAngleDown} className="ms-2" />
          </Dropdown.Toggle>
          <Dropdown.Menu className="dashboard-dropdown dropdown-menu-left mt-2">
            <Dropdown.Item className="fw-bold" eventKey="0">
              전체
            </Dropdown.Item>
            <Dropdown.Item className="fw-bold" eventKey="1">
              개선제안
            </Dropdown.Item>
            <Dropdown.Item className="fw-bold" eventKey="2">
              문의
            </Dropdown.Item>
            <Dropdown.Item className="fw-bold" eventKey="3">
              오류
            </Dropdown.Item>
            <Dropdown.Item className="fw-bold" eventKey="4">
              기타
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>

        <Row>
          <Col md="2">
            <Form.Group className="mb-1">
              <Form.Select value={search.type} onChange={changeSearchType}>
                <option value={1}>Title</option>
                <option value={2}>Contents</option>
                <option value={3}>Author</option>
              </Form.Select>
            </Form.Group>
          </Col>
          <Col md="10">
            <InputGroup className="mb-1">
              <Form.Control type="text" placeholder="Search" value={search.text} onChange={inputSearchText} onKeyPress={onEnterKeySearch} />
              <InputGroup.Text>
                <FontAwesomeIcon icon={faSearch} style={{ cursor: "pointer" }} onClick={executeSearch} />
              </InputGroup.Text>
            </InputGroup>
          </Col>
        </Row>

        <TransactionsTable />
      </Container>
    </>
  );
}

export default Board;
