import React, { Component } from 'react';
import { Button, Container, Row, Card, Form, Modal } from '@themesberg/react-bootstrap';
import axios from 'axios';
import moment from 'moment';
import { PG_CONFIG } from "../../data/pgConfig";
import pg from "../../utils/pg";

import { Breadcrumb } from '@themesberg/react-bootstrap';
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import SurveyDetailsItem from './components/SurveyDetailsItem';
import { SURVEY_STATUS } from "./const/surveyConst";


export default class SurveyDetails extends Component {
  state = {
    survey_id: "",
    app_id: "",
    app_name: "",
    survey_header: {
      survey_title: "",
      survey_description: ""
    },
    last_survey_id: 0,
    survey_list: [
    ],
    start_date: 0,
    end_date: 0,
    survey_status: 0,
    user_number: "",
    request_id: "",
    modal: {
      title: "",
      message: "",
      open: false,
      survey_status: 0
    },
    comment: ""
  }

  loadItem = async () => {
    const id = this.props.location.state.id;

    try {
      const response = await axios.get(PG_CONFIG.apiGatewayUrl + '/survey/list/' + id, {
        timeout: 2000
      });
      const surveyData = response.data;

      if (surveyData) {
        const surveyContents = JSON.parse(surveyData.survey_contents);
        this.setState({
          survey_id: id,
          app_id: surveyData.app_id,
          app_name: surveyData.app_name,
          survey_header: surveyContents.survey_header,
          survey_list: surveyContents.survey_list,
          start_date: surveyData.start_date,
          end_date: surveyData.end_date,
          survey_status: surveyData.survey_status,
          user_number: surveyData.userNumber,
          request_id: surveyData.request_id
        });
      }
    } catch (error) {
      console.log(error);
    }
  }

  componentDidMount() {
    this.loadItem();
  }

  inputCommentHandler = (e) => {
    //console.log(e.target.value);
    this.setState({
      comment: e.target.value
    });
  }

  surveyApprovalButtonClick = async (event) => {
    let key = event.currentTarget.id;
    let modalTitle;
    let modalMessage;
    let survey_status;

    switch (key) {
      case 'approve':
        modalTitle = '확인';
        modalMessage = 'Survey 등록 요청이 승인되었습니다.'
        survey_status = SURVEY_STATUS.APPROVED;
        break;
      case 'reject':
        modalTitle = '확인';
        modalMessage = 'Survey 등록 요청을 반려되었습니다.';
        survey_status = SURVEY_STATUS.REJECTED;
        break;
      default:
        console.log('DetailView: Button click error');
        return;
    }

    try {
      await fetch(PG_CONFIG.apiGatewayUrl + "/survey/update/status", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          "id": this.state.survey_id,
          "survey_status": survey_status
        }),
      })

      this.setState({
        modal: {
          title: modalTitle,
          message: modalMessage,
          open: true,
          survey_status: survey_status
        }
      });

      if (survey_status === SURVEY_STATUS.APPROVED) {
        await pg.sendFCMPush(
          "설문 등록 알림",
          "설문이 등록되었습니다.",
          "survey",
          "https://lgplayground.lge.com/survey/response",
          this.state.app_id,
          this.state.survey_id
        );

        pg.sendEmail(
          this.state.request_id,
          "설문 등록 요청 결과",
          "설문 등록 요청이 승인되었습니다."
          + "\n\n"
          + "설문명 : "
          + this.state.survey_header.survey_title
          + "\n\n"
          + this.state.comment
        );
      } else if (survey_status === SURVEY_STATUS.REJECTED) {
        pg.sendEmail(
          this.state.request_id,
          "설문 등록 요청 결과",
          "설문 등록 요청이 반려되었습니다."
          + "\n\n"
          + "설문명 : "
          + this.state.survey_header.survey_title
          + "\n\n"
          + this.state.comment
        );
      }
    } catch (error) {
      console.log(error);
      this.setState({
        modal: {
          title: '승인/반려 오류',
          message: 'Survey 승인/반려 처리중 오류가 발생되었습니다.',
          open: true,
          survey_status: SURVEY_STATUS.REQUESTED
        }
      });
    }
  }

  webPageHandler = (surveyStatus) => {
    this.setState({
      modal: {
        open: false
      }
    })

    this.props.history.goBack();
  }

  render() {
    //console.log(this.state);
    return (
      <div>
        <Container fluid className="main-content-container" style={{ marginBottom: "15px" }}>
          {/* Page Header */}
          <Row noGutters className="page-header py-4">
            {/*<PageTitle sm="4" title="Survey Ceater" subtitle="Survey" className="text-sm-left" />*/}
            <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
              <Breadcrumb.Item><FontAwesomeIcon icon={faHome} /></Breadcrumb.Item>
              <Breadcrumb.Item>Survey</Breadcrumb.Item>
              <Breadcrumb.Item active>Details</Breadcrumb.Item>
            </Breadcrumb>
            <h4>Survey Details</h4>
            <p className="mb-0">
              { }
            </p>
          </Row>
          <Card border="light" className="shadow-sm">
            <Card.Header className="border-bottom border-light d-flex justify-content-between">
              <span className="d-block">
                <h4 className="text-primary align-top">{this.state.survey_header.survey_title}</h4>
                <h6 className="text-primary align-top">{this.state.survey_header.survey_description}</h6>
                <h6 className="text-primary align-top">설문기간 : <span style={{ fontWeight: "normal" }}>{moment(this.state.start_date).format("YYYY년MM월DD일")}~{moment(this.state.end_date).format("YYYY년MM월DD일")}</span></h6>
                <h6 className="text-primary align-top">설문대상 : <span style={{ fontWeight: "normal" }}>
                  {
                    this.state.app_name
                      ? ((this.state.app_id.split(':')[0] === 'lgpg') || (this.state.app_id.split(':')[0] === 'general'))
                        ? this.state.app_name
                        : this.state.app_name + ' (' + this.state.app_id.split(':')[0] + ')'
                      : "-"
                    /* this.state.app_name + ' (' + this.state.app_id.split(':')[0] + ')' */
                  }
                </span></h6>
                <h6 className="text-primary align-top">작성자 : <span style={{ fontWeight: "normal" }}>{this.state.request_id}</span></h6>
              </span>
            </Card.Header>
            <Form.Group>
              <ol style={{ paddingInlineStart: "0px" }}>
                {this.state.survey_list.map((surveyItem, index) => (
                  <li style={{ listStyleType: "none" }} key={index}>
                    <SurveyDetailsItem data={surveyItem} idx={index} />
                  </li>
                ))}
              </ol>
            </Form.Group>
            <Form.Group>
              <div style={{ padding: "0px 20px", marginBottom: "20px" }}>
                <hr />
                <br />
                <Form.Label style={{ fontWeight: "bold", color: "red" }}>Comment</Form.Label>
                <Form.Control as="textarea" rows="5" onChange={this.inputCommentHandler.bind(this)} />
              </div>
            </Form.Group>
            <Form.Group>
              <div className="mt-2 mb-4">
                <div className="text-right" style={{ marginRight: "20px" }}>
                  <Button style={{ width: "120px" }} variant="success" className="mb-2 mr-1 me-2" id="approve" name="approve"
                    onClick={this.surveyApprovalButtonClick}>Approve</Button>
                  <Button style={{ width: "120px" }} variant="danger" className="mb-2 mr-1 me-2" id="reject" name="reject"
                    onClick={this.surveyApprovalButtonClick}>Reject</Button>
                </div>
              </div>
            </Form.Group>
          </Card>

          <Modal as={Modal.Dialog} centered show={this.state.modal.open}>
            <Modal.Header>
              <Modal.Title className="h6">{this.state.modal.title}</Modal.Title>
              <Button variant="close" aria-label="Close" onClick={() => {
                this.webPageHandler(this.state.modal.survey_status);
              }} />
            </Modal.Header>
            <Modal.Body>
              <p>{this.state.modal.message}</p>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="link" className="text-gray ms-auto" onClick={() => {
                this.webPageHandler(this.state.modal.survey_status);
              }}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        </Container>
      </div>
    );
  }
}