import React, { Component } from 'react';
import { Button, Container, Card, Form, Row, Navbar } from '@themesberg/react-bootstrap';
import axios from 'axios';
import { PG_CONFIG } from "../../data/pgConfig";
import FadeLoader from "react-spinners/FadeLoader";

import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import SurveySummaryItemMobile from './components/SurveySummaryItemMobile';

export default class SurveySummaryMobile extends Component {
  state = {
    survey_header: {
      survey_title: "",
      survey_description: ""
    },
    app_id: "",
    app_name: "",
    period: "",
    request_id: "",
    summary_list: [],
    response_count:0,
    isLoading: false
  }

  loadItem = async () => {
    const id = this.props.location.state.id;
    this.setState({ isLoading: true });

    try {
      const response = await axios.get(PG_CONFIG.apiGatewayUrl + '/survey/response', {
        params: {
          survey_id: id
        },
        timeout: 2000
      });
      const surveyData = response.data;

      //응답 데이터 취합하기
      let questionList = Array.from(this.props.location.state.question_list);
      //console.log(questionList);

      for (let res of surveyData) {
        let surveyContents = JSON.parse(res.survey_contents);
        for (let ans of surveyContents.survey_list) {
          //console.log(ans);
          for (let sel of ans.selected) {
            //questionList[ans.id-1].select.push(sel);
            questionList[ans.id - 1].answer[sel - 1].amount = questionList[ans.id - 1].answer[sel - 1].amount + 1;
          }
          if (ans.comment !== "") {
            questionList[ans.id - 1].comment.push(ans.comment);
          }
        }
      }
      //console.log(questionList);

      //취합 데이터 set
      this.setState({
        survey_header: {
          survey_title: this.props.location.state.survey_header.survey_title,
          survey_description: this.props.location.state.survey_header.survey_description
        },
        app_id: this.props.location.state.app_id,
        app_name: this.props.location.state.app_name,
        period: this.props.location.state.period,
        request_id: this.props.location.state.request_id,
        summary_list: questionList,
        response_count: response.data.length,
        isLoading: false
      })
    } catch (error) {
      console.log(error);
      this.setState({ isLoading: false });
    }
  }

  componentDidMount() {
    this.loadItem();
  }

  render() {
    console.log(this.state);
    return (
      <>
        {this.state.isLoading
          ?
          <Container fluid className="main-content-container px-4 pb-4">
            <div className="loading" style={{ position: "fixed", top: "45%", left: "45%", transform: "translate(-50%, -50%)" }}>
              <FadeLoader height="15px" width="5px" color="#6b5ce7" radius="2px" margin="2px" />
            </div>
          </Container>
          :
          <div>
            <Navbar className="sticky-top" style={{ 'background-color': '#f5f8fb' }}>
              <main className="content">
                <Row noGutters className="page-header">
                  <h4 className="mb-0">
                    <FontAwesomeIcon icon={faArrowLeft} className="me-2" onClick={() => { this.props.history.goBack(); }} />
                    Survey Summary
                  </h4>
                </Row>
              </main>
            </Navbar>
            <Container fluid style={{ margin: "5px 0px", padding: "0px 5px" }}>
              <Card border="light" className="shadow-sm mb-1">
                <Card.Header className="border-bottom border-light justify-content-between">
                  <span className="d-block">
                    <h4 className="text-primary align-top">{this.state.survey_header.survey_title}</h4>
                    <h6 className="text-primary align-top"><span style={{ fontWeight: "normal" }}>{this.state.survey_header.survey_description}</span></h6>
                    <hr/>
                    <h6 className="text-primary align-top">설문기간 : <span style={{ fontWeight: "normal" }}>{this.state.period}</span></h6>
                    <h6 className="text-primary align-top">설문대상 : <span style={{ fontWeight: "normal" }}>
                      {
                        this.state.app_name
                        ? ((this.state.app_id.split(':')[0] === 'lgpg') || (this.state.app_id.split(':')[0] === 'general'))
                          ? this.state.app_name
                          : this.state.app_name + ' (' + this.state.app_id.split(':')[0] + ')'
                        : "-"
                        /* this.state.app_name + ' (' + this.state.app_id.split(':')[0] + ')' */
                      }
                    </span></h6>
                    <h6 className="text-primary align-top">작성자 : <span style={{ fontWeight: "normal" }}>{this.state.request_id}</span></h6>
                    <h6 className="text-primary align-top"><span style={{ color: "red" }}>총 응답수 : <span style={{ fontWeight: "normal" }}>{this.state.response_count}</span></span></h6>
                  </span>
                </Card.Header>
              </Card>
              <Form.Group>
                <ol style={{ paddingInlineStart: "0px" }}>
                  {this.state.summary_list.map((summaryItem, index) => (
                    <li style={{ listStyleType: "none", marginBottom: "5px" }} key={index}>
                      <SurveySummaryItemMobile data={summaryItem} idx={index} />
                    </li>
                  ))}
                </ol>
              </Form.Group>
              <Form.Group>
                <div className="mt-2 mb-4">
                  <div className="text-center">
                    <Button style={{ width: "120px" }} theme="primary" className="mb-2"
                      onClick={() => { this.props.history.goBack() }}>확인</Button>
                  </div>
                </div>
              </Form.Group>
            </Container>
          </div>}
      </>
    );
  }
}