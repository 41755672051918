import React, { useState, useEffect } from "react";
//import ReactQuill, { Quill } from 'react-quill';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Container, Badge, Col, Row, Button, Card, Form, FormCheck, Modal, Image, Navbar } from '@themesberg/react-bootstrap';
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router-dom";

import { ReactComponent as LikeIcon } from "../../assets/img/like.svg";
import { ReactComponent as UnlikeIcon } from "../../assets/img/unlike.svg";
//import { ReactComponent as ReplyIcon } from "../../assets/img/reply.svg";
import { ReactComponent as ShareIcon } from "../../assets/img/share.svg";
//import { ReactComponent as SaveIcon } from "../../assets/img/save.svg";
import { ReactComponent as ViewIcon } from "../../assets/img/view.svg";

import LGPGLogo from "../../assets/img/lgpg-logo.png"
import pg from "../../utils/pg";
import "../../css/post.css";
import gadget from "../../utils/gadget";

export default (props) => {
  const propUserId = props.location.state.userId;
  const propId = props.location.state.id;
  const propTitle = props.location.state.title;
  const propContents = props.location.state.contents;
  const propAuthor = props.location.state.author;
  const propDate = props.location.state.date;
  const propCategory = props.location.state.category;
  const propViews = props.location.state.views;
  const propLikes = props.location.state.likes;
  //const propCommentCount = props.location.state.commentCount;
  const propIconPath = props.location.state.iconPath;
  const propDeviceType = props.location.state.deviceType;

  const history = useHistory();
  const [post, setPost] = useState({ id: 0, title: "", contents: "", author: "", likes: 0 });
  const [comment, setComment] = useState("");
  const [commentList, setCommentList] = useState([]);
  const [password, setPassword] = useState("");
  const [show, setShow] = useState(false);
  const [disableRegister, setDisableRegister] = useState(true);
  const [reload, setReload] = useState(false);
  const [favorite, setFavorite] = useState(false);
  const [anonymous, setAnonymous] = useState(false);

  const handleUpdate = (event) => {
    console.log("handleSubmitPermission");
    event.preventDefault();
  }

  const handleAnonymousCheck = (event) => {
    setAnonymous(event.target.checked);
  }

  const toggle = () => {
    setShow(!show);
  }

  const toggleFavorite = async (event) => {
    console.log("toggleFavorite", favorite);
    event.preventDefault();

    if (propUserId) {
      if (favorite) {
        // dislike
        await pg.updatePostLikes(propId, propUserId, 0);
        setPost({ ...post, "likes": post.likes - 1 })
      } else {
        // like
        await pg.updatePostLikes(propId, propUserId, 1);
        setPost({ ...post, "likes": post.likes + 1 })
      }
      setFavorite(!favorite);
    }
  };

  const onClickShare = async (event) => {
    //console.log('onClickShare :: title=' + propTitle + ", contents=" + propContents);

    if (navigator.share) {
      navigator.share({
        title: propTitle,
        text: propContents,
        url: '',
      });
    } else {
      alert("공유하기가 지원되지 않는 환경 입니다.")
    }
  };

  const registerComment = async () => {
    setDisableRegister(true);
    if (anonymous) {
      await pg.registerComment(post.id, comment, undefined, new Date().getTime());
    } else {
      await pg.registerComment(post.id, comment, propUserId, new Date().getTime());
    }

    setReload(!reload);
    setComment("");
  }

  const deleteComment = async (id, commentId) => {
    await pg.deleteComment(id, commentId);
    setReload(!reload);
  }

  const handleChange = (event) => {
    if (event.target.value) {
      setDisableRegister(false);
    } else {
      setDisableRegister(true);
    }

    setComment(event.target.value);
  };

  const handlePassword = (event) => {
    setPassword(event.target.value);
  };

  useEffect(() => {
    if (propUserId) {
      pg.getLikeStatus(propId, propUserId)
        .then(res => {
          if (res) {
            //console.log("like:", res);
            if (res[0].id) {
              setFavorite(true);
            }
          }
        }).catch((err) => {
          //setError(err);
        })
    }
  }, []);

  useEffect(() => {
    setPost({ id: propId, title: propTitle, contents: propContents, author: propAuthor, likes: propLikes });

    pg.getComment(propId)
      .then(res => {
        if (res) {
          setCommentList(res);
        }
      }).catch((err) => {
        //setError(err);
      })
  }, [reload]);

  const onDelete = async () => {
    let hashed = "";
    if (post.author === 'undefined') {
      const cryptojs = require('crypto-js');
      const secret = process.env.REACT_APP_BOARD_CRYPTO_SECRET;
      let encrypt = cryptojs.HmacSHA256(password, secret);
      hashed = cryptojs.enc.Hex.stringify(encrypt);
    }

    await pg.deletePost(post, hashed);
    //alert(post.title + "" + post.contents + post.author + new Date().getTime());

    history.goBack();
  }

  const CommentRow = () => {
    return (commentList.map(t =>
      <div key={t.id}>
        <Row>
          <Col className="post__commenter mb-1">
            <div>{t.commenter === "undefined" ? "익명" : gadget.maskEmail(t.commenter)}</div>
          </Col>
          <Col className="text-right">
            <Row>
              <div>
                {(t.commenter === propUserId) &&
                  <Button variant="close" size="xs" onClick={() => { deleteComment(post.id, t.id) }} />
                }
              </div>
            </Row>
          </Col>
        </Row>
        <Row className="post__commentBoader">
          <Col className="mb-1">{t.comment}</Col>
          <Row>
            <Col className="post__commentDate mb-1">
              <Row>
                <div>{new Date(t.date).toLocaleDateString()} {new Date(t.date).toLocaleTimeString()}</div>
              </Row>
            </Col>
          </Row>

        </Row>
      </div>
    )
    )
  }

  return (
    <div>
      <Navbar className="sticky-top" style={{ 'background-color': '#f5f8fb' }}>
        <Container fluid className="position-relative justify-content-between px-3">
          <Col>
            <h4 className="mb-0">
              <FontAwesomeIcon icon={faArrowLeft} className="me-2" onClick={() => { history.goBack() }} />
              Community Board
            </h4>
          </Col>
        </Container>
      </Navbar>
      <main className="content mt-2 mx-1">
        <div>
          <Row>
            <Col xs={12} xl={12}>
              <Card border="light" className="bg-white shadow-sm mb-2">
                <Card.Header className="p-0 mb-2">
                  <div className="pt-2 ps-2 mb-2">
                    {
                      propCategory === "1" ? <Badge pill bg={"success"} className="badge-md">개선제안</Badge> :
                        propCategory === "2" ? <Badge pill bg={"info"} className="badge-md">문의</Badge> :
                          propCategory === "3" ? <Badge pill bg={"danger"} className="badge-md">오류</Badge> :
                            propCategory === "4" ? <Badge pill bg={"dark"} className="badge-md">기타</Badge> : "-"
                    }
                  </div>
                  <div className="post__header pt-0 pb-0">
                    <h5>
                      {propIconPath ? <Image src={propIconPath} className="image image-xs me-1" /> : <Image src={LGPGLogo} className="image image-xs me-1" />}
                      {post.title}
                    </h5>
                  </div>
                  <div align="right" className="me-3" style={{ color: "#adadad" }}>
                    {propDeviceType === 1 ? "Android" : propDeviceType === 2 ? "iOS" : "Web"}
                  </div>
                </Card.Header>
                <Card.Body>
                  <Form className="label-text" onSubmit={handleUpdate}>
                    <Row>
                      <Col md={12} className="mb-3">
                        <div className="body__content" style={{ minHeight: "150px" }} dangerouslySetInnerHTML={{ __html: post.contents }} />
                      </Col>
                    </Row>
                  </Form>

                  <div align="right" className="post__text">
                    {propAuthor === "undefined" ? "익명" : gadget.maskEmail(propAuthor)}
                  </div>
                  <div align="right" className="mb-2" style={{ fontSize: "12px" }}>{new Date(propDate).toLocaleDateString()} {new Date(propDate).toLocaleTimeString()}</div>

                  <Row>
                    <Col className="iconview__border" align="center">
                      <button className="post__buttonIcon">
                        <ViewIcon fill="#262626" height="1.5rem" width="1.5rem" />
                      </button>
                      {propViews}
                    </Col>

                    <Col className="iconlike__border">
                      <button
                        className="post__buttonIcon"
                        value={favorite}
                        onClick={toggleFavorite}
                      >
                        {favorite ? (
                          <LikeIcon fill="#ed4956" height="1.5rem" width="1.5rem" />
                        ) : (
                          <UnlikeIcon fill="#262626" height="1.5rem" width="1.5rem" />
                        )}
                      </button>
                      {post.likes}
                    </Col>

                    {/*<Col className="iconcomment">
                    <button className="post__buttonIcon">
                      <ReplyIcon fill="#262626" height="1.8rem" width="1.8rem" />
                    </button>
                    {propCommentCount}
                      </Col>*/}

                    <Col className="iconshare">
                      <button className="post__buttonIcon"
                        onClick={onClickShare}>
                        <ShareIcon fill="#262626" height="1.5rem" width="1.5rem" />
                      </button>
                    </Col>

                    {/*<span className="iconsave">
                    <button className="post__buttonIcon">
                      <SaveIcon fill="#262626" height="1.8rem" width="1.8rem" />
                    </button>
                </span>*/}
                  </Row>
                </Card.Body>
                <Card.Footer>
                  <CommentRow />

                  <Form.Group as={Row}>
                    <Col xs={9}>
                      <Form.Control autoComplete="off"
                        id="input-comment"
                        name="comment"
                        placeholder="Comment"
                        onChange={handleChange}
                        value={comment} />
                    </Col>
                    <Col xs={3}>
                      <Button disabled={disableRegister} style={{ width: "70px" }} variant="primary" onClick={registerComment}>등록</Button>
                    </Col>
                    <Form.Check type="checkbox" className="mt-2">
                      <FormCheck.Input id="anonymous-id" className="me-2" checked={anonymous} onChange={handleAnonymousCheck} />
                      <FormCheck.Label htmlFor="anonymous-id" className="mb-0">익명</FormCheck.Label>
                    </Form.Check>
                  </Form.Group>
                </Card.Footer>
              </Card>
              {
                ((post.author === propUserId) || post.author === "undefined") &&
                <Row className="text-right">
                  <Col className="mb-3">
                    {((post.author === propUserId)) &&
                    <Button className="mb-2 mr-1 me-2" style={{ width: "70px" }} variant="primary"
                      onClick={() => {
                        history.push({
                          pathname: "/board/mobile/update",
                          state: {
                            id: post.id,
                            title: post.title,
                            contents: post.contents
                          }
                        })
                      }}>수정</Button>}
                    <Button className="mb-2 mr-1" style={{ width: "70px" }} variant="danger" onClick={toggle}>삭제</Button>
                  </Col>
                </Row>
              }
            </Col>
            <Modal centered show={show} onHide={toggle}>
              <Modal.Header><strong>삭제하기</strong></Modal.Header>
              <Modal.Body>
                <div className="mb-3">이 게시글을 삭제하시겠습니까?</div>
                {(propAuthor === "undefined") &&
                  <Form.Group>
                    <Form.Control required type="password" id="input-password" name="password" placeholder="Password"
                      onChange={handlePassword} />
                  </Form.Group>
                }
              </Modal.Body>
              <Modal.Footer>
                <Button className="mb-2 mr-1" variant="danger" onClick={onDelete}>
                  삭제
                </Button>
                <Button className="mb-2 mr-1" onClick={toggle}>
                  취소
                </Button>
              </Modal.Footer>
            </Modal>
          </Row>
        </div>
      </main>
    </div>
  );
};
