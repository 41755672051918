
import React from "react";
import { Redirect } from "react-router-dom";

import auth from "../../utils/auth";


export default () => {
  const iframePart = () => {
    return {
      __html: '<iframe width="100%" height="100%" src="https://datastudio.google.com/embed/reporting/cadeb561-25c5-4ee1-be24-c23290194693/page/1xZU" frameborder="0" style="border:0" allowfullscreen></iframe>',

    };
  };

  return (
    <>
      {!auth.isLogin() && <Redirect to="/login" />}
      {auth.getUserType() < 2 && <Redirect to="/examples/404" />}
      <div
        style={{ margin: 'auto', position: 'relative', width: '100%', height: '800px', overflow: 'hidden' }}
        dangerouslySetInnerHTML={iframePart()}
      />
    </>
  );
};
