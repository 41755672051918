import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import { faUserCircle } from "@fortawesome/free-regular-svg-icons";
import GoogleLogo from "../assets/img/google-logo.png"
import FacebookLogo from "../assets/img/facebook-logo.png"
import AmazonLogo from "../assets/img/amazon-logo.png"
import NaverLogo from "../assets/img/naver-logo.png"

import { Badge, Nav, Navbar, Dropdown, Container, Image } from '@themesberg/react-bootstrap';

import auth from "../utils/auth";


export default (props) => {
  return (
    <Navbar variant="dark" expanded className="ps-0 pe-2 pb-0">
      <Container fluid className="px-0">
        <div className="d-flex justify-content-end w-100">
          {/*<div className="d-flex align-items-center">
            <Form className="navbar-search">
              <Form.Group id="topbarSearch">
                <InputGroup className="input-group-merge search-bar">
                  <InputGroup.Text><FontAwesomeIcon icon={faSearch} /></InputGroup.Text>
                  <Form.Control type="text" placeholder="Search" />
                </InputGroup>
              </Form.Group>
            </Form>
          </div>*/}
          <Nav className="align-items-center">
            {/*<Dropdown as={Nav.Item} onToggle={markNotificationsAsRead} >
              <Dropdown.Toggle as={Nav.Link} className="text-dark icon-notifications me-lg-3">
                <span className="icon icon-sm">
                  <FontAwesomeIcon icon={faBell} className="bell-shake" />
                  {areNotificationsRead ? null : <span className="icon-badge rounded-circle unread-notifications" />}
                </span>
              </Dropdown.Toggle>
              <Dropdown.Menu className="dashboard-dropdown notifications-dropdown dropdown-menu-lg dropdown-menu-center mt-2 py-0">
                <ListGroup className="list-group-flush">
                  <Nav.Link href="#" className="text-center text-primary fw-bold border-bottom border-light py-3">
                    Notifications
                  </Nav.Link>

                  {notifications.map(n => <Notification key={`notification-${n.id}`} {...n} />)}

                  <Dropdown.Item className="text-center text-primary fw-bold py-3">
                    View all
                  </Dropdown.Item>
                </ListGroup>
              </Dropdown.Menu>
            </Dropdown>*/}

            <Dropdown as={Nav.Item}>
              <Dropdown.Toggle as={Nav.Link} className="pt-1 px-0">
                <div className="media d-flex align-items-center">
                  {auth.getUserInfo().idType === "GGL" ?
                    <Image src={GoogleLogo} height={25} width={25} />
                    : auth.getUserInfo().idType === "FBK" ?
                      <Image src={FacebookLogo} height={25} width={25} />
                      : auth.getUserInfo().idType === "AMZ" ?
                        <Image src={AmazonLogo} height={25} width={25} />
                        : auth.getUserInfo().idType === "NAV" ?
                          <Image src={NaverLogo} height={25} width={25} />
                          : <></>}
                  <div className="media-body ms-2 text-dark align-items-center d-none d-lg-block">
                    {/*<span className="mb-0 font-small fw-bold"> {auth.getUserInfo().idType}</span>*/}
                    <span className="mb-0 font-small fw-bold"> {auth.getUserID()} </span>
                    <Badge pill bg={"info"} className="badge-md notification-count ms-2"> {auth.getUserTypeString()} </Badge>
                  </div>
                </div>
              </Dropdown.Toggle>
              <Dropdown.Menu className="user-dropdown dropdown-menu-right mt-2">
                {/*
                <Dropdown.Item className="fw-bold">
                  <FontAwesomeIcon icon={faUserCircle} className="me-2" /> My Profile
                </Dropdown.Item>
                <Dropdown.Item className="fw-bold">
                  <FontAwesomeIcon icon={faCog} className="me-2" /> Settings
                </Dropdown.Item>
                <Dropdown.Item className="fw-bold">
                  <FontAwesomeIcon icon={faEnvelopeOpen} className="me-2" /> Messages
                </Dropdown.Item>
                */}
                <Dropdown.Item href="/user/profile" className="fw-bold">
                  <FontAwesomeIcon icon={faUserCircle} className="me-2" /> Profile
                </Dropdown.Item>
                <Dropdown.Divider />

                <Dropdown.Item href="/logout" className="fw-bold">
                  <FontAwesomeIcon icon={faSignOutAlt} className="text-danger me-2" /> Logout
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Nav>
        </div>
      </Container>
    </Navbar>
  );
};
