import React, { useState, useEffect, useRef } from "react";
import { Redirect, useHistory } from "react-router-dom";

import { Badge, Button, Card, Col, Container, Dropdown, Nav, Pagination, Row } from '@themesberg/react-bootstrap';
import { Breadcrumb } from '@themesberg/react-bootstrap';
import { faHome, faAngleDown, faAngleDoubleLeft, faAngleDoubleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import axios from 'axios';
import auth from "../../utils/auth";
import { APP_REQUEST, APP_LIST } from './const/AppConst';

import approval from "../../css/approval.module.css";


function Approval(props) {
  const history = useHistory();
  const [getAppList, setAppList] = useState();
  const [filter, setFilter] = useState("all");

  const totalApplistRow = useRef(1);
  const approvalAppListPagination = useRef({ maxRows: 0, firstPageIndex: 0, currentPage: 1 })

  const goDetailView = (id) => {
    history.push({
      pathname: "/app/details",
      state: {
        id: id,
        entryPoint: "Request"
      }
    });
  };

  const approvalListGet = async (offset) => {
    let appStatus = [];
    let userType = auth.getUserType();
    let requestID;

    switch(userType) {
      case APP_REQUEST.ADMIN_USER:
        requestID = 'request_id='
        appStatus = `app_status=${APP_REQUEST.REQUEST}&app_status=${APP_REQUEST.ADMIN_REJECT}`;
        break;
      case APP_REQUEST.DEVELOPER_USER:
        requestID = 'request_id=' + auth.getUserID();
        appStatus = `app_status=${APP_REQUEST.REQUEST}&app_status=${APP_REQUEST.ADMIN_REJECT}`;
        break;
      default:
        break;
    }

    try {
      const response = await axios.get(APP_REQUEST.AWS_APP_REQUEST_QUERY + `?${requestID}&${appStatus}&offset=${offset}`);

      const AppsData = response.data.appList;
      approvalAppListPagination.current.maxRows = response.data.totalRow;
      totalApplistRow.current = response.data.totalRow;
      let appInfo = [];

      if (true) {
        const AWSPath = response.data.S3Path;
        let index = 0;

        for (let app of AppsData) {
          let files = [];
          files['icon_file'] = AWSPath[index]['icon_file'];

          appInfo.push({ appData: app, AWSPath: files });
          index++;
        }
      } else {
        const CloudFrontAddress = response.data.CloudFront;

        for (let app of AppsData) {
          let files = [];

          if (!app['icon_file'].match(/^http:\/\//) && !app['icon_file'].match(/^https:\/\//)) {
            files['icon_file'] = CloudFrontAddress + '/' + encodeURIComponent(app.icon_file);
          }
          appInfo.push({ appData: app, AWSPath: files });
        }
      }

      setAppList(appInfo);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      approvalListGet(0);
    };
    fetchData();
  }, []);

  const AppCardWidget = (app) => {
    const { id, app_name, app_version, request_id, app_status, app_type } = app.appData;
    const { icon_file } = app.AWSPath;

    let bgColor = (app_status === APP_REQUEST.REQUEST) ? "white" : "#ffe5e5";
    let targetBadge = (app_type === APP_LIST.ANDROID_TYPE)
        ? {bg: "secondary", target: "Android"} : (app_type === APP_LIST.IOS_TYPE)
          ? {bg: "warning", target: "iOS"} : {bg: "success", target: "Web"}

    return (
      <Card className={approval.box} style={{ cursor: 'pointer', backgroundColor: bgColor }} onClick={() => { goDetailView(id)} } >
        <Card.Img src={icon_file} />
        <Card.Body className="p-2">
          <Card.Title className={approval.title}>{app_name}</Card.Title>
          <Row>
            <Col>
              <p small="true" className={approval.version}>{app_version}</p>
            </Col>

            <Col>
              <p small="true" className={approval.target}>
                <Badge pill bg={targetBadge.bg} className="badge-md">{targetBadge.target}</Badge>
              </p>
            </Col>
          </Row>

          <hr style={{margin: "10px 5px 0px 5px"}}/>

          <Row>
            <span small="true" className={approval.author}>{request_id.split('@')[0]}</span>
            <p className={approval.status}>
              { (app_status === APP_REQUEST.REQUEST)
                ? <span small="true">Request</span>
                : <span small="true" style={{ color: "red" }}>Admin reject</span> }
            </p>
          </Row>
        </Card.Body>
      </Card>
    );
  };

  const onPreviousItem = async () => {
    if (approvalAppListPagination.current.firstPageIndex > 0) {
      approvalAppListPagination.current.currentPage = approvalAppListPagination.current.firstPageIndex;
      approvalAppListPagination.current.firstPageIndex -= 10;

      approvalListGet((approvalAppListPagination.current.currentPage - 1) * APP_REQUEST.PAGE_CARD_VIEW);
    }
  };

  const onNextItem = async (page) => {
    if (((approvalAppListPagination.current.firstPageIndex + 10) * APP_REQUEST.PAGE_CARD_VIEW) < approvalAppListPagination.current.maxRows) {
      approvalAppListPagination.current.firstPageIndex += 10;
      approvalAppListPagination.current.currentPage = approvalAppListPagination.current.firstPageIndex + 1;

      approvalListGet(( approvalAppListPagination.current.firstPageIndex) * APP_REQUEST.PAGE_CARD_VIEW);
    }
  };

  const onPageItem = async (page) => {
    approvalAppListPagination.current.currentPage = page;

    approvalListGet((page - 1) * APP_REQUEST.PAGE_CARD_VIEW);
  };

  const PageNumbering = (props) => {
    let index = props.index;

    if ((approvalAppListPagination.current.maxRows + APP_REQUEST.PAGE_CARD_VIEW) <= (index * APP_REQUEST.PAGE_CARD_VIEW)) {
      return <></>;
    }

    return <Pagination.Item className="mx-1" active={approvalAppListPagination.current.currentPage === index} onClick={() => onPageItem(index)}>{index}</Pagination.Item>
  }

  const AppCardPagination = (props) => {
    if (approvalAppListPagination.current.maxRows <= APP_REQUEST.PAGE_CARD_VIEW) {
      return <></>
    }

    let html = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((index) => <PageNumbering index={index + approvalAppListPagination.current.firstPageIndex} />)

    return (
      <>
        <Pagination.Prev onClick={() => onPreviousItem()}>
          <FontAwesomeIcon icon={faAngleDoubleLeft} />
        </Pagination.Prev>
        {html}
        <Pagination.Next onClick={() => onNextItem()}>
          <FontAwesomeIcon icon={faAngleDoubleRight} />
        </Pagination.Next>
      </>
    );
  }

  return (
    <>
      {!auth.isLogin() && <Redirect to="/login" />}
      {(auth.getUserType() !== 2 && auth.getUserType() !== 4) && <Redirect to="/examples/404" />}
      <Container fluid className="main-content-container">
        <Row noGutters className="page-header py-4">
          <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
            <Breadcrumb.Item><FontAwesomeIcon icon={faHome} /></Breadcrumb.Item>
            <Breadcrumb.Item>Apps</Breadcrumb.Item>
            <Breadcrumb.Item active>{(auth.getUserType() === APP_REQUEST.DEVELOPER_USER) ? "Register" : "Approve"}</Breadcrumb.Item>
          </Breadcrumb>
          <h4>
            { filter === "all"
              ? (auth.getUserType() === APP_REQUEST.DEVELOPER_USER) ? "Request (All apps)" : "Approval (All apps)"
              : filter === "request"
                ? (auth.getUserType() === APP_REQUEST.DEVELOPER_USER) ? "Request" : "Approval (Request)"
                : (auth.getUserType() === APP_REQUEST.DEVELOPER_USER) ? "Request (Reject)" : "Approval (Reject)"
            }
          </h4>
        </Row>

        <Dropdown className="btn-toolbar" onSelect={(eventKey) => { setFilter(eventKey) }}>
          <Dropdown.Toggle as={Button} variant="outline-gray-900" size="sm" className="me-2">
            Filter <FontAwesomeIcon icon={faAngleDown} className="ms-2" />
          </Dropdown.Toggle>

          <Dropdown.Menu className="dashboard-dropdown dropdown-menu-left mt-2">
            <Dropdown.Item className="fw-bold" eventKey="all">All Apps</Dropdown.Item>
            <Dropdown.Item className="fw-bold" eventKey="request">Request</Dropdown.Item>
            <Dropdown.Item className="fw-bold" eventKey="reject">Reject</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>

        {getAppList !== undefined &&
        <>
          <Row className="boxs">
            { getAppList.map(({...app}, index) => {
              switch(filter) {
                case "request":
                  if (app.appData.app_status !== APP_REQUEST.REQUEST) return <></>
                  break;
                case "reject":
                  if (app.appData.app_status !== APP_REQUEST.ADMIN_REJECT) return <></>
                  break;
                default:
                  break;
              }

              return <AppCardWidget key={index} {...app}/>
            }) }
          </Row>

          <Card.Footer className="px-3 border-0 d-lg-flex align-items-center justify-content-between">
            <Nav>
              <Pagination className="mb-2 mb-lg-0">
                <AppCardPagination/>
              </Pagination>
            </Nav>
          </Card.Footer>
        </>
}
      </Container>
    </>
  )
}

export default Approval;
