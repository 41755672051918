import qs from 'qs'
import axios from 'axios'
import CryptoJS from 'crypto-js'
import { EMP_CONFIG } from '../data/empConfig.js'

const getDateTime = (oauth2url) => {
  const headers = {
    'Accept': 'application/json',
    'x-lge-appKey': EMP_CONFIG.client_id,
    'Content-Type': 'application/x-www-form-urlencoded'
  }

  const options = {
    url: oauth2url + '/oauth/1.0/datetime',
    method: 'GET',
    headers: headers
  }
  return axios(options)
    .then((res) => (res.data.date))
}

const getSignature = (path, datetime, params) => {
  const requestParams = qs.stringify(params)
  const stringToSign = path + '?' + requestParams + '\n' + datetime

  return CryptoJS.enc.Base64.stringify(CryptoJS.HmacSHA1(stringToSign, EMP_CONFIG.client_secret))
}

const requestToken = (oauth2url, code, datetime) => {
  const params = {
    code,
    grant_type: 'authorization_code',
    redirect_uri: EMP_CONFIG.redirect_uri
  }

  const headers = {
    'Accept': 'application/json',
    'x-lge-oauth-signature': getSignature(EMP_CONFIG.tokenUrl, datetime, params),
    'x-lge-oauth-date': datetime,
    'x-lge-appKey': EMP_CONFIG.client_id,
    'Content-Type': 'application/x-www-form-urlencoded'
  }

  const options = {
    url: oauth2url + EMP_CONFIG.tokenUrl,
    method: 'POST',
    headers: headers,
    params: params,
    data: {}
  }

  return axios(options)
    .then((res) => (res.data))
}

const getAccessToken = (oauth2url = EMP_CONFIG.oauthUrl, code) => {
  console.log('getAccessToken')
  return getDateTime(oauth2url)
    .then((datetime) => requestToken(oauth2url, code, datetime))
}

const getUserProfile = (token, oauthUrl = EMP_CONFIG.oauthUrl) => {
  return getDateTime(oauthUrl).then((datetime) => requestUserProfile(token, oauthUrl, datetime)).catch((reason) => console.log('88888' + reason))
}

const requestUserProfile = (token, oauthUrl, datetime) => {
  const headers = {
    'Accept': 'application/json',
    'x-lge-oauth-signature': getSignature('/oauth/1.0/users/profile', datetime, {}),
    'x-lge-appKey': EMP_CONFIG.client_id,
    'x-lge-oauth-date': datetime,
    'Authorization': 'Bearer ' + token,
    'Content-Type': 'application/x-www-form-urlencoded'
  }

  const options = {
    url: oauthUrl + '/oauth/1.0/users/profile',
    method: 'GET',
    headers: headers
  }

  return axios(options).then((res) => (res.data))
}

export default {
  getAccessToken,
  getUserProfile
}
