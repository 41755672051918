import React from "react";
import { Redirect } from "react-router-dom";
import auth from '../utils/auth'

const Logout = () => {
  auth.logout();

  return <Redirect to="/login" />
}

export default Logout;
