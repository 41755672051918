const gadget = {
  isEmpty(value) {
    if (value === "" || value === null || value === undefined
      || (value !== null && typeof value === "object" && !Object.keys(value).length)) {
      return true;
    } else {
      return false
    }
  },
  isNumberOrPoint(value) {
    const regExp = /[^-\.0-9]/;
    if (regExp.test(value)) {
      return true;
    } else {
      return false;
    }
  },
  maskEmail(email) {
    var parts = email.split("@");
    var username = parts[0];
    var domain = parts[1];
    var maskedUsername = username.slice(0, 3) + Array(username.length - 3).fill("*").join("");
    return maskedUsername + "@" + domain;
  }
};

export default gadget;