import React, { Component } from "react";
import { Button, Modal } from '@themesberg/react-bootstrap';
import Axios from "axios";

import { PG_CONFIG } from "../../../data/pgConfig";

export default class SurveyResponseBtn extends Component {
  constructor(props) {
    super(props);
  }
  state = {
    modal: {
      title: "",
      message: "",
      open: false,
      return_code: 0
    }
  }

  onResponseSurvey = async () => {
    var surveybody = {
      survey_id: this.props.surveyId,
      app_id: this.props.appId,
      app_name: this.props.appName,
      survey_header: this.props.surveyHeader,
      survey_list: this.props.responseList,
      start_date: this.props.surveyStartDate,
      end_date: this.props.surveyEndDate,
      survey_status: 4,
      user_number: this.props.userNumber
    };

    console.log(surveybody);

    let requiredErrMessage = "";
    let requiredErr = false;
    let returnCode = 0; //0:remain, 1:return

    let currentDate = new Date().getTime();
    if (currentDate < this.props.surveyStartDate) {
      requiredErrMessage = "설문 응답 기간이 아닙니다."
      requiredErr = true;
      returnCode = 1;
    } else if (currentDate > this.props.surveyEndDate) {
      requiredErrMessage = "설문 응답 기간이 종료되었습니다."
      requiredErr = true;
      returnCode = 1;
    } else {
      let i = 0;
      while (i < surveybody.survey_list.length) {
        if (surveybody.survey_list[i].required === true) {
          if (surveybody.survey_list[i].type === "singleinput") {
            if (surveybody.survey_list[i].comment === null || surveybody.survey_list[i].comment === "") {
              requiredErrMessage = surveybody.survey_list[i].id + "번 항목은 필수 답변 사항입니다."
              requiredErr = true;
              returnCode = 0;
              break;
            }
          } else {
            if (surveybody.survey_list[i].selected.length < 1) {
              requiredErrMessage = surveybody.survey_list[i].id + "번 항목은 필수 답변 사항입니다."
              requiredErr = true;
              returnCode = 0;
              break;
            }
          }
        }

        i = i + 1;
      }
    }

    //Todo: 이미 설문 응답 완료 여부 확인.
    try {
      const response = await Axios.get(PG_CONFIG.apiGatewayUrl + '/survey/response', {
        params: {
          user_number: this.props.userNumber
        },
        timeout: 2000
      });
      console.log(response.data);
      const resSurveyItems = response.data;
      let j = 0;
      while (j < resSurveyItems.length) {
        if (resSurveyItems[j].survey_id === Number(this.props.surveyId)) {
          requiredErrMessage = "이미 응답을 완료하였습니다."
          requiredErr = true;
          returnCode = 1;
          break;
        }
        j = j + 1;
      }
    } catch (error) {
      console.log(error);
      requiredErrMessage = "응답 여부가 확인되지 않았습니다."
      requiredErr = true;
      returnCode = 1;
    }

    //Survey 응답 or Error Popup
    if (requiredErr === true) {
      this.setState({
        modal: {
          title: surveybody.survey_header.survey_title,
          message: requiredErrMessage,
          open: true,
          return_code: returnCode
        }
      });
    } else {
      Axios.post(PG_CONFIG.apiGatewayUrl + "/survey/response", surveybody)
        .then(response => {
          //alert('success!');
          window.location.replace("/survey/complete?result=success");
        })
        .catch(error => {
          //alert('failed!');
          window.location.replace("/survey/complete?result=fail");
        });
    }
  }

  webPageHandler = (returnCode) => {
    this.setState({
      modal: {
        open: false
      }
    })
    if (returnCode === 1) {
      window.location.replace("/survey/complete?result=fail");
    }
  }

  render() {
    var btnTags = [];
    if (this.props.responseList.length <= 0) {
      btnTags.push(<div></div>);
    } else {
      btnTags.push(<Button size="md" style={{ width: "120px", margin: "0px 10px" }} theme="primary" className="mb-2 mr-1" onClick={this.onResponseSurvey.bind(this)}>설문 제출</Button>);
    }

    return (
      <div>
        {btnTags}
        <Modal as={Modal.Dialog} centered show={this.state.modal.open}>
          <Modal.Header>
            <Modal.Title className="h6">{this.state.modal.title}</Modal.Title>
            <Button variant="close" aria-label="Close" onClick={() => {
              this.webPageHandler(this.state.modal.return_code);
            }} />
          </Modal.Header>
          <Modal.Body>
            <p>{this.state.modal.message}</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="link" className="text-gray ms-auto" onClick={() => {
              this.webPageHandler(this.state.modal.return_code);
            }}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}
