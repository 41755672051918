import React, { Component } from 'react';
import { Card, Container, Form, Modal, Button } from '@themesberg/react-bootstrap';
import FadeLoader from "react-spinners/FadeLoader";
import moment from 'moment';
import axios from 'axios';

import SurveyResponseItem from './components/SurveyResponseItem';
import SurveyResponseBtn from './components/SurveyResponseBtn';

import { PG_CONFIG } from "../../data/pgConfig";

export default class SurveyResponse extends Component {
  state = {
    survey_id: "",
    app_id: "",
    app_name: "",
    survey_header: {
      survey_title: "",
      survey_description: ""
    },
    last_survey_id: 0,
    survey_list: [
    ],
    start_date: 0,
    end_date: 0,
    survey_status: 0,
    user_number: "",
    isLoading: false,
    modal: {
      title: "",
      message: "",
      open: false
    }
  }
  /*
  state = {
      survey_id:"cb277809-7e86-4b77-b582-fdb0e1f71402",
      app_id:"612975cb-53fd-4956-8f7a-294bda516c42",
      app_name:"Promota",
      survey_header: {
          survey_title: "프로그래밍 언어 사용 실태 조사",
          survey_description: "응답자의 조직 내에서 사용하고 있는 프로그래밍 언어의 사용 실태를 확인하고자 합니다."
      },
      last_survey_id : 4,
      survey_list: [
          {
              id : 1,
              type : "singleinput",
              question : "응답자가 속한 조직의 이름은?",
              required : true,
              is_description: true,
              description : "응답자가 속한 조직의 한글/영문 이름을 기재해 주세요.",
              candidate_cnt : 0,
              candidate : [],
              selected : [],
              is_comment : true,
              comment : ""
          },
          {
              id : 2,
              type : "checkbox",
              question : "응답자가 사용하고 있는 프로그래밍 언어는?",
              required : false,
              is_description: true,
              description : "조직내에서 응답자가 사용하고 있는 프로그래밍 언어를 모두 선택하세요.",
              candidate_cnt : 5,
              candidate : [
                  {
                      value : 1,
                      text : "Java"
                  },
                  {
                      value : 2,
                      text : "Javascript" //"가 정말로 줄바꿈시에 잘 정리되어야 하는데.. 이게 잘 안된다... 테스트가 정말 정말 잘 되었으면 좋겠는데 React가 너무 어렵다."
                  },
                  {
                      value : 3,
                      text : "Python"
                  },
                  {
                      value : 4,
                      text : "C언어"
                  },
                  {
                      value : 5,
                      text : "기타"
                  }
              ],
              selected : [],
              is_comment : true,
              comment : ""
          },
          {
              id : 3,
              type : "radiogroup",
              question : "응답자의 조직내에서 응답자와 동일한 프로그래밍 언어를 사용하는 팀원은 몇 명인가요?",
              required : true,
              is_description: false,
              description : "",
              candidate_cnt : 3,
              candidate : [
                  {
                      value : 1,
                      text : "10명 이상"
                  },
                  {
                      value : 2,
                      text : "5 ~ 9명" //"정도의 인원으로 정말 팀에서 하는 모든 프로그램 개발을 문제 없이 일정 안에 잘 진행할 수 있을까? 걱정이 많다."
                  },
                  {
                      value : 3,
                      text : "5명 미만"
                  }
              ],
              selected : [],
              is_comment : false,
              comment : ""
          },
          {
              id : 4,
              type : "radiogroup",
              question : "기타 프로그래밍 언어의 팀내 사용 비율은 어느정도인가요?",
              required : true,
              is_description: false,
              description : "",
              candidate_cnt : 4,
              candidate : [
                  {
                      value : 1,
                      text : "80% 이상"
                  },
                  {
                      value : 2,
                      text : "50% 이상 80% 미만"
                  },
                  {
                      value : 3,
                      text : "10% 이상 50% 미만"
                  },
                  {
                      value : 4,
                      text : "10% 미만"
                  }
              ],
              selected : [],
              is_comment : true,
              comment : ""
          },
          {
              id : 5,
              type : "rating",
              question : "응답자가 사용하고 있는 프로그래밍 언어의 적절성을 평가해 주세요.",
              required : true,
              is_description: true,
              description : "1~5점으로 평가하시기 바랍니다.",
              candidate_cnt : 5,
              candidate : [
                  {
                      value : 1,
                      text : "1"
                  },
                  {
                      value : 2,
                      text : "2"
                  },
                  {
                      value : 3,
                      text : "3"
                  },
                  {
                      value : 4,
                      text : "4"
                  },
                  {
                      value : 5,
                      text : "5"
                  }
              ],
              selected : [],
              is_comment : true,
              comment : ""
          }
      ],
      start_date:1638284400000,
      end_date:1638629999000
  }
  */

  loadItem = async () => {
    const search = this.props.location.search;
    const surveyId = new URLSearchParams(search).get("survey_id");
    const userNumber = new URLSearchParams(search).get("user_number");
    this.setState({ isLoading: true });

    //Survey 정보 Loading
    try {
      const response = await axios.get(PG_CONFIG.apiGatewayUrl + '/survey/list/' + surveyId, {
        timeout: 2000
      });
      const resSurveyData = response.data;
      console.log(resSurveyData);
      //this.setState({ isLoading: false });

      if (resSurveyData) {
        const surveyContents = JSON.parse(resSurveyData.survey_contents);
        this.setState({
          survey_id: surveyId,
          app_id: surveyContents.app_id,
          app_name: surveyContents.app_name,
          survey_header: surveyContents.survey_header,
          survey_list: surveyContents.survey_list,
          start_date: surveyContents.start_date,
          end_date: surveyContents.end_date,
          survey_status: surveyContents.survey_status,
          user_number: userNumber,
          isLoading: false
        });
      }
    } catch (error) {
      console.log(error);
      //this.setState({ isLoading: false });
    }

    //이미 설문 응답 여부 확인.
    try {
      const responseAlready = await axios.get(PG_CONFIG.apiGatewayUrl + '/survey/response', {
        params: {
          survey_id: surveyId,
          user_number: userNumber
        },
        timeout: 2000
      });
      console.log(responseAlready.data);
      if (responseAlready.data.length > 0) {
        this.setState({
          modal: {
            title: "설문 응답 오류",
            message: "이미 응답이 완료된 설문입니다.",
            open: true
          }
        });
      }
      this.setState({ isLoading: false });
    } catch (error) {
      console.log(error);
      this.setState({ isLoading: false });
    }
  }

  componentDidMount() {
    this.loadItem();
  }

  onUpdate = (id, updateItemType, updateItem) => {
    //console.log(id);
    //console.log(updateItem);

    var newLists = Array.from(this.state.survey_list);
    var i = 0;
    while (i < newLists.length) {
      if (id === newLists[i].id) {
        if (updateItemType === "comment") {
          newLists[i].comment = updateItem;
        } else if (updateItemType === "selected") {
          newLists[i].selected = updateItem.selected;
        }
        break;
      }
      i = i + 1;
    }

    this.setState({
      survey_list: newLists
    });
  }

  responseModalHandler = () => {
    this.setState({
      modal: {
        open: false
      }
    })
    window.location.replace("/survey/complete?result=fail");
  }

  render() {
    //console.log(this.state);

    var responseTags = [];
    if (this.state.survey_id === "") {
      responseTags.push(<div style={{ textAlign: "center" }}>설문이 없습니다.</div>); //ToDo: 설문이 없는 경우 페이지 추가.
    } else {
      responseTags.push(
        <Form>
          <Form.Group className="mb-3">
            <Card.Header className="border-bottom border-light justify-content-between">
              <span className="d-block">
                <h4 className="text-primary align-top">{this.state.survey_header.survey_title}</h4>
                <h6 className="text-primary align-top"><span style={{ fontWeight: "normal" }}>{this.state.survey_header.survey_description}</span></h6>
                <hr/>
                <h6 className="text-primary align-top">설문기간 : <span style={{ fontWeight: "normal" }}>{moment(this.state.start_date).format("YYYY.MM.DD")}~{moment(this.state.end_date).format("YYYY.MM.DD")}</span></h6>
                <h6 className="text-primary align-top">설문대상 : <span style={{ fontWeight: "normal" }}>
                  {
                    this.state.app_name
                    ? ((this.state.app_id.split(':')[0] === 'lgpg') || (this.state.app_id.split(':')[0] === 'general'))
                      ? this.state.app_name
                      : this.state.app_name + ' (' + this.state.app_id.split(':')[0] + ')'
                    : "-"
                    /* this.state.app_name + ' (' + this.state.app_id.split(':')[0] + ')' */
                  }
                </span></h6>
              </span>
            </Card.Header>
          </Form.Group>
          <Form.Group>
            <ol style={{ paddingInlineStart: "0px" }}>
              {this.state.survey_list.map((surveyItem, index) => (
                <li style={{ listStyleType: "none" }} key={index}>
                  <SurveyResponseItem data={surveyItem} onUpdate={this.onUpdate} idx={index} />
                </li>
              ))}
            </ol>
          </Form.Group>
          <Form.Group>
            <div className="mt-5 mb-1">
              <div className="text-center">
                <SurveyResponseBtn
                  surveyId={this.state.survey_id}
                  appId={this.state.app_id}
                  appName={this.state.app_name}
                  surveyHeader={this.state.survey_header}
                  surveyStartDate={this.state.start_date}
                  surveyEndDate={this.state.end_date}
                  responseList={this.state.survey_list}
                  userNumber={this.state.user_number} />
              </div>
            </div>
          </Form.Group>
        </Form>
      );
    }

    return (
      <>
        {this.state.isLoading ?
          <Container fluid className="main-content-container px-4 pb-4">
            <div className="loading" style={{ position: "fixed", top: "45%", left: "45%", transform: "translate(-50%, -50%)" }}>
              <FadeLoader height="15px" width="5px" color="#6b5ce7" radius="2px" margin="2px" />
            </div>
          </Container> :
          <div>
            {responseTags}
            <Modal as={Modal.Dialog} centered show={this.state.modal.open}>
              <Modal.Header>
                <Modal.Title className="h6">{this.state.modal.title}</Modal.Title>
                <Button variant="close" aria-label="Close" onClick={() => {
                  this.responseModalHandler();
                }} />
              </Modal.Header>
              <Modal.Body>
                <p>{this.state.modal.message}</p>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="link" className="text-gray ms-auto" onClick={() => {
                  this.responseModalHandler();
                }}>
                  Close
                </Button>
              </Modal.Footer>
            </Modal>
          </div>}
      </>
    );
  }
}
