export const Routes = {
  // pages
  Presentation: { path: "/presentation" },
  DashboardOverview: { path: "/dashboard/overview" },
  Transactions: { path: "/transactions" },
  Settings: { path: "/settings" },
  Upgrade: { path: "/upgrade" },
  BootstrapTables: { path: "/tables/bootstrap-tables" },
  Billing: { path: "/examples/billing" },
  Invoice: { path: "/examples/invoice" },
  Signin: { path: "/examples/sign-in" },
  Signup: { path: "/examples/sign-up" },
  ForgotPassword: { path: "/examples/forgot-password" },
  ResetPassword: { path: "/examples/reset-password" },
  Lock: { path: "/examples/lock" },
  NotFound: { path: "/examples/404" },
  ServerError: { path: "/examples/500" },

  // docs
  DocsOverview: { path: "/documentation/overview" },
  DocsDownload: { path: "/documentation/download" },
  DocsQuickStart: { path: "/documentation/quick-start" },
  DocsLicense: { path: "/documentation/license" },
  DocsFolderStructure: { path: "/documentation/folder-structure" },
  DocsBuild: { path: "/documentation/build-tools" },
  DocsChangelog: { path: "/documentation/changelog" },

  // components
  Accordions: { path: "/components/accordions" },
  Alerts: { path: "/components/alerts" },
  Badges: { path: "/components/badges" },
  Widgets: { path: "/widgets" },
  Breadcrumbs: { path: "/components/breadcrumbs" },
  Buttons: { path: "/components/buttons" },
  Forms: { path: "/components/forms" },
  Modals: { path: "/components/modals" },
  Navs: { path: "/components/navs" },
  Navbars: { path: "/components/navbars" },
  Pagination: { path: "/components/pagination" },
  Popovers: { path: "/components/popovers" },
  Progress: { path: "/components/progress" },
  Tables: { path: "/components/tables" },
  Tabs: { path: "/components/tabs" },
  Tooltips: { path: "/components/tooltips" },
  Toasts: { path: "/components/toasts" },
  WidgetsComponent: { path: "/components/widgets" },

  // LG Playground
  Main: { path: "/main" },
  MainMobile: { path: "/mobile" },
  Login: { path: "/login" },
  Logout: { path: "/logout" },
  LoginRedirect: { path: "/redirect" },
  Analytics: { path: "/dashboard/analytics" },
  Crashlytics: { path: "/dashboard/crashlytics" },
  AppList: { path: "/app/list" },
  AppDetails: { path: "/app/details" },
  AppRegister: { path: "/app/register" },
  AppApproval: { path: "/app/approval" },
  AppTestPage: { path: "/app/testpage" },
  UserProfile: { path: "/user/profile" },
  UserPermission: { path: "/user/permission" },
  SurveyRequest: { path: "/survey/request" },
  SurveyCreator: { path: "/survey/creator" },
  SurveyApproval: { path: "/survey/approval" },
  SurveyDetails: { path: "/survey/details" },
  SurveyResult: { path: "/survey/result" },
  SurveyRequestMobile: { path: "/survey/m/request" },
  SurveyCreatorMobile: { path: "/survey/m/creator" },
  SurveySummary: { path: "/survey/summary" },
  SurveyResultMobile: { path: "/survey/m/result" },
  SurveySummaryMobile: { path: "/survey/m/summary" },
  SurveyResponse: { path: "/survey/response" },
  SurveyComplete: { path: "/survey/complete" },
  SurveyMyListMobile: { path: "/survey/m/mylist"},
  GroupLGE: { path: "/group/lge" },
  GroupFUT: { path: "/group/fut" },
  GroupDEV: { path: "/group/dev" },
  Notice: { path: "/notice/send" },
  Board: { path: "/board/board" },
  NewPost: { path: "/board/new" },
  UpdatePost: { path: "/board/update" },
  PostDetail: { path: "/board/detail" },
  BoardMobile: { path: "/board/mobile" },
  NewPostMobile: { path: "/board/mobile/new" },
  PostDetailMobile: { path: "/board/mobile/detail" },
  UpdatePostMobile: { path: "/board/mobile/update" },
  LGPGUploader: { path: "/uploader"},
  InstallGuide: { path: "/guide" },
};