import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import axios from 'axios';

import { Button, Container, Row, Card, Table, Modal, Form, InputGroup } from '@themesberg/react-bootstrap';
import { Breadcrumb } from '@themesberg/react-bootstrap';
import { faHome, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import auth from "../../utils/auth";
import gadget from "../../utils/gadget";
import { PG_CONFIG } from "../../data/pgConfig";
import { GROUP_TYPE } from "./const/groupConst";

import "./styles/GroupStyle.css";

const GroupFUTs = () => {
  const [groupFUTList, setGroupFUTList] = useState([]);
  const [groupNameTxt, setGroupNameTxt] = useState("");
  const [groupCertNo, setGroupCertNo] = useState("");
  const [groupDesc, setGroupDesc] = useState("");

  const [pageReload, setPageReload] = useState(false);
  /* Alert Modal */
  const [showAlertModal, setShowAlertModal] = useState(false);
  const [alertModalTitle, setAlertModalTitle] = useState("");
  const [alertModalMessage, setAlertModalMessage] = useState("");
  /* Add Modal */
  const [showAddModal, setShowAddModal] = useState(false);
  const [addGroupName, setAddGroupName] = useState("");
  /* Delete Modal */
  const [deleteGroupId, setDeleteGroupId] = useState(null);
  const [deleteGroupName, setDeleteGroupName] = useState("");
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  useEffect(() => {
    try {
      axios.get(PG_CONFIG.apiGatewayUrl + '/group/list', {
        params : {
          group_type:GROUP_TYPE.FUT
        }
      })
      .then(response => {
        setGroupFUTList(response.data);
      })
      .catch(error => {
        //그룹 리스트 가져오기 에러
        console.log("그룹 목록 가져오기 에러");
      })
    } catch (error) {
      //그룹 리스트 가져오기 에러
      console.log("그룹 목록 가져오기 에러");
    }
  }, [pageReload]);

  {/* 그룹 추가 */}
  const isDuplicate = () => {
    /* 현재 가져온 그룹리스트에서 중복 check */
    for (let idx in groupFUTList) {
      if (groupFUTList[idx].group_name.replace(/(\s*)/g, '') === groupNameTxt.replace(/(\s*)/g, '')) {
        return true;
      }
    }
    return false;
  }

  const isExistCertNo = () => {
    /* 현재 가져온 그룹 인증번호에서 중복 check */
    for (let idx in groupFUTList) {
      if (groupFUTList[idx].cert_no === groupCertNo) {
        return true;
      }
    }
    return false;
  }

  const openAddModal = () => {
    if (gadget.isEmpty(groupNameTxt)) {
      setShowAlertModal(true);
      setAlertModalTitle("그룹 추가 오류");
      setAlertModalMessage("입력된 그룹명이 없습니다.");
    } else if (isDuplicate()) {
      setShowAlertModal(true);
      setAlertModalTitle("그룹 추가 오류");
      setAlertModalMessage("해당 그룹이 이미 존재합니다.");
    } else if (gadget.isEmpty(groupCertNo)) {
      setShowAlertModal(true);
      setAlertModalTitle("인증 번호 오류");
      setAlertModalMessage("FUT 그룹에 사용될 인증번호를 생성하세요.");
    } else if (isExistCertNo()) {
      setShowAlertModal(true);
      setAlertModalTitle("인증 번호 오류");
      setAlertModalMessage("동일한 인증번호를 가진 FUT 그룹이 있습니다.");
    } else {
      setAddGroupName(groupNameTxt);
      setShowAddModal(true);
    }
  }

  const toggleAddModal = () => {
    setShowAddModal(false);
  }

  const onAddModalConfirmHandler = () => {
    setShowAddModal(false);
    var groupbody = {
      group_name: groupNameTxt,
      group_type: GROUP_TYPE.FUT, /* 0: All, 1: LGE Group, 2: FUT Group */
      cert_no : groupCertNo,
      group_desc : groupDesc,
    };

    try {
      axios.post(PG_CONFIG.apiGatewayUrl + "/group/register", groupbody)
      .then(response => {
        setShowAlertModal(true);
        setAlertModalTitle("그룹 추가 완료");
        setAlertModalMessage("그룹 추가가 완료되었습니다.");
        setGroupNameTxt("");
        setAddGroupName("");
        setGroupCertNo("");
        setGroupDesc("");
        setPageReload(!pageReload);
      })
      .catch(error => {
        setShowAlertModal(true);
        setAlertModalTitle("그룹 추가 오류");
        setAlertModalMessage("그룹 추가중 오류가 발생하였습니다.");
      });
    } catch (error) {
      setShowAlertModal(true);
      setAlertModalTitle("그룹 추가 오류");
      setAlertModalMessage("그룹 추가중 오류가 발생하였습니다.");
    }
  }

  {/* 그룹 삭제 */}
  const openDeleteModal = (id, name, type) => {
    setDeleteGroupId(id);
    setDeleteGroupName(name);
    setShowDeleteModal(true);
  }

  const toggleDeleteModal = () => {
    setShowDeleteModal(false);
  }

  const onDeleteModalConfirmHandler = () => {
    setShowDeleteModal(false);
    var groupbody = {
      group_id: deleteGroupId,
      group_name: deleteGroupName,
      group_type: GROUP_TYPE.FUT, /* 0: All, 1: LGE Group, 2: FUT Group */
    };

    try {
      axios.post(PG_CONFIG.apiGatewayUrl + "/group/remove", groupbody)
      .then(response => {
        setShowAlertModal(true);
        setAlertModalTitle("그룹 삭제 완료");
        setAlertModalMessage("그룹 삭제가 완료되었습니다.");
        setDeleteGroupId(null);
        setDeleteGroupName("");
        setPageReload(!pageReload);
      })
      .catch(error => {
        setShowAlertModal(true);
        setAlertModalTitle("그룹 삭제 오류");
        setAlertModalMessage("그룹 삭제중 오류가 발생하였습니다.");
      });
    } catch (error) {
      setShowAlertModal(true);
      setAlertModalTitle("그룹 삭제 오류");
      setAlertModalMessage("그룹 삭제중 오류가 발생하였습니다.");
    }
  }

  const toggleAlertModal = () => {
    setShowAlertModal(false);
  }

  const onInputGroupTextHandler = (event) => {
    //console.log(event.target.id);
    if (event.target.id === "g_name") {
      setGroupNameTxt(event.target.value);
    } /*else if (event.target.id === "g_cert") {
      setGroupCertNo(event.target.value);
    }*/ else if (event.target.id === "g_desc") {
      setGroupDesc(event.target.value);
    } else {
      // none
    }
  }

  /* Generate Group Certification Number */
  const randomInt = (min, max) => {
    return Math.round(min + (Math.random() * (max-min)));
  }

  const genCertNo = () => {
    const CERT_MAX_LEN = 8;
    let presetChar = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    let genNo = "";
    for (let i = 0; i < CERT_MAX_LEN; i++) {
      genNo += presetChar[randomInt(0, presetChar.length - 1)];
    }
    setGroupCertNo(genNo);
  }

  const checkDuplCertNo = () => {
    if (gadget.isEmpty(groupCertNo)) {
      setShowAlertModal(true);
      setAlertModalTitle("인증 번호 오류");
      setAlertModalMessage("FUT 그룹에 사용될 인증번호를 생성하세요.");
    } else if (isExistCertNo()) {
      setShowAlertModal(true);
      setAlertModalTitle("인증 번호 오류");
      setAlertModalMessage("동일한 인증번호를 가진 FUT 그룹이 있습니다.");
    } else {
      setShowAlertModal(true);
      setAlertModalTitle("인증 번호 확인");
      setAlertModalMessage("해당 FUT 그룹에 사용할 수 있는 인증 번호 입니다.");
    }
  }

  const FutGroupTable = () => {
    const TableRow = (request) => {
      return (
        <tr className="FUTGroup">
          <td className="border-0">
            {request.id}
          </td>
          <td className="border-0">
            {request.group_name}
          </td>
          <td className="border-0">
            {request.cert_no}
          </td>
          <td className="border-0">
            {request.description}
          </td>
          <td className="border-0 float-end">
            {/*
            <FontAwesomeIcon icon={faTrash} style={{cursor:"hand", marginRight:"10px", marginLeft:"10px"}} title="삭제" onClick={(id, name, type) => {openDeleteModal(request.id, request.group_name, request.group_type)}} />
            */}
          </td>
        </tr>
      )
    }

    return (
      <Card border="light" className="shadow-sm">
        <Card.Body className="pb-0">
          <Table responsive className="table-centered table-nowrap rounded mb-0">
            <thead className="thead-light">
              <tr>
                <th className="border-0">No</th>
                <th className="border-0">Group Name</th>
                <th className="border-0">Certification No.</th>
                <th className="border-0">Description</th>
                <th className="border-0"></th>
              </tr>
            </thead>
            {
              groupFUTList.length <= 0 ?
                <tbody>
                  <tr className="LGEGroup">
                    <td colSpan="5" className="border-0">
                      <div style={{ textAlign: "center" }}>등록된 FUT 그룹이 없습니다.</div>
                    </td>
                  </tr>
                </tbody>
              :
                <tbody>
                  {groupFUTList.map(r => <TableRow key={`request-${r.id}`} {...r} />)}
                </tbody>
            }
          </Table>
        </Card.Body>
      </Card>
    )
  };

  return (
    <>
      {!auth.isLogin() && <Redirect to="/login" />}
      {auth.getUserType() !== 4 && <Redirect to="/examples/404" />}
      <Container fluid className="main-content-container">
        {/* Page Header */}
        <Row noGutters className="page-header py-4">
          {/*<PageTitle sm="4" title="Approval" subtitle="Apps" className="text-sm-left" />*/}
          <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
            <Breadcrumb.Item><FontAwesomeIcon icon={faHome} /></Breadcrumb.Item>
            <Breadcrumb.Item>Group</Breadcrumb.Item>
            <Breadcrumb.Item active>FUT Group</Breadcrumb.Item>
          </Breadcrumb>
          <h4>FUT Group</h4>
          <p className="mb-0">
            { }
          </p>
        </Row>
        {/* Group Add */}
        <Card className="mb-3">
          <Card.Body>
            <Form.Group className="mb-3">
              {/*<Form.Label>FUT Group 이름 (최대 50자)</Form.Label>*/}
              <InputGroup>
                <InputGroup.Text>그룹 이름</InputGroup.Text>
                <Form.Control
                  id="g_name"
                  className="input-box"
                  type="text"
                  placeholder="그룹 이름을 입력하세요 (최대 50자)"
                  maxLength={50}
                  value={groupNameTxt}
                  onChange={(event) => {onInputGroupTextHandler(event)}}
                />
              </InputGroup>
            </Form.Group>
            <Form.Group className="mb-3">
              {/*<Form.Label>FUT Group 인증 번호 (최대 8자, 영어/숫자)</Form.Label>*/}
              <InputGroup>
                <InputGroup.Text>인증 번호</InputGroup.Text>
                <Form.Control
                  id="g_cert"
                  className="input-box"
                  type="text"
                  maxLength={8}
                  placeholder="'인증번호 생성' 버튼을 눌러 인증번호를 생성하세요."
                  value={groupCertNo}
                  style={{marginRight:"1px"}}
                />
                <Button variant="outline-primary" onClick={genCertNo}>인증번호 생성</Button>
                <Button variant="outline-primary" onClick={checkDuplCertNo}>중복 확인</Button>
              </InputGroup>
            </Form.Group>
            <Form.Group className="mb-3">
              {/*<Form.Label>FUT Group 설명 (최대 200자)</Form.Label>*/}
              <InputGroup>
                <InputGroup.Text>그룹 설명</InputGroup.Text>
                <Form.Control
                  id="g_desc"
                  className="input-box"
                  type="text"
                  placeholder="그룹에 대한 설명을 입력하세요 (최대 200자)"
                  maxLength={200}
                  value={groupDesc}
                  onChange={(event) => {onInputGroupTextHandler(event)}}
                />
              </InputGroup>
            </Form.Group>
            <Form.Group className="text-right">
              <Button variant="primary" onClick={openAddModal}>Add Group</Button>
            </Form.Group>
          </Card.Body>
        </Card>
        {/* Group List */}
        <FutGroupTable />
        {/* Add Modal */}
        <Modal as={Modal.Dialog} centered show={showAddModal} onHide={toggleAddModal}>
          <Modal.Header>
            <Modal.Title className="h6">Add FUT Group</Modal.Title>
            <Button variant="close" aria-label="Close" onClick={toggleAddModal} />
          </Modal.Header>
          <Modal.Body>
            <p>FUT 그룹 목록에 '{addGroupName}' 그룹을 추가하시겠습니까?</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={onAddModalConfirmHandler}>확인</Button>
            <Button variant="link" className="text-gray ms-auto" onClick={toggleAddModal}>닫기</Button>
          </Modal.Footer>
        </Modal>
        {/* Alert Modal */}
        <Modal as={Modal.Dialog} centered show={showAlertModal} onHide={toggleAlertModal}>
          <Modal.Header>
            <Modal.Title className="h6">{alertModalTitle}</Modal.Title>
            <Button variant="close" aria-label="Close" onClick={toggleAlertModal} />
          </Modal.Header>
          <Modal.Body>
            <p>{alertModalMessage}</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="link" className="text-gray ms-auto" onClick={toggleAlertModal}>닫기</Button>
          </Modal.Footer>
        </Modal>
        {/* Delete Modal */}
        <Modal as={Modal.Dialog} centered show={showDeleteModal} onHide={toggleDeleteModal}>
          <Modal.Header>
            <Modal.Title className="h6">Delete FUT Group</Modal.Title>
            <Button variant="close" aria-label="Close" onClick={toggleDeleteModal} />
          </Modal.Header>
          <Modal.Body>
            <p>FUT 그룹 목록에서 '{deleteGroupName}' 그룹을 삭제하시겠습니까?</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="danger" onClick={onDeleteModalConfirmHandler}>삭제</Button>
            <Button variant="link" className="text-gray ms-auto" onClick={toggleDeleteModal}>닫기</Button>
          </Modal.Footer>
        </Modal>
      </Container>
    </>
  );
};

export default GroupFUTs;