import React, { Component } from "react";
import { Button, Row, Col, Form } from '@themesberg/react-bootstrap';
import "../styles/SurveySelectForm.css";

export default class SurveySelecForm extends Component {
  render() {
    return (
      <div className="ComponentsToolBox">
        <Col xs={12} className="edit">
          <Form>
            <Form.Group>
              <Row className="mt-4 pt-2" style={{ border: "1px solid lightgray", borderRadius: "10px", marginLeft: "0px", marginRight: "0px" }}>
                <Col xl={3}>
                  {/*<Form.Label htmlFor="tool_box"><h4 style={{fontWeight:"bold"}}>TOOLBOX</h4></Form.Label>*/}
                  <Button disabled variant="none" size="lg" className="mb-2 me-2">TOOLBOX</Button>
                </Col>
                <Col xl={2}>
                  <div className="ComponentItem" style={{ textAlign: "center" }} role="button" onClick={() => { this.props.onCreate({ type: "singleinput" }) }}>
                    <span>Single Input</span>
                  </div>
                </Col>
                <Col xl={2}>
                  <div className="ComponentItem" style={{ textAlign: "center" }} role="button" onClick={() => { this.props.onCreate({ type: "checkbox" }) }}>
                    <span>Checkbox</span>
                  </div>
                </Col>
                <Col xl={2}>
                  <div className="ComponentItem" style={{ textAlign: "center" }} role="button" onClick={() => { this.props.onCreate({ type: "radiogroup" }) }}>
                    <span>Radiogroup</span>
                  </div>
                </Col>
                <Col xl={2}>
                  <div className="ComponentItem" style={{ textAlign: "center" }} role="button" onClick={() => { this.props.onCreate({ type: "rating" }) }}>
                    <span>Rating</span>
                  </div>
                </Col>
              </Row>
            </Form.Group>
          </Form>
        </Col>
      </div>
    );
  }
}