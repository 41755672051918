import React, { Component } from "react";
import { Card, Form, Table } from '@themesberg/react-bootstrap';
import Chart from "react-apexcharts";

export default class SurveySummaryItemMobile extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    var summaryItem = this.props.data;
    //console.log(summaryItem);

    var questionTag = [];
    questionTag.push(
      <Form.Label style={{
        fontWeight: "bold",
        fontSize: "16px",
        margin: "0px",
        width: "100%",
        display: "inline-block",
        boxSizing: "border-box"
      }}>{summaryItem.id}.&nbsp;{summaryItem.question}
      </Form.Label>
    );

    var chartTag = [];
    switch (summaryItem.type) {
      case 'singleinput':
        //console.log(summaryItem.type);
        break;
      case 'checkbox':
        //console.log(summaryItem.type);
        var series = [{
          name: "",
          data: []
        }];
        var options = {
          plotOptions: {
            bar: {
              borderRadius: 4,
              horizontal: true,
              distributed: true,
              barHeight: "70%"
            }
          },
          dataLabels: {
            enabled: false
          },
          xaxis: {
            categories: []
          }
        };

        for (let ans of summaryItem.answer) {
          series[0].data.push(ans.amount);
          options.xaxis.categories.push(ans.text);
        }
        chartTag.push(
          <div className="mixed-chart" style={{ padding: "1px 2px" }}>
            <Chart
              options={options}
              series={series}
              type="bar"
              height={(100 + (20 * summaryItem.answer.length))}
              width='100%'
            />
          </div>
        )
        break;
      case 'radiogroup':
        //console.log(summaryItem.type);
        var series = [{
          name: "",
          data: []
        }];
        var options = {
          plotOptions: {
            bar: {
              borderRadius: 4,
              horizontal: true,
              distributed: true,
              barHeight: "70%"
            }
          },
          dataLabels: {
            enabled: false
          },
          xaxis: {
            categories: []
          }
        };

        for (let ans of summaryItem.answer) {
          series[0].data.push(ans.amount);
          options.xaxis.categories.push(ans.text);
        }

        chartTag.push(
          <div className="mixed-chart" style={{ padding: "1px 2px" }}>
            <Chart
              options={options}
              series={series}
              type="bar"
              height={(100 + (20 * summaryItem.answer.length))}
              width='100%'
            />
          </div>
        )
        break;
      case 'rating':
        //console.log(summaryItem.type);
        var series = [{
          name: "",
          data: []
        }];
        var options = {
          plotOptions: {
            bar: {
              borderRadius: 4,
              horizontal: true,
              colors: {
                backgroundBarColors: ['#EEEEEE', '#EEEEEE', '#EEEEEE', '#EEEEEE', '#EEEEEE', '#EEEEEE', '#EEEEEE', '#EEEEEE', '#EEEEEE', '#EEEEEE'],
                backgroundBarRadius: 4
              },
              dataLabels: {
                position: 'bottom'
              },
              barHeight: "70%"
            }
          },
          dataLabels: {
            enabled: true
          },
          xaxis: {
            categories: [],
            labels: {
              show: false
            },
            tickAmount: 1
          }
        };

        var total = 0;
        var cnt = 0;
        for (let i = summaryItem.answer.length - 1; i >= 0; i--) {
          series[0].data.push(summaryItem.answer[i].amount);
          options.xaxis.categories.push('★'.repeat(summaryItem.answer[i].value));

          total = total + (summaryItem.answer[i].amount * (i + 1));
          cnt = cnt + summaryItem.answer[i].amount;
        }
        options.xaxis.max = cnt;

        chartTag.push(
          <table style={{ width: "100%" }}>
            <tbody>
              <tr>
                <td style={{ textAlign: "center", verticalAlign: "bottom" }}>
                  <span style={{ fontWeight: "bold", fontSize: "6em" }}>{((cnt === 0) ? 0.0 : total / cnt).toFixed(1)}</span>
                  <span style={{ color: "gray", fontWeight: "bold", fontSize: "20px" }}>&nbsp;&nbsp;(최고 {summaryItem.answer.length}점)</span>
                </td>
              </tr>
              <tr>
                <td style={{ width: "100%", padding: "2px" }}>
                  <div className="mixed-chart">
                    <Chart
                      options={options}
                      series={series}
                      type="bar"
                      height={(50 + (20 * summaryItem.answer.length))}
                      width='100%'
                    />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        )
        break;
      default:
    }

    var summaryTags = [];
    if (chartTag.length > 0) {
      summaryTags.push(
        <div style={{ border: "solid 1px lightgray", borderRadius: "5px", marginBottom: "5px" }}>
          {chartTag}
        </div>
      );
    }
    if (summaryItem.comment.length >= 1) {
      summaryTags.push(
        <div style={{ border: "solid 1px lightgray", borderRadius: "5px", marginBottom: "5px" }}>
          <ul>
            {/*commentTag*/}
            {summaryItem.comment.map((comment, index) => (
              <li style={{ margin: "0px", padding: "0px 8px" }} key={index}>
                {comment}
              </li>
            ))}
          </ul>
        </div>
      );
    }

    return (
      <div>
        <Card>
          <Table className="table-centered table-nowrap rounded mb-0" style={{ tableLayout: "fixed" }}>
            <tbody>
              <tr>
                <th className="border-0" style={{
                  padding: "12px 12px",
                  display: "block",
                  boxSizing: "border-box",
                  whiteSpace: "normal"
                }}>{questionTag}</th>
              </tr>
              <tr>
                <td style={{
                  padding: "12px 12px",
                  whiteSpace: "normal"
                }}>
                  {summaryTags}
                </td>
              </tr>
            </tbody>
          </Table>
        </Card>
      </div>
    );
  }
}