import { faAndroid, faApple } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Col, Container, Navbar, Row } from '@themesberg/react-bootstrap';
import React from "react";
import { HashLink } from 'react-router-hash-link';

export default () => {

  return (
    <div>
      <Navbar variant="dark" expand="lg" bg="white" className="navbar-transparent navbar-theme-primary sticky-top" style={{ height: "8vh" }}>
        <Container fluid className="position-relative justify-content-between px-3">
          <Navbar.Brand as={HashLink} to="#" className="me-lg-3 d-flex align-items-center">
            {/*<Image src={ReactHero} />*/}
            <span className="ms-2 brand-text d-md-inline">LG PLAYGROUND</span>
          </Navbar.Brand>

          <div className="d-flex align-items-center">
            {/*<Navbar.Collapse id="navbar-default-primary">
              <Nav className="navbar-nav-hover align-items-lg-center">
                <Nav.Link as={HashLink} to="#features">Features</Nav.Link>
                <Nav.Link as={HashLink} to="#pages">Pages</Nav.Link>
                <Nav.Link as={HashLink} to="#folder" className="d-sm-none d-xl-inline">Folder Structure</Nav.Link>
                <Nav.Link as={HashLink} to="#getting-started">Getting Started</Nav.Link>
                <Nav.Link as={HashLink} to="#download">Upgrade to Pro</Nav.Link>
              </Nav>
              </Navbar.Collapse>
            <Button as={Link} to="/app/list" variant="outline-primary" className="ms-3"><FontAwesomeIcon icon={faSignInAlt} className="d-none d-sm-inline me-1" /> Login</Button>*/}
          </div>
        </Container>
      </Navbar>
      <div className="intro" style={{ height: "92vh" }}>
        <div className="overlay" style={{ height: "92vh" }}>
          <section className="section-header overflow-hidden text-white" id="home">
            <Row>
              <Col xs={12} className="text-center mt-5 ps-5">
                <h1 style={{ fontSize: "2.5rem" }} className="text-left fw-bolder ps-3">WELCOME TO</h1>
                <h1 style={{ fontSize: "2.5rem" }} className="text-left fw-bolder ps-3 mb-3">LG PLAYGROUND</h1>
                <p className="text-left fw-bolder ps-3 mt-5 h4">LG Playground를 설치하면</p>
                <p className="text-left fw-bolder ps-3 h4">플랫폼사업센터의 새로운 서비스를</p>
                <p className="text-left fw-bolder ps-3 mb-5 h4 mb-3 d-none d-md-block d-lg-block">미리 만나볼 수 있습니다</p>

                <div className="d-flex align-items-left ps-3 mt-4">
                  <Button variant="outline-gray-700" className="me-3" style={{ width: "120px" }}>
                    <a href='https://d3hw8j3d2xi1v9.cloudfront.net/installer/android/LGPlayground.apk'>
                      <FontAwesomeIcon icon={faAndroid} className="d-sm-inline ms-1" /> Android
                    </a>
                  </Button>
                  <Button variant="outline-gray-700" className="me-3" style={{ width: "120px" }}>
                    <a href='itms-services://?action=download-manifest&url=https://d3hw8j3d2xi1v9.cloudfront.net/installer/ios/manifest.plist'>
                      <FontAwesomeIcon icon={faApple} className="d-sm-inline ms-1" /> iOS
                    </a>
                  </Button>
                </div>
              </Col>
            </Row>
          </section>
        </div>
      </div>
    </div>
  );
};
