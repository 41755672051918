import React, { Component } from 'react';
import { Button, Container, Row, Card, Modal } from '@themesberg/react-bootstrap';
import axios from 'axios';
import SurveyPage from './components/SurveyPage';

import auth from "../../utils/auth";
import { PG_CONFIG } from "../../data/pgConfig";
import { SURVEY_USER_TYPE, SURVEY_STATUS } from "./const/surveyConst";


import { Breadcrumb } from '@themesberg/react-bootstrap';
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default class SurveyCreator extends Component {
  state = {
    app_list: [],
    app_id: "0",
    app_name: "",
    survey_id: "",
    survey_header: {
      survey_title: "",
      survey_description: ""
    },
    last_survey_id: 0,
    survey_list: [
    ],
    start_date: 0,
    end_date: 0,
    user_number: "",
    request_id: "",
    current_user_type:0,
    delete_modal: {
      title: "",
      message: "",
      open: false
      //survey_status: 0
    },
    alert_modal: {
      title: "",
      message: "",
      open: false,
      goback: false
    }
  }

  /*
  state = {
      app_list:[],
      app_id:"efa826b0-5f13-41ff-94de-572faf8f96c0",
      app_name:"Whale",
      survey_header: {
          survey_title: "Whale browser 사용성 설문",
          survey_description: "웨일 브라우저 사용에 관한 설문 입니다."
      },
      last_survey_id : 4,
      survey_list: [
          {
              id : 1,
              type : "singleinput",
              question : "응답자가 사용하는 OS는?",
              required : true,
              is_description: true,
              description : "Windows, Android, 또는 iOS 로 표기",
              candidate_cnt : 0,
              candidate : [],
              selected : [],
              is_comment : true,
              comment : ""
          },
          {
              id : 2,
              type : "checkbox",
              question : "웨일 브라우져 이외에 사용하는 브라우저는?",
              required : true,
              is_description: true,
              description : "사용하는 브라우저를 모두 선택하세요.",
              candidate_cnt : 5,
              candidate : [
                  {
                      value : 1,
                      text : "chrome"
                  },
                  {
                      value : 2,
                      text : "edge"
                  },
                  {
                      value : 3,
                      text : "safari"
                  },
                  {
                      value : 4,
                      text : "firefox"
                  },
                  {
                      value : 5,
                      text : "other"
                  },
              ],
              selected : [],
              is_comment : true,
              comment : ""
          },
          {
              id : 3,
              type : "radiogroup",
              question : "사용하는 브라우저 중 가장 많이 사용하는 브라우저는?",
              required : true,
              is_description: false,
              description : "",
              candidate_cnt : 6,
              candidate : [
                  {
                      value : 1,
                      text : "whale"
                  },
                  {
                      value : 2,
                      text : "chrome"
                  },
                  {
                      value : 3,
                      text : "edge"
                  },
                  {
                      value : 4,
                      text : "safari"
                  },
                  {
                      value : 5,
                      text : "firefox"
                  },
                  {
                      value : 6,
                      text : "other"
                  }
              ],
              selected : [],
              is_comment : true,
              comment : ""
          },
          {
              id: 4,
              type: "radiogroup",
              question: "3번 에서 응답한 브라우저를 가장 많이 사용하는 이유는?",
              required: false,
              is_description: true,
              description: "핵심적인 이유 한가지를 선택하세요",
              candidate_cnt: 3,
              candidate: [
                  {
                      value: 1,
                      text: "속도"
                  },
                  {
                      value: 2,
                      text: "적은 데이터 사용"
                  },
                  {
                      value: 3,
                      text: "기타"
                  }
              ],
              selected: [],
              is_comment: true,
              comment: ""
          },
          {
              id : 5,
              type : "rating",
              question : "웨일브라우저를 평가해주세요",
              required : true,
              is_description: true,
              description : "1~5점으로 웨일 브라우저를 평가해주세요.",
              candidate_cnt : 5,
              candidate : [
                  {
                      value : 1,
                      text : "1"
                  },
                  {
                      value : 2,
                      text : "2"
                  },
                  {
                      value : 3,
                      text : "3"
                  },
                  {
                      value : 4,
                      text : "4"
                  },
                  {
                      value : 5,
                      text : "5"
                  }
              ],
              selected : [],
              is_comment : true,
              comment : ""
          }
      ],
      start_date:0,
      end_date:0
  }
  */

  loadItem = async () => {
    const survey_id = this.props.location.state.survey_id;

    if ((survey_id !== null) && (survey_id !== "")) {
      try {
        const response = await axios.get(PG_CONFIG.apiGatewayUrl + '/survey/list/' + survey_id, {
          timeout: 2000
        });
        const resSurveyData = response.data;
        //console.log(resSurveyData);
        if (resSurveyData) {
          const surveyContents = JSON.parse(resSurveyData.survey_contents);
          this.setState({
            survey_id: survey_id,
            app_id: surveyContents.app_id,
            app_name: surveyContents.app_name,
            survey_header: surveyContents.survey_header,
            last_survey_id: surveyContents.survey_list.length,
            survey_list: surveyContents.survey_list,
            start_date: surveyContents.start_date,
            end_date: surveyContents.end_date,
            user_number: resSurveyData.user_number,
            request_id: resSurveyData.request_id
          });
        }
      } catch (error) {
        console.log(error);
      }
    } else {

    }

    var userInfo = auth.getUserInfo();

    //App 정보 Loading - ToDo : 해당 개발자가 등록한 App만 가져오도록 정리 필요.
    let response = null;

    try {
      let requestID;
      let app_status = [];
      if (userInfo.userType === SURVEY_USER_TYPE.ADMIN) {
        app_status = survey_id === "" ? `app_status=1` : `app_status=1&app_status=2`;
        requestID = 'request_id=';
      } else if (userInfo.userType === SURVEY_USER_TYPE.DEVELOPER) {
        app_status = survey_id === "" ? `app_status=1` : `app_status=1&app_status=2`;
        requestID = 'request_id=' + auth.getUserID();
      }

      response = await axios.get(PG_CONFIG.apiGatewayUrl + '/apps/approval' + '?' + app_status + '&' + requestID);

      if (response !== null) {
        const AppsData = response.data.appList;
        let appInfo = [];
        for (let app of AppsData) {
          appInfo.push({
            app_name: app.app_name,
            app_id: app.app_id
          })
        }

        this.setState({ app_list: appInfo });
      }
    } catch (error) {
      console.log(error);
    }
  }

  componentDidMount() {
    this.loadItem();
    var calInitDate = new Date();
    if (this.state.start_date === 0) {
      var startInitDate = new Date(calInitDate.getFullYear(), calInitDate.getMonth(), calInitDate.getDate()).getTime();
      this.setState({
        start_date: startInitDate
      });
    }
    if (this.state.end_date === 0) {
      var endInitDate = new Date(calInitDate.getFullYear(), calInitDate.getMonth(), calInitDate.getDate(), 23, 59, 59).getTime();
      this.setState({
        end_date: endInitDate
      });
    }
  }

  addSurveyForm = (newForm) => {
    //console.log(newForm);

    var newContents = Array.from(this.state.survey_list);
    newContents.push(newForm);

    this.setState({
      last_survey_id: newForm.id,
      survey_list: newContents
    });
  }

  deleteSurveyForm = (id) => {
    var newLists = Array.from(this.state.survey_list);
    var i = 0;

    while (i < newLists.length) {
      if (id === newLists[i].id) {
        newLists.splice(i, 1);
        break;
      }
      i = i + 1;
    }

    this.setState({
      survey_list: newLists
    });
  }

  updateSurveyHeader = (updateHeader) => {
    this.setState({
      survey_header: updateHeader
    });
  }

  updateSurvey = (id, updateItem) => {
    var newLists = Array.from(this.state.survey_list);
    var i = 0;
    while (i < newLists.length) {
      if (id === newLists[i].id) {
        newLists[i] = updateItem;
        break;
      }
      i = i + 1;
    }

    this.setState({
      survey_list: newLists
    });
  }

  changeOrderSurvey = (surveys) => {
    this.setState({
      survey_list: surveys
    });
  }

  onUpdateSurveyPeriod = (se, millis) => {
    if (se === "start_date") {
      this.setState({ start_date: millis });
    } else {
      this.setState({ end_date: millis });
    }
  }

  onSelectApp = (appId, appName) => {
    this.setState({
      app_id: appId,
      app_name: appName
    });
  }

  RequestSurveyCreation = async (event) => {
    let modalTitle = '설문 등록 요청';
    let modalMessage = 'Survey 등록이 요청 되었습니다.'
    //let survey_status;

    var requestErrMessage = "";
    var requestErr = false;

    var userInfo = auth.getUserInfo();
    if (userInfo === undefined) {
      requestErr = true;
      requestErrMessage = "로그인이 필요합니다.";
    } else if (this.state.app_id === "0") {
      requestErr = true;
      requestErrMessage = "설문이 필요한 앱을 선택하세요.";
    } else if (this.state.survey_header.survey_title === "") {
      requestErr = true;
      requestErrMessage = "설문 제목을 설정하세요.";
    } else if (this.state.survey_list.length <= 0) {
      requestErr = true;
      requestErrMessage = "질문이 없습니다.";
    }

    if (requestErr === true) {
      //alert(requestErrMessage);
      this.setState({
        alert_modal: {
          title: '설문 요청 오류',
          message: requestErrMessage,
          open: true,
          goback: false
        }
      });
    } else {
      try {
        //New Survey
        var surveybody = {
          app_id: this.state.app_id,
          app_name: this.state.app_name,
          survey_header: this.state.survey_header,
          survey_list: this.state.survey_list,
          start_date: this.state.start_date,
          end_date: this.state.end_date,
          survey_status: SURVEY_STATUS.REQUESTED,
          user_number: userInfo.userNumber,
          request_id: userInfo.userID
        };

        //survey_list 의 id를 Array index로 변경.
        var i = 0;
        while (i < surveybody.survey_list.length) {
          surveybody.survey_list[i].id = i + 1;
          i = i + 1;
        }
        //console.log(surveybody);

        axios.post(PG_CONFIG.apiGatewayUrl + "/survey/create", surveybody)
          .then(response => {
            this.setState({
              alert_modal: {
                title: modalTitle,
                message: modalMessage,
                open: true,
                //survey_status: survey_status
                goback: true
              }
            });
          })
          .catch(error => {
            console.log(error);
            this.setState({
              alert_modal: {
                title: '설문 요청 오류',
                message: '설문 요청 처리중 오류가 발생되었습니다.',
                open: true,
                //survey_status: SURVEY_STATUS.REQUESTED
                goback: false
              }
            });
          });
      } catch (error) {
        console.log(error);
        this.setState({
          alert_modal: {
            title: '설문 요청 오류',
            message: '설문 요청 처리중 오류가 발생되었습니다.',
            open: true,
            //survey_status: SURVEY_STATUS.REQUESTED
            goback: false
          }
        });
      }
    }
  }

  RequestSurveyDeletion = async (event) => {
    let modalTitle = '설문 삭제';
    let modalMessage = '설문을 삭제할까요?';
    //let survey_status;

    var requestErrMessage = "";
    var requestErr = false;

    var userInfo = auth.getUserInfo();
    if (userInfo === undefined) {
      requestErr = true;
      requestErrMessage = "로그인이 필요합니다."
    } else if ((userInfo.userType !== SURVEY_USER_TYPE.ADMIN) && (userInfo.userType !== SURVEY_USER_TYPE.DEVELOPER)) {
      requestErr = true;
      requestErrMessage = "삭제 권한이 없습니다."
    }

    if (requestErr === true) {
      //alert(requestErrMessage);
      this.setState({
        alert_modal: {
          title: '설문 삭제 오류',
          message: requestErrMessage,
          open: true,
          goback: false
        }
      });
    } else {
      try {
        this.setState({
          delete_modal: {
            title: modalTitle,
            message: modalMessage,
            open: true
          }
        });
        /*
        axios.post(PG_CONFIG.apiGatewayUrl + "/survey/remove/" + this.state.survey_id)
          .then(response => {
            this.setState({
              modal: {
                title: modalTitle,
                message: modalMessage,
                open: true,
                //survey_status: survey_status
              }
            });
          })
          .catch(error => {
            console.log(error);
            this.setState({
              alert_modal: {
                title: '설문 삭제 오류',
                message: '설문 삭제 처리중 오류가 발생되었습니다.',
                open: true,
                //survey_status: SURVEY_STATUS.REQUESTED
                goback: false
              }
            });
          });
        */
      } catch (error) {
        console.log(error);
        this.setState({
          alert_modal: {
            title: '설문 삭제 오류',
            message: '설문 삭제 처리중 오류가 발생되었습니다.',
            open: true,
            //survey_status: SURVEY_STATUS.REQUESTED
            goback:false
          }
        });
      }
    }
  }

  RequestSurveyUpdate = async (event) => {
    let modalTitle = '설문 수정 요청';
    let modalMessage = '설문이 수정되어 등록 요청 되었습니다.';
    //let survey_status;

    var requestErrMessage = "";
    var requestErr = false;

    var userInfo = auth.getUserInfo();
    if (userInfo === undefined) {
      requestErr = true;
      requestErrMessage = "로그인이 필요합니다.";
    } else if (this.state.app_id === "0") {
      requestErr = true;
      requestErrMessage = "설문이 필요한 앱을 선택하세요.";
    } else if (this.state.survey_header.survey_title === "") {
      requestErr = true;
      requestErrMessage = "설문 제목을 설정하세요.";
    } else if (this.state.survey_list.length <= 0) {
      requestErr = true;
      requestErrMessage = "질문이 없습니다.";
    }

    if (requestErr === true) {
      //alert(requestErrMessage);
      this.setState({
        alert_modal: {
          title: '설문 수정 요청 오류',
          message: requestErrMessage,
          open: true,
          goback: false
        }
      });
    } else {
      try {
        //Update Survey
        var surveybody = {
          app_id: this.state.app_id,
          app_name: this.state.app_name,
          survey_header: this.state.survey_header,
          survey_list: this.state.survey_list,
          start_date: this.state.start_date,
          end_date: this.state.end_date,
          survey_status: SURVEY_STATUS.REQUESTED,
          user_number: this.state.user_number,
          request_id: this.state.request_id
        };

        //survey_list 의 id를 Array index로 변경.
        var i = 0;
        while (i < surveybody.survey_list.length) {
          surveybody.survey_list[i].id = i + 1;
          i = i + 1;
        }
        //console.log(surveybody);

        axios.put(PG_CONFIG.apiGatewayUrl + "/survey/update/" + this.state.survey_id, surveybody)
          .then(response => {
            this.setState({
              alert_modal: {
                title: modalTitle,
                message: modalMessage,
                open: true,
                //survey_status: survey_status
                goback: true
              }
            });
          })
          .catch(error => {
            console.log(error);
            this.setState({
              alert_modal: {
                title: '설문 수정 요청 오류',
                message: '설문 수정 요청 처리중 오류가 발생되었습니다.',
                open: true,
                //survey_status: SURVEY_STATUS.REQUESTED
                goback: false
              }
            });
          });
      } catch (error) {
        console.log(error);
        this.setState({
          alert_modal: {
            title: '설문 수정 요청 오류',
            message: '설문 수정 요청 처리중 오류가 발생되었습니다.',
            open: true,
            //survey_status: SURVEY_STATUS.REQUESTED
            goback: false
          }
        });
      }
    }
  }

  deleteConfirmHandler = () => {
    try {
      axios.post(PG_CONFIG.apiGatewayUrl + "/survey/remove/" + this.state.survey_id)
      .then(response => {
        this.setState({
          delete_modal: {
            open: false
          },
          alert_modal: {
            title: '설문 삭제 완료',
            message: "설문이 삭제되었습니다.",
            open: true,
            //survey_status: survey_status
            goback: true
          }
        });
      })
      .catch(error => {
        console.log(error);
        this.setState({
          delete_modal: {
            open: false
          },
          alert_modal: {
            title: '설문 삭제 오류',
            message: '설문 삭제 처리중 오류가 발생되었습니다.',
            open: true,
            //survey_status: SURVEY_STATUS.REQUESTED
            goback: false
          }
        });
      });
    } catch (error) {
      console.log(error);
      this.setState({
        delete_modal: {
          open: false
        },
        alert_modal: {
          title: '설문 삭제 오류',
          message: '설문 삭제 처리중 오류가 발생되었습니다.',
          open: true,
          //survey_status: SURVEY_STATUS.REQUESTED
          goback:false
        }
      });
    }
  }

  deleteModalClose = () => {
    this.setState({
      delete_modal: {
        open: false
      }
    })
  }

  //Alert Modal hanler
  alertModalClose = () => {
    this.setState({
      alert_modal: {
        open: false
      }
    })
    if (this.state.alert_modal.goback === true) {
      this.props.history.goBack();
    }
  }

  render() {
    //console.log(this.state);

    var btnTags = [];
    if (this.state.survey_list.length <= 0) {
      btnTags.push(<div></div>);
    } else {
      if (this.state.survey_id === "") {
        btnTags.push(<Button outline="true" size="md" style={{ width: "120px", margin: "0px 10px" }} theme="primary" className="mb-2 mr-1" id="request" name="request"
          onClick={this.RequestSurveyCreation.bind(this)}>Request</Button>);
      } else {
        btnTags.push(
          <div className="mt-2 mb-4">
            <div className="text-right">
              <Button outline="true" size="md" style={{ width: "120px", margin: "0px" }} variant="danger" className="mb-2 mr-1" id="delete" name="delete"
                onClick={this.RequestSurveyDeletion.bind(this)}>Delete</Button>
              <Button outline="true" size="md" style={{ width: "120px", margin: "0px 10px" }} theme="primary" className="mb-2 mr-1 me-2" id="update" name="update"
                onClick={this.RequestSurveyUpdate.bind(this)}>Update </Button>
            </div>
          </div>
        );
      }
    }

    return (
      <div>
        <Container fluid className="main-content-container" style={{ minWidth: "480px" }}>
          {/* Page Header */}
          <Row noGutters className="page-header py-4">
            {/*<PageTitle sm="4" title="Survey Ceater" subtitle="Survey" className="text-sm-left" />*/}
            <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
              <Breadcrumb.Item><FontAwesomeIcon icon={faHome} /></Breadcrumb.Item>
              <Breadcrumb.Item>Survey</Breadcrumb.Item>
              <Breadcrumb.Item active>Creator</Breadcrumb.Item>
            </Breadcrumb>
            <h4>Survey Creator</h4>
            <p className="mb-0">
              { }
            </p>
          </Row>
          <Container>
            <Card small="true" className="mb-3">
              <Card.Header className="border-bottom border-light d-flex justify-content-between">
                <span className="d-block">
                  <h4 className="text-primary align-top">Create Survey</h4>
                  <h6 className="text-primary align-top">Please fill out a survey for your app or general</h6>
                </span>
              </Card.Header>
              <Card.Body>
                <SurveyPage
                  userType={auth.getUserInfo().userType}
                  appList={this.state.app_list}
                  appId={this.state.app_id}
                  appName={this.state.app_name}
                  surveyId={this.state.survey_id}
                  surveyHeaderContent={this.state.survey_header}
                  surveyStartDate={this.state.start_date}
                  surveyEndDate={this.state.end_date}
                  onUpdatePeriod={this.onUpdateSurveyPeriod}
                  surveylist={this.state.survey_list}
                  lastId={this.state.last_survey_id}
                  onAddSurvey={this.addSurveyForm}
                  onUpdateSurveyHeader={this.updateSurveyHeader}
                  onUpdateSurvey={this.updateSurvey}
                  onDeleteSurvey={this.deleteSurveyForm}
                  onChangeOrder={this.changeOrderSurvey}
                  onSelectApp={this.onSelectApp}
                />
                <div className="mt-4 mb-1">
                  <div className="text-right">
                    {btnTags}
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Container>
          <Modal as={Modal.Dialog} centered show={this.state.delete_modal.open}>
            <Modal.Header>
              <Modal.Title className="h6">{this.state.delete_modal.title}</Modal.Title>
              <Button variant="close" aria-label="Close" onClick={() => {this.deleteModalClose();}} />
            </Modal.Header>
            <Modal.Body>
              <p>{this.state.delete_modal.message}</p>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => {this.deleteConfirmHandler();}}>확인</Button>
              <Button variant="link" className="text-gray ms-auto" onClick={() => {this.deleteModalClose();}}>닫기</Button>
            </Modal.Footer>
          </Modal>

          {/* Alert Modal */}
          <Modal as={Modal.Dialog} centered show={this.state.alert_modal.open}>
            <Modal.Header>
              <Modal.Title className="h6">{this.state.alert_modal.title}</Modal.Title>
              <Button variant="close" aria-label="Close" onClick={() => {this.alertModalClose();}} />
            </Modal.Header>
            <Modal.Body>
              <p>{this.state.alert_modal.message}</p>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="link" className="text-gray ms-auto" onClick={() => {this.alertModalClose();}}>닫기</Button>
            </Modal.Footer>
          </Modal>
        </Container>
      </div>
    );
  }
}