import { PG_CONFIG } from "../data/pgConfig"
import axios from 'axios';

const requestUserPermission = (userNumber, userId, request) => {
  fetch(PG_CONFIG.apiGatewayUrl + '/user/permission/request', {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      "id": userNumber,
      "user_id": userId,
      "request_state": request.requestState,
      "request_type": request.requestType,
      "request_reason": request.requestReason
    }),
  });
}

const getRequestedPermissions = () => {
  return fetch(PG_CONFIG.apiGatewayUrl + '/user/permissions')
    .then(data => data.json())
}

const getRequestedPermission = (userNumber) => {
  return fetch(PG_CONFIG.apiGatewayUrl + '/user/permission/' + userNumber)
    .then(data => data.json())
}

const updatePermissionApprove = async (userNumber, requestType) => {
  await fetch(PG_CONFIG.apiGatewayUrl + '/user/permission/remove/' + userNumber, {
    method: "POST"
  });

  await fetch(PG_CONFIG.apiGatewayUrl + '/user/info', {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      "id": userNumber,
      "user_type": requestType
    }),
  });
}

const updatePermissionReject = async (userNumber) => {
  await fetch(PG_CONFIG.apiGatewayUrl + '/user/permission/remove/' + userNumber, {
    method: "POST"
  });
}

const updateUserDepartment = async (userNumber, department) => {
  await fetch(PG_CONFIG.apiGatewayUrl + '/user/info/department', {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      "id": userNumber,
      "department": department
    }),
  });
}

const sendEmail = async (recipient, subject, message) => {
  await axios.post(PG_CONFIG.apiGatewayUrl + "/user/email/send",
    {
      recipient: recipient,
      messageSubject: "[LG Playground] " + subject,
      messageBody: message,
    },
    {
      headers: {
        'Content-Type': 'application/json'
      }
    }
  );
}

const sendFCMPush = async (notiTitle, notiBody, dataType, dataLink, dataAppId, dataSurveyId) => {
  await axios.post(PG_CONFIG.apiGatewayUrl + "/user/fcm/send",
    {
      title: notiTitle,
      body: notiBody,
      type: dataType,
      link: dataLink,
      app_id: dataAppId,
      survey_id: dataSurveyId
    },
    {
      headers: {
        'Content-Type': 'application/json'
      }
    }
  );
}

const getBoardList = (limit, page, search_type, search_text, category) => {
  return fetch(PG_CONFIG.apiGatewayUrl + '/board/list', {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      "limit": limit,
      "page": page,
      "search_type": search_type,
      "search_text": search_text,
      "category": category
    }),
  }).then(data => data.json());
}

const createNewPost = async (post, date, category, appId, password) => {
  await fetch(PG_CONFIG.apiGatewayUrl + '/board/create', {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      "title": post.title,
      "contents": post.contents,
      "author": post.author,
      "date": date,
      "category": category,
      "appId": appId,
      "deviceType": post.deviceType,
      "password": password
    }),
  });
}

const updatePost = async (id, title, contents) => {
  await fetch(PG_CONFIG.apiGatewayUrl + '/board/update', {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      "id": id,
      "title": title,
      "contents": contents
    }),
  });
}

const updatePostViews = async (id) => {
  await fetch(PG_CONFIG.apiGatewayUrl + '/board/update/views', {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      "id": id
    }),
  });
}

const deletePost = async (post, password) => {
  console.log('debug: id=' + post.id + ', author=' + post.author + ', password=' + password);
  await fetch(PG_CONFIG.apiGatewayUrl + '/board/delete', {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      "id": post.id,
      "author": post.author,
      "password": password
    }),
  });
}

const registerComment = async (board_id, comment, commenter, date) => {
  await fetch(PG_CONFIG.apiGatewayUrl + '/board/comment/register', {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      "board_id": board_id,
      "comment": comment,
      "commenter": commenter,
      "date": date
    }),
  });
}

const deleteComment = async (id, comment_id) => {
  await fetch(PG_CONFIG.apiGatewayUrl + '/board/comment/delete', {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      "board_id": id,
      "id": comment_id
    }),
  });
}

const getComment = async (board_id) => {
  return await fetch(PG_CONFIG.apiGatewayUrl + '/board/comment/list', {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      "board_id": board_id
    }),
  }).then(data => data.json());
}

const getImageUploadUrl = async (path) => {
  return await fetch(PG_CONFIG.apiGatewayUrl + '/board/image/uploadurl?upload_url=' + path)
    .then(data => data.json());
}


const updatePostLikes = async (board_id, user_id, is_like) => {
  return await fetch(PG_CONFIG.apiGatewayUrl + '/board/update/likes', {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      "board_id": board_id,
      "user_id": user_id,
      "is_like": is_like
    }),
  }).then(data => data.json());
}

const getLikeStatus = async (board_id, user_id) => {
  return await fetch(PG_CONFIG.apiGatewayUrl + '/board/like/status', {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      "board_id": board_id,
      "user_id": user_id
    }),
  }).then(data => data.json());
}

export default {
  getRequestedPermissions,
  getRequestedPermission,
  requestUserPermission,
  updatePermissionApprove,
  updatePermissionReject,
  updateUserDepartment,
  sendEmail,
  sendFCMPush,
  getBoardList,
  createNewPost,
  updatePost,
  updatePostViews,
  deletePost,
  registerComment,
  deleteComment,
  getComment,
  getImageUploadUrl,
  updatePostLikes,
  getLikeStatus
}
