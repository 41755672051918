import React, { Component } from 'react';
import { Badge, Container, Navbar, Row } from '@themesberg/react-bootstrap';
import axios from 'axios';
import { PG_CONFIG } from "../../data/pgConfig";
import FadeLoader from "react-spinners/FadeLoader";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './styles/SurveyCard.css';

import moment from 'moment';

export default class SurveyMyListMobile extends Component {
  state = {
    survey_my_list: [],
    run_status:0,
    user_number:"",
    isLoading: false
  }

  loadItem = async () => {
    //var userInfo = auth.getUserInfo();
    const search = this.props.location.search;
    const appId = new URLSearchParams(search).get("app_id");
    const runStatus = Number(new URLSearchParams(search).get("status"));
    const userNumber = new URLSearchParams(search).get("user_number");

    let resultList = [];
    if (runStatus === 0 || runStatus === 1) {
      this.setState({ isLoading: true });
      try {
        let response;
        response = await axios.get(PG_CONFIG.apiGatewayUrl + '/survey/apps/list', {
          params: {
            app_id: appId,
            run_status : runStatus,
            user_number : userNumber
          },
          timeout: 2000
        });
        const resSurveyItems = response.data;

        console.log(resSurveyItems);

        for (let surveyItem of resSurveyItems) {
          resultList.push(surveyItem);
        }
      } catch (error) {
        console.log(error);
      }

      this.setState({
        survey_my_list: resultList,
        run_status: Number(runStatus),
        user_number: userNumber,
        isLoading: false
      });
    }
  }

  componentDidMount() {
    this.loadItem();
  }

  TableRow = (requestSurvey) => {
    const surveyData = requestSurvey;
    const surveyContents = JSON.parse(surveyData.survey_contents);
    const surveySubject = surveyContents.survey_header.survey_title;
    const surveyAppId = surveyData.app_id;
    const surveyAppName = surveyData.app_name;
    const surveyPeriod = moment(surveyData.start_date).format("YYYY.MM.DD") + "~" + moment(surveyData.end_date).format("YYYY.MM.DD");
    const surveyRequester = surveyData.request_id;
    const currentTime = new Date().getTime();

    let questionList = [];
    for (let question of surveyContents.survey_list) {

      let answerList = [];
      for (let ans of question.candidate) {
        answerList.push({
          value: ans.value,
          text: ans.text,
          amount: 0
        })
      }

      questionList.push({
        id: question.id,
        type: question.type,
        question: question.question,
        answer: answerList,
        //select:[],
        comment: []
      });
    }

    //console.log(questionList);

    return (
      <div className="request" onClick={() => {
        if (this.state.run_status === 1) {
          this.props.history.push(
            `/survey/response?survey_id=${surveyData.id}&user_number=${this.state.user_number}`
          );
        } else {
          this.props.history.push({
            pathname: "/survey/m/summary",
            state: {
              id: surveyData.id,
              survey_header: {
                survey_title: surveyContents.survey_header.survey_title,
                survey_description: surveyContents.survey_header.survey_description
              },
              app_id: surveyData.app_id,
              app_name: surveyData.app_name,
              period: surveyPeriod,
              request_id: surveyData.request_id,
              question_list: questionList
            }
          });
        }
      }}>
        <div className="survey_card_body" style={{ width: "100%", padding: "0px 2px" }}>
          <div className="border-bottom border-light d-flex justify-content-between" style={{ padding: "10px 10px" }}>
            <span className="d-block">
              <h6 className="text-primary align-top">{surveySubject}</h6>
              <div style={{ fontSize: "14px" }}>&nbsp;&nbsp;&ordm; 설문대상 :&nbsp;
                {
                  surveyAppName
                  ? ((surveyAppId.split(':')[0] === 'lgpg') || (surveyAppId.split(':')[0] === 'general'))
                    ? surveyAppName
                    : surveyAppName + ' (' + surveyAppId.split(':')[0] + ')'
                  : "-"
                  /* surveyAppName ? surveyAppName + ' (' + surveyAppId.split(':')[0] + ')' : "-" */
                }
              </div>
              <div style={{ fontSize: "14px" }}>&nbsp;&nbsp;&ordm; 설문기간 : {surveyPeriod ? surveyPeriod : "-"}
                {((currentTime >= surveyData.start_date) && (currentTime <= surveyData.end_date))
                  ? <Badge pill bg={"success"} className="badge-md ms-2">진행중</Badge>
                  : <Badge pill bg={"danger"} className="badge-md ms-2">종료</Badge>}
              </div>
              <div style={{ fontSize: "14px" }}>&nbsp;&nbsp;&ordm; 작성자 : {surveyRequester ? surveyRequester : "-"}</div>
            </span>
          </div>
        </div>
      </div>
      );
  }

  render() {
    //console.log(this.state.survey_my_list);
    var requestedList = [];
    if (this.state.survey_my_list.length <= 0) {
      requestedList.push(
        <div className="survey_card_list_none">설문이 없습니다.</div>
      );
    } else {
      requestedList.push(
        <div>
          {this.state.survey_my_list.map(r => <this.TableRow key={`request-${r.id}`} {...r} />)}
        </div>
      )
    }
    return (
      <>
        {this.state.isLoading
          ?
          <Container fluid className="main-content-container px-4 pb-4">
            <div className="loading" style={{ position: "fixed", top: "45%", left: "45%", transform: "translate(-50%, -50%)" }}>
              <FadeLoader height="15px" width="5px" color="#6b5ce7" radius="2px" margin="2px" />
            </div>
          </Container>
          :
          <div>
            <Navbar className="sticky-top" style={{ 'background-color': '#f5f8fb' }}>
              <main className="content">
                <Row noGutters className="page-header">
                  <h4 className="mb-0">
                    <FontAwesomeIcon icon={faArrowLeft} className="me-2" onClick={() => { window.location.replace("/survey/m/mylist?returnCode=400&returnDescription=cancel") }} />
                    Survey List
                  </h4>
                </Row>
              </main>
            </Navbar>
            <main className="content mt-2">
              <Container fluid style={{ padding: "0px 0px" }}>
                {requestedList}
              </Container>
            </main>
          </div>}
      </>
    );
  }
}