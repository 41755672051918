import React, { useState, useEffect } from "react";
import { Button, Card, Col, Container, Dropdown, Form, Modal, Row, Table } from '@themesberg/react-bootstrap';
import { Link } from 'react-router-dom';
import { PG_CONFIG } from "../data/pgConfig";
import { Routes } from "../routes";

import Dropzone from 'react-dropzone';
import Sortable from 'react-sortablejs';
import { Trash2, UploadCloud } from 'react-feather';
import gadget from "../utils/gadget";
import auth from "../utils/auth";
import axios from 'axios';

function  LGPGUploader(props) {
  const VERSION_LENGTH = 9;
  const [versionRendering, setVersionRendering] = useState(true);
  const [fileList, setFileList] = useState({
    init: false,
    allFileList: [],
    android_apk: [],
    ios_ipa: [],
    ios_plist: []
  });
  const [appVersion, setVersion] = useState({
    currVersion:"1.0.4",
    newVersion:""
  });
  // Fileupload / Error Modal
  const [getUploadModal, setUploadModal] = useState({ open: false, title: '', message: '' });
  const [getErrorModal, setErrorModal] = useState({ open: false, title: '', message: '' });
  const [getCompleteModal, setCompleteModal] = useState({ open: false, title: '', message: '', message2:'' });
  const [getInvalidationCompleteModal, setInvalidationCompleteModal] = useState({ open: false, title: '', message: '' });

  useEffect(() => {
    const fetchData = async() => {
      try {
        const response = await axios.get(PG_CONFIG.apiGatewayUrl + `/lgpg/version`);
        let versionName = response.data[0].version_name;
        let versionCode = response.data.version_code;
        console.log(versionName);
        setVersion({ ...appVersion, currVersion: versionName });
      } catch (error) {
        console.log('Error: ', error);
      }
    };
    fetchData();
  }, []);

  const handleVersionEdit = async (event, length) => {
    if (event.currentTarget == null
        || event.currentTarget.value.length > length) {
      return;
    }

    //let getString = event.currentTarget;
    //let key = getString.name;
    setVersion({ ...appVersion, newVersion: event.currentTarget.value});
  }

  const formatBytes = (bytes, decimals) => {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  const handleAcceptedFiles = (files) => {
    files.map(file => {
        let preview = (file['type'].split('/')[0] === 'image') ? URL.createObjectURL(file) : null;
        let date = new Date(file.lastModified).toLocaleString();
        let appFileSize = formatBytes(file.size);
        let position;

        if (file['name'].match(/(.apk)$/) !== null) {
          position = 'android_apk';
        } else if (file['name'].match(/(.ipa)$/) !== null) {
          position = 'ios_ipa';
        } else if (file['name'].match(/(.plist)$/) !== null) {
          position = 'ios_plist';
        } else {
          position = '';
        }

        if (position !== '') {
          return Object.assign(file, {
            preview: preview,
            date: date,
            app_size: appFileSize,
            position: position,
            uploaded: false,
          })
        }
      }
    );

    let android_apk = [...fileList.android_apk];
    let ios_ipa = [...fileList.ios_ipa];
    let ios_plist = [...fileList.ios_plist];

    for (let file of files) {
      switch(file.position) {
        case 'android_apk':
          if (android_apk.filter((android_apk) => android_apk.name.match(file.name)).length === 0) {
            android_apk.push(file);
          }
          if (1 < android_apk.length) {
            android_apk.pop();
          }
          break;

        case 'ios_ipa':
          if (ios_ipa.filter((ios_ipa) => ios_ipa.name.match(file.name)).length === 0) {
            ios_ipa.push(file);
          }
          if (1 < ios_ipa.length) {
            ios_ipa.pop();
          }
          break;

        case 'ios_plist':
          if (ios_plist.filter((ios_plist) => ios_plist.name.match(file.name)).length === 0) {
            ios_plist.push(file);
          }
          if (1 < ios_plist.length) {
            ios_plist.pop();
          }
          break;
        default:
          break;
      }
    }

    setFileList({ ...fileList, init: true, android_apk, ios_ipa, ios_plist})
  }

  const RemoveFile = (index, filename) => {
    console.log(index, filename);
    switch (index) {
      case 'android_apk':
        setFileList({ ...fileList, android_apk: fileList.android_apk.filter(file => !decodeURIComponent(file.path).includes(filename)) });
        break;
      case 'ios_ipa':
        setFileList({ ...fileList, ios_ipa: fileList.ios_ipa.filter(file => !decodeURIComponent(file.path).includes(filename)) });
        break;
      case 'ios_plist':
        setFileList({ ...fileList, ios_plist: fileList.ios_plist.filter(file => !decodeURIComponent(file.path).includes(filename)) });
        break;
      default:
        break;
    }
  }

  const FileContainer = (props) => {
    let fileData = props.file;

    let filename;
    let date = fileData.date;
    let appSize = fileData.app_size;

    if (fileData.uploaded) {
      let getUrl = fileData.path.split('?')[0];
      filename = decodeURIComponent(getUrl).split('/')[6]
    } else {
      filename = fileData.path;
    }

    return (
      <React.Fragment>
        <tr style={{ verticalAlign: "middle" }}>
          <td className="border-0" style={{ width: "40%" }}> { filename } </td>
          <td className="border-0" style={{ width: "30%" }}> { date } </td>
          <td className="border-0" style={{ width: "20%" }}> { appSize } </td>
          <td className="border-0" style={{ width: "10%" }}>
            <div id="app-trash">
              <a  onClick={() => { RemoveFile(fileData.position, filename) }}>
                <Trash2/>
              </a>
            </div>
          </td>
        </tr>
      </React.Fragment>
    );
  }

  const AppFileContainer = ({ fileInfo }) => {
    if (fileInfo.length === 0) {
      return <React.Fragment/>
    }

    fileInfo = fileInfo.map((attachFile, idx) => <FileContainer key={idx} file={attachFile}/>);

    return (
      <div className="mx-4">
        {/*<p className="title">App 상세정보</p>*/}
        <Table className="table-centered table-nowrap rounded">
          <thead>
            <tr>
              <th className="border-0" style={{ width: "40%", fontSize: "13px", paddingBottom: "0px" }}>App filename</th>
              <th className="border-0" style={{ width: "30%", paddingBottom: "0px" }}>Date</th>
              <th className="border-0" style={{ width: "20%", paddingBottom: "0px" }}>Size</th>
              <th className="border-0" style={{ width: "10%", paddingBottom: "0px" }}></th>
            </tr>
          </thead>
          <tbody className="py-0">
            {fileInfo}
          </tbody>
        </Table>
      </div>
    );
  }

  const isValidVersion = (version) => {
    let regExp = /^[0-9]{1,2}\.[0-9]{1,2}\.[0-9]{1,3}$/;
    //let regExp = /^(\d{1,2}\.)?(\d{1,2}\.)?(\d{1,3}\.)$/;
    let oldVerArr = version.currVersion.replace(/\./g, "");
    let newVerArr = version.newVersion.replace(/\./g, "");

    //Version 형식 비교
    if (!regExp.test(version.newVersion)) {
      console.log("버전 형식 오류");
      return false;
    }
    //이전 버전과 비교
    if (Number(oldVerArr) > Number(newVerArr)) {
      console.log("이전 버전보다 낮은 버전입니다.");
      return false;
    }

    return true;
  }

  const appUpload = async() => {
    let attachFiles = [];

    // Valid Check
    if (!isValidVersion(appVersion)) {
      alert("버전 형식이 잘못되었거나 이전 버전보다 낮은 형식의 버전입니다.");
      return;
    } else if (fileList.android_apk.length < 1) {
      alert("Android용 apk 파일이 없습니다.");
      return;
    } else if (fileList.ios_ipa.length < 1) {
      alert("iOS용 ipa 파일이 없습니다.");
      return;
    } else if (fileList.ios_plist.length < 1) {
      alert("iOS용 plist 파일이 없습니다.");
      return;
    }

    //S3 경로 Android --> /installer/android/파일명(apk)
    //S3 경로 iOS --> /installer/ios/파일명(ipa,plist)
    attachFiles.push(fileList.android_apk[0]);
    attachFiles.push(fileList.ios_ipa[0]);
    attachFiles.push(fileList.ios_plist[0]);

    try {
      let uploadComplete = new Set();

      for (let file of attachFiles) {
        const uploadFilename = "installer/" + ((file.position === "android_apk") ? "android" : "ios") + "/" + file.name;
        let presignedURL = await axios.get(PG_CONFIG.apiGatewayUrl + '/apps/register' + `?upload_url=${uploadFilename}`);
        presignedURL = presignedURL.data.presignedURL;

        console.log(presignedURL);

        await axios.put(presignedURL, file, {
          onUploadProgress: (progressEvent) => {
            let title;
            let message;
            let button;
            let percentage;

            title = '등록 중...';
            message = `${file.name} 파일을 등록 중 입니다.`;
            button = '취소';
            percentage = Math.round((progressEvent.loaded / progressEvent.total) * 100);

            if (percentage >= 100) {
              uploadComplete.add(file.name);

              if (uploadComplete.size === attachFiles.length) {
                title = '등록 완료...';
                message = `${file.name} 파일이 등록 되었습니다.`;
                button = '확인';
              }
            }

            setUploadModal({
              open: true,
              title: title,
              message: message,
              filename: file.name,
              uploadedFileCnt: uploadComplete.size,
              totalFileCnt: attachFiles.length,
              totalProgress: Math.round((uploadComplete.size / attachFiles.length) * 100),
              progress: percentage,
              button: button
            });
          }
        });
      }
      console.log("App upload 성공");
      setUploadModal({ open: false });

      let newVerArr = appVersion.newVersion.replace(/\./g, "");
      let newVerCode = newVerArr[0].padStart(2, '0') + newVerArr[1].padStart(2, '0') + newVerArr[2].padStart(3, '0');

      //DB 에 정보 등록.
      await fetch(PG_CONFIG.apiGatewayUrl + "/lgpg/info", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          "version_name": appVersion.newVersion,
          "version_code": newVerCode,
          "apk_file": "installer/android/LGPlayground.apk",
          "ipa_file": "installer/ios/LGPlayground.ipa",
          "plist_file": "installer/ios/manifest.plist"
        }),
      });

      setCompleteModal({
        open: true,
        title: 'Upload success',
        message: 'LG Playground application 이 update 되었습니다.',
        message2: 'Cache 무효화 요청중 입니다. 잠시 기다려주세요.'
      });

      let invalidationResults = await fetch(PG_CONFIG.apiGatewayUrl + "/lgpg/invalidation", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          "invalidation_items": [
            "/installer/android/LGPlayground.apk",
            "/installer/ios/LGPlayground.ipa",
            "/installer/ios/manifest.plist"
          ]
        }),
      });
      console.log(invalidationResults);

      setCompleteModal({open: false});

      if (invalidationResults.status === 200) {
        setInvalidationCompleteModal({
          open: true,
          title: 'Create invalidation',
          message: 'Cache 무효화가 요청되었습니다. 몇 분 내에 무효화가 완료됩니다.'
        });
      } else {
        setInvalidationCompleteModal({
          open: true,
          title: 'Failed invalidation',
          message: 'Cache 무효화 요청이 실패하였습니다.'
        });
      }
    } catch (error) {
      setErrorModal({
        open: true,
        title: 'Error',
        message: `${error.message} ${error.response.data}`
      });
      console.log("App upload 실패");
    }
  }

  const userInfo = auth.getUserInfo();

  return (
    <Container fluid className="main-content-container mt-5">
      { (userInfo.userType === 4)
      ?
        <>
          <Container>
            {/* Editor */}
            <Card className="mb-3">
              <Card.Header className="border-bottom border-light d-flex justify-content-between">
                <span className="d-block">
                  <h4 className="text-primary align-top">LG Playground Application Upload</h4>
                  <h6 className="text-primary align-top">Please fill in LG Playground application information in the form below</h6>
                </span>
              </Card.Header>

              <Card.Body>
                <Col xl={12} id="register-edit">
                  <Form>
                    <Form.Group className="mb-5">
                      <Form.Label>App Version (Current : {appVersion.currVersion})</Form.Label>
                      <Form.Control className="input-box" rows="1" type="text" name="app_version" id="app_version" autoComplete="off" maxlength="9"
                        onChange={(event) => {handleVersionEdit(event, VERSION_LENGTH)}} />
                    </Form.Group>

                    <Dropdown.Divider className="my-4 border-indigo" />

                    <Form.Group className="mb-5">
                      <Form.Label htmlFor="app_screenshot">Attach Files (Android : apk / iOS : ipa, plist)</Form.Label>
                      <p>
                        <span small="true">&nbsp;&nbsp;1) 앱 파일: apk, ipa, plist 파일을 추가 할 수 있습니다.</span> <br/>
                        <span small="true">&nbsp;&nbsp;2) 파일 타입 별로 각 1개의 파일만 업로드 가능합니다.</span> <br/>
                        <span small="true">&nbsp;&nbsp;3) 각 파일명은 아래와 같이 변경해서 올려주세요.</span> <br/>
                        <span small="true">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- apk : LGPlayground.apk</span> <br/>
                        <span small="true">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- ipa : LGPlayground.ipa</span> <br/>
                        <span small="true">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- plist : manifest.plist</span> <br/>
                      </p>
                      <React.Fragment>
                        <Dropzone onDrop={acceptedFiles => handleAcceptedFiles(acceptedFiles)}>
                          {({ getRootProps, getInputProps }) => (
                            <div className="upload-box">
                              <div className="dropzone text-center" style={{ borderRadius: "15px" }}>
                                  <div className="dz-message py-5 needsclick" {...getRootProps()}>
                                    <input {...getInputProps()} />
                                    <UploadCloud width="60" height="60" />
                                    <br /><br />
                                    <h5>Drop files here or click to upload.</h5>
                                  </div>
                                </div>
                              </div>
                          )}
                        </Dropzone>
                        <br />

                        { (!gadget.isEmpty(fileList.android_apk) || !gadget.isEmpty(fileList.ios_ipa) || !gadget.isEmpty(fileList.ios_plist)) &&
                          <Row>
                            <Col xl={12}>
                              <div className="upload-files">
                                <AppFileContainer
                                    className="android_apk"
                                    fileInfo={fileList.android_apk} />

                                { (!gadget.isEmpty(fileList.android_apk) && (!gadget.isEmpty(fileList.ios_ipa) || !gadget.isEmpty(fileList.ios_plist))) &&
                                  <hr className="mt-4" style={{ width: "90%", margin: "0px auto" }}/>
                                }
                                <AppFileContainer
                                  className="ios_ipa"
                                  fileInfo={fileList.ios_ipa} />
                                <AppFileContainer
                                  className="ios_plist"
                                  fileInfo={fileList.ios_plist} />

                              </div>
                            </Col>
                          </Row>
                        }
                      </React.Fragment>
                    </Form.Group>
                  </Form>
                </Col>
                {/* Upload Button */}
                <div className="mt-5 mb-1 text-right">
                  <Button size="md" style={{ width: "120px" }} theme="primary" className="mb-2 mr-1" onClick={() => { appUpload() }}>Upload</Button>
                </div>
              </Card.Body>
            </Card>
          </Container>
          {/* Upload Progress Modal */}
          <Modal as={Modal.Dialog} size="lg" centered show={getUploadModal.open}>
            <Modal.Header>
              <Modal.Title className="h5">{getUploadModal.title}</Modal.Title>
              <Button variant="close" as={Link} aria-label="Close" onClick={() => setUploadModal({ open: false })} />
            </Modal.Header>
            <Modal.Body>
              <div className="mx-3">
                <label className="mt-3 mb-3">{getUploadModal.message}</label>
              </div>

              { getUploadModal.filename !== undefined &&
                <div style={{paddingLeft: "30px", paddingRight: "30px"}}>
                  <label style={{ marginTop: "30px" }}>Progress</label>
                  <div style={{ height: 30, width: "100%", backgroundColor: "#e0e0de", borderRadius: 50, marginBottom: "20px" }} >
                    <div style={{ height: "100%", width: `${getUploadModal.totalProgress}%`, backgroundColor: "#262b40", transition: "width .2s ease-in-out", borderRadius: "inherit", textAlign: "right", paddingTop: "3px" }}>
                      <span style={{ padding: 5, color: "white", fontWeight: "bold" }}>{getUploadModal.uploadedFileCnt}/{getUploadModal.totalFileCnt}</span>
                    </div>
                  </div>

                  <label>File: {getUploadModal.filename}</label>
                  <div style={{ height: 30, width: "100%", backgroundColor: "#e0e0de", borderRadius: 50, marginBottom: "20px" }} >
                    <div style={{ height: "100%", width: `${getUploadModal.progress}%`, backgroundColor: "#262b40", transition: "width .2s ease-in-out", borderRadius: "inherit", textAlign: "right", paddingTop: "3px" }}>
                      <span style={{ padding: 5, color: "white", fontWeight: "bold" }}>{getUploadModal.progress}%</span>
                    </div>
                  </div>
                </div>
              }
            </Modal.Body>
            <Modal.Footer style={{border: "0px"}}>
              <Button variant="link" className="text-gray ms-auto" onClick={() => setUploadModal({ open: false })}>
                {getUploadModal.button}
              </Button>
            </Modal.Footer>
          </Modal>
          {/* Upload Error Modal */}
          <Modal as={Modal.Dialog} size="lg" centered show={getErrorModal.open}>
            <Modal.Header>
              <Modal.Title className="h5">{getErrorModal.title}</Modal.Title>
              <Button variant="close" aria-label="Close" onClick={() => setErrorModal({ open: false })} />
            </Modal.Header>
            <Modal.Body>
              <div className="mx-3">
                <label className="mt-3 mb-3">{getErrorModal.message}</label>
              </div>
            </Modal.Body>
            <Modal.Footer style={{border: "0px"}}>
              <Button variant="link" className="text-gray ms-auto" onClick={() => setErrorModal({open: false})}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
          {/* Upload Complete Modal */}
          <Modal as={Modal.Dialog} size="lg" centered show={getCompleteModal.open}>
            <Modal.Header>
              <Modal.Title className="h5">{getCompleteModal.title}</Modal.Title>
              {/* <Button variant="close" aria-label="Close" onClick={() => setCompleteModal({ open: false })} /> */}
            </Modal.Header>
            <Modal.Body>
              <div className="mx-3">
                <label className="mt-3">{getCompleteModal.message}</label>
                <br />
                <label className="mb-3">{getCompleteModal.message2}</label>
              </div>
            </Modal.Body>
            {/*
            <Modal.Footer style={{border: "0px"}}>
              <Button variant="link" className="text-gray ms-auto" onClick={() => {setCompleteModal({open: false}); window.location.replace("/");}}>
                Close
            </Button>
            </Modal.Footer>
            */}
          </Modal>
          {/* Invalidation Complete Modal */}
          <Modal as={Modal.Dialog} size="lg" centered show={getInvalidationCompleteModal.open}>
            <Modal.Header>
              <Modal.Title className="h5">{getInvalidationCompleteModal.title}</Modal.Title>
              <Button variant="close" aria-label="Close" onClick={() => setInvalidationCompleteModal({ open: false })} />
            </Modal.Header>
            <Modal.Body>
              <div className="mx-3">
                <label className="mt-3 mb-3">{getInvalidationCompleteModal.message}</label>
              </div>
            </Modal.Body>
            <Modal.Footer style={{border: "0px"}}>
              <Button variant="link" className="text-gray ms-auto" onClick={() => {setInvalidationCompleteModal({open: false}); window.location.replace("/");}}>
                Close
            </Button>
            </Modal.Footer>
          </Modal>
        </>
      :
        <div>업로드 권한이 없습니다.</div>
      }
    </Container>
  );
}

export default LGPGUploader;