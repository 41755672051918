import React, { useState, useEffect } from "react";
import { Col, Row, Table } from '@themesberg/react-bootstrap';

import Dropzone from 'react-dropzone';
import Sortable from 'react-sortablejs';

import { APP_REGISTER } from './const/AppConst'

import { Trash2, UploadCloud } from 'react-feather';
import "../../css/register.css";
import gadget from "../../utils/gadget";

function FileAttach(props) {
  const attachFiles = props.boardFiles;
  const entryPoint = props.entryPoint;

  const [fileList, setFileList] = useState({
    init: false,
    allFileList: [],
    app_file: [],
    icon_file: [],
    screenshot: []
  });

  const [getEntryPoint, setEntryPoint] = useState('');
  const [getAppType, setAppType] = useState(0);

  const RemoveFile = (index, filename) => {
    switch (index) {
      case 'app_file':
        setFileList({ ...fileList, app_file: fileList.app_file.filter(file => !decodeURIComponent(file.path).includes(filename)) });
        break;
      case 'icon_file':
        setFileList({ ...fileList, icon_file: fileList.icon_file.filter(file => !decodeURIComponent(file.path).includes(filename)) });
        break;
      case 'banner_file':
        setFileList({ ...fileList, banner_file: fileList.banner_file.filter(file => !decodeURIComponent(file.path).includes(filename)) });
        break;
      case 'screenshot':
        setFileList({ ...fileList, screenshot: fileList.screenshot.filter(file => !decodeURIComponent(file.path).match(filename)) });
        break;
      default:
        break;
    }
  }

  const getImagePreview = (fileInfo) => {
    if (!gadget.isEmpty(fileInfo.preview)){
      return fileInfo.preview;
    } else if (!gadget.isEmpty(fileInfo.path) && (fileInfo.path.match(/^http/))) {
      return fileInfo.path
    }

    return '';
  }

  const getFilename = (fileInfo) => {
    let filename;

    if (fileInfo.uploaded) {
      let getUrl = fileInfo.path.split('?')[0];
      filename = decodeURIComponent(getUrl).split('/')[APP_REGISTER.URL_APP_FILENAME]
    } else {
      filename = fileInfo.path;
    }

    return filename;
  }

  const AppIconContainer = (fileInfo) => {
    if (gadget.isEmpty(fileInfo.path)) {
      return <></>
    }

    let filename = getFilename(fileInfo);
    let preview = getImagePreview(fileInfo);

    return (
      <div className="mx-4">
        <p className="title">App icon</p>
        <div id="icon-img-wrapper">
          <img src={preview} alt="preview icon"/>
          <div onClick={() => { RemoveFile('icon_file', filename) }}>
            <Trash2/>
          </div>
        </div>
      </div>
    );
  }

  const AppBannerContainer = (fileInfo) => {
    if (gadget.isEmpty(fileInfo.path)) {
      return <></>
    }

    let filename = getFilename(fileInfo);;
    let preview = getImagePreview(fileInfo);

    return (
      <div className="mx-4">
        <p className="title">Banner</p>
        <div id="banner-img-wrapper">
          <img src={preview} alt="banner icon"/>
          <div onClick={() => { RemoveFile('banner_file', filename) }}>
            <Trash2/>
          </div>
        </div>
      </div>
    );
  }

  const FileContainer = (props) => {
    let fileData = props.file;

    let filename;
    let date = fileData.date;
    let appSize = fileData.app_size;

    if (fileData.uploaded) {
      let getUrl = fileData.path.split('?')[0];
      filename = decodeURIComponent(getUrl).split('/')[APP_REGISTER.URL_APP_FILENAME]
    } else {
      filename = fileData.path;
    }

    return (
      <React.Fragment>
        <tr style={{ verticalAlign: "middle" }}>
          <td className="border-0" style={{ width: "40%" }}> { filename } </td>
          <td className="border-0" style={{ width: "30%" }}> { date } </td>
          <td className="border-0" style={{ width: "20%" }}> { appSize } </td>
          <td className="border-0" style={{ width: "10%" }}>
            <div id="app-trash">
              <a  onClick={() => { RemoveFile('app_file', filename) }}>
                <Trash2/>
              </a>
            </div>
          </td>
        </tr>
      </React.Fragment>
    );
  }

  const AppFileContainer = ({ fileInfo }) => {
    if (fileInfo.length === 0) {
      return <React.Fragment/>
    }

    fileInfo = fileInfo.map((attachFile, idx) => <FileContainer key={idx} file={attachFile}/>);

    return (
      <>
        <div className="mx-4">
          <p className="title">App 상세정보</p>
          <Table className="table-centered table-nowrap rounded">
            <thead>
              <tr>
                <th className="border-0" style={{ width: "40%", fontSize: "13px", paddingBottom: "0px" }}>App filename</th>
                <th className="border-0" style={{ width: "30%", paddingBottom: "0px" }}>Date</th>
                <th className="border-0" style={{ width: "20%", paddingBottom: "0px" }}>Size</th>
                <th className="border-0" style={{ width: "10%", paddingBottom: "0px" }}></th>
              </tr>
            </thead>
            <tbody className="py-0">
              {fileInfo}
            </tbody>
          </Table>
        </div>
      </>
    );
  }

  const ScreenshotContainer = (props) => {
    if (gadget.isEmpty(props)) {
      return <></>
    }

    let fileData = props.file;
    let preview = (!gadget.isEmpty(fileData.preview)) ? fileData.preview
      : (!gadget.isEmpty(fileData.path) && (fileData.path.match(/^http/))) ? fileData.path : '';
    let fileIndex = fileData.position;
    let filename;

    if (fileData.uploaded) {
      let getUrl = fileData.path.split('?')[0];
      filename = decodeURIComponent(getUrl).split('/')[APP_REGISTER.URL_APP_FILENAME]
    } else {
      filename = fileData.path;
    }

    return (
      <React.Fragment>
        <div id="screenshot-img-wrapper">
          <img src={preview} alt="screenshot images"/>
          <div onClick={() => { RemoveFile(fileIndex, filename) }}>
            <Trash2 className="trash" />
          </div>
        </div>
      </React.Fragment>
    );
  }

  const AppScreenshotContainer = ( {fileInfo} ) => {
    if (fileInfo.length === 0) {
      return <React.Fragment/>
    }

    fileInfo = fileInfo.map((attachFile, idx) => <ScreenshotContainer key={idx} file={attachFile}/>);

    return (
      <div className="mx-4">
        <p className="title">Screenshot ({fileList.screenshot.length})</p>
        <Sortable
          options={{
            group: 'screenshot',
            animation: 1000,

            onEnd: (evt) => {
              // evt, evt.oldIndex, evt.from.accessKey, evt.newIndex, evt.to.accessKey

              let fromKey = evt.from.accessKey;
              let toKey = evt.to.accessKey;

              let fromList = fileList[fromKey];
              let toList = fileList[toKey];

              if (evt.to.accessKey !== evt.from.accessKey) {
                fileInfo[evt.oldIndex].props.file.position = evt.to.accessKey;
                toList.splice(evt.newIndex, 0, fileInfo[evt.oldIndex].props.file)
                fromList.splice(evt.oldIndex, 1);
              } else {
                if (evt.oldIndex < evt.newIndex) {
                  toList.splice(evt.newIndex + 1, 0, fileInfo[evt.oldIndex].props.file)
                  toList.splice(evt.oldIndex, 1)
                } else {
                  toList.splice(evt.newIndex, 0, fileInfo[evt.oldIndex].props.file)
                  toList.splice(evt.oldIndex + 1, 1)
                }
              }
              setFileList({ ...fileList, [toKey]: toList, [fromKey]: fromList })
            },
          }}
          accessKey={'screenshot'}>

          {fileInfo}
        </Sortable>
      </div>
    );
  }

  const formatBytes = (bytes, decimals) => {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  const handleAcceptedFiles = (files) => {
    files.map(file => {
        let preview = (file['type'].split('/')[0] === 'image') ? URL.createObjectURL(file) : null;
        let date = new Date(file.lastModified).toLocaleString();
        let appFileSize = formatBytes(file.size);
        let position;

        if (file['name'].match(/(.apk|.ipa|.plist|.zip)$/) !== null) {
          position = 'app_file';
        } else if (file['name'].match(/^(icon_)/) !== null && file['name'].match(/(.webp|.png|.jpg|.bmp)$/) !== null) {
          position = 'icon_file';
        } else if (file['name'].match(/^(banner_)/) !== null && file['name'].match(/(.webp|.png|.jpg|.bmp)$/) !== null) {
          position = 'banner_file';
        } else if (file['name'].match(/(.webp|.png|.jpg|.bmp)$/) !== null) {
          position = 'screenshot';
        } else {
          position = '';
        }

        if (position !== '') {
          return Object.assign(file, {
            preview: preview,
            date: date,
            app_size: appFileSize,
            position: position,
            uploaded: false,
          })
        }
      }
    );

    let app_file = [...fileList.app_file];
    let icon_file = fileList.icon_file;
    let banner_file = fileList.banner_file;
    let screenshot = [...fileList.screenshot];

    for (let file of files) {
      switch(file.position) {
        case 'app_file':
          if (app_file.filter((app_file) => app_file.name.match(file.name)).length === 0) {
            app_file.push(file);
          }

          let maxFileNum = (getAppType === 1) ? APP_REGISTER.MAX_APP_IOS_FILE : APP_REGISTER.MAX_APP_FILE;
          if (maxFileNum < app_file.length) {
            app_file.pop();
          }
          break;

        case 'icon_file':
          if (icon_file.filter((icon_file) => icon_file.name.match(file.name)).length === 0) {
            icon_file.push(file);
          }
          if (APP_REGISTER.MAX_ICON_FILE < icon_file.length) {
            icon_file.pop();
          }
          break;

        case 'banner_file':
          if (banner_file.filter((banner_file) => banner_file.name.match(file.name)).length === 0) {
            banner_file.push(file);
          }
          if (APP_REGISTER.MAX_BANNER_FILE < banner_file.length) {
            banner_file.pop();
          }
          break;

        case 'screenshot':
          if (screenshot.filter((screenshot) => screenshot.name.match(file.name)).length === 0) {
            screenshot.push(file);
          }
          if (APP_REGISTER.MAX_SCREENSHOT_FILE < screenshot.length) {
            screenshot.pop();
          }
          break;

        default:
          break;
      }
    }

    setFileList({ ...fileList, init: true, app_file, icon_file, banner_file, screenshot })
  }

  useEffect(() => {
    // Sidebar를 누를 경우 기존 Data를 삭제 한다.
    if (props.entryPoint !== getEntryPoint) {
      setFileList({
        init: false,
        allFileList: [],
        app_file: [],
        icon_file: [],
        banner_file: [],
        screenshot: []
      });
      setEntryPoint(props.entryPoint);
      setAppType(props.appType);
    } else {
      if (Object.keys(attachFiles).length !== 0
          && !fileList.init) {
        setFileList({
          init: true,
          allFileList: [],
          app_file: attachFiles.app_file,
          icon_file: [attachFiles.icon_file],
          banner_file: [attachFiles.banner_file],
          screenshot: attachFiles.screenshot
        });
      }
      setAppType(props.appType);

      if (fileList.init) {
        props.onFileUpload(fileList);
      }
    }
  }, [fileList, attachFiles, getEntryPoint, props]);

  return (
    <React.Fragment>
      <Dropzone onDrop={acceptedFiles => handleAcceptedFiles(acceptedFiles)} {...props}>
        {({ getRootProps, getInputProps }) => (
          <div className="upload-box">
            <div className="dropzone text-center" style={{ borderRadius: "15px" }}>
              <div className="dz-message py-5 needsclick" {...getRootProps()}>
                <input {...getInputProps()} />
                <UploadCloud width="60" height="60" />
                <br /><br />
                <h5>Drop files here or click to upload.</h5>
              </div>
            </div>
          </div>
        )}
      </Dropzone>
      <br />

      { (!gadget.isEmpty(fileList.app_file) || !gadget.isEmpty(fileList.icon_file) || !gadget.isEmpty(fileList.banner_file) || Object.keys(fileList.screenshot).length !== 0) &&
        <Row>
          <Col xl={12}>
            <div className="upload-files">
              <AppFileContainer
                  className="app_file"
                  fileInfo={fileList.app_file} />

              { (!gadget.isEmpty(fileList.app_file) && (!gadget.isEmpty(fileList.icon_file) || !gadget.isEmpty(fileList.banner_file))) &&
                <hr className="mt-4" style={{ width: "90%", margin: "0px auto" }}/>
              }

              <Row>
                <Col xl={6}>
                  <AppIconContainer
                    {...fileList.icon_file[0]} />
                </Col>
                <Col xl={6}>
                  <AppBannerContainer
                    {...fileList.banner_file[0]} />
                </Col>
              </Row>

              { (((!gadget.isEmpty(fileList.app_file) && (gadget.isEmpty(fileList.icon_file) || gadget.isEmpty(fileList.banner_file)))
                  || (!gadget.isEmpty(fileList.icon_file) || !gadget.isEmpty(fileList.banner_file)))
                      && Object.keys(fileList.screenshot).length !== 0) &&
                <hr className="mt-5" style={{ width: "90%", margin: "0px auto" }}/>
              }

              <AppScreenshotContainer
                classNames="screenshot"
                fileInfo={fileList.screenshot} />
            </div>
          </Col>
        </Row>
      }

    </React.Fragment>
  );
}

export default FileAttach;