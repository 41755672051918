import React, { useState, useEffect } from "react";
// import ProgressBar from "./progress-bar.component";

import { Button, Card, Col, Container, Dropdown, Form, Modal, Row } from '@themesberg/react-bootstrap';
import { Breadcrumb } from '@themesberg/react-bootstrap';
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Routes } from "../../routes";


function TestPage(props) {
  const appDetails = props.location.state == null
  ? { app_status: 0, app_file: '', icon_file: '', download_cnt: 0, package_name: '', activity_name: '', url_schema: '', description: '', admin_comment: '', app_size: '' }
  : {};

  const [registerApp, setRegisterApp] = useState(appDetails);
  const [completed, setCompleted] = useState(0);
  const [fileUploadModal, setFileUploadModal] = useState({ open: false, title: '', message: '' });
  const [uploadProgress, setUploadProgress] = useState({ filename: '', progress: 0 });

  useEffect(() => {
    setFileUploadModal({
      open: true,
      title: 'Success',
      message: 'Application 등록이 요청 됐습니다.'
    });

    setInterval(() => setUploadProgress({progress: Math.floor(Math.random() * 100) + 1}), 2000);
  }, []);

  return (
    <>
      <Container fluid className="main-content-container">
        <Row noGutters className="page-header py-4">
          {/*<PageTitle sm="4" title="Register" subtitle="Apps" className="text-sm-left" />*/}
          <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
            <Breadcrumb.Item><FontAwesomeIcon icon={faHome} /></Breadcrumb.Item>
            <Breadcrumb.Item>Apps</Breadcrumb.Item>
            <Breadcrumb.Item active>Register</Breadcrumb.Item>
          </Breadcrumb>
          <h4>Register application</h4>
        </Row>

        <Container>
          {/* Editor */}
          <Card className="mb-3">
            <Card.Header className="border-bottom border-light d-flex justify-content-between">
              <span className="d-block">
                <h4 className="text-primary align-top">Application Information</h4>
                <h6 className="text-primary align-top">Please fill in your application information in the form below</h6>
              </span>
            </Card.Header>
          </Card>
        </Container>
      </Container>

      <Modal as={Modal.Dialog} centered show={fileUploadModal.open}>
        <Modal.Header>
          <Modal.Title className="h6">{fileUploadModal.title}</Modal.Title>
          <Button variant="close" aria-label="Close" />
        </Modal.Header>
        <Modal.Body>
          <p>{fileUploadModal.message}</p>
          <label>{uploadProgress.filename}</label>

          <div style={{ height: 30, width: "100%", backgroundColor: "#e0e0de", borderRadius: 50, marginTop: "30px", marginBottom: "10px" }} >
            <div style={{ height: "100%", width: `${fileUploadModal.totalProgress}%`, backgroundColor: "#262b40", transition: "width .2s ease-in-out", borderRadius: "inherit", textAlign: "right", paddingTop: "3px" }}>
              <span style={{ padding: 5, color: "white", fontWeight: "bold" }}>{fileUploadModal.uploadedFileCnt}/{fileUploadModal.totalFileCnt}</span>
            </div>
          </div>
          <div style={{ height: 30, width: "100%", backgroundColor: "#e0e0de", borderRadius: 50, }} >
            <div style={{ height: "100%", width: `${fileUploadModal.progress}%`, backgroundColor: "#262b40", transition: "width .2s ease-in-out", borderRadius: "inherit", textAlign: "right", paddingTop: "3px" }}>
              <span style={{ padding: 5, color: "white", fontWeight: "bold" }}>{fileUploadModal.progress}%</span>
            </div>
          </div>
          <label style={{ marginTop: "10px "} }>{fileUploadModal.filename}</label>


        </Modal.Body>
        <Modal.Footer>
          <Button variant="link" className="text-gray ms-auto">
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default TestPage;
