export const SURVEY_STATUS = {
  REQUESTED: 0,
  APPROVED: 1,
  REJECTED: 2,
  COMPLETED: 4
}

export const SURVEY_USER_TYPE = {
  TESTER: 1,
  DEVELOPER: 2,
  ANALYST: 3,
  ADMIN: 4,
}