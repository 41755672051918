import React, { Component } from 'react';
import '../styles/InputRangeNumber.css';

export default class InputNumber extends Component {
  state = {
    value: 0,
  }

  constructor(props) {
    super(props);
    this.state = {
      id: 0,
      value: 0
    };

    this.increment = this.increment.bind(this);
    this.decrement = this.decrement.bind(this);
  }

  get value() {
    return this.state.value;
  }

  increment = (id, value) => {
    if (typeof this.props.max === 'number' && value >= this.props.max) return;
    this.props.onNumChange(id, value + 1);
  }

  decrement = (id, value) => {
    if (typeof this.props.min === 'number' && value <= this.props.min) return;
    this.props.onNumChange(id, value - 1);
  }

  render() {
    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        <div className="input-range-number" style={this.props.style}>
          <button type="button" onClick={this.decrement.bind(this, this.props.id, this.props.value)}>&minus;</button>
          <span>{this.props.value}</span>
          <button type="button" onClick={this.increment.bind(this, this.props.id, this.props.value)}>&#43;</button>
        </div>
        <div className="input-range-number-label">
          <span style={{ color: "#4a5073", fontWeight:"bold" }}>&nbsp;{this.props.label}</span>
        </div>
      </div>
    )
  }
}










/*
export default class InputNumber extends Component {
    state = {
        value: 0,
    }

    constructor(props) {
        super(props);

        this.state = {
            value: this.props.default
        }
        
        this.increment = this.increment.bind(this);
        this.decrement = this.decrement.bind(this);
    }

    get value() {
        return this.state.value;
    }

    increment() {
        const { max } = this.props;

        if (typeof max === 'number' && this.value >= max) return;

        this.setState({ value: this.value + 1 });
        this.props.onNumChange(this.value + 1);
    }

    decrement() {
        const { min } = this.props;

        if (typeof min === 'number' && this.value <= min) return;

        this.setState({ value: this.value - 1 });
        this.props.onNumChange(this.value - 1);
    }

    render() {
        return (
            <div className="input-range-number" style={this.props.style}>
                <button type="button" onClick={this.decrement}>&minus;</button>
                <span>{this.value}</span>
                <button type="button" onClick={this.increment}>&#43;</button>     
            </div>
        )
    }
}
*/