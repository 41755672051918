import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Container, Col, Row } from '@themesberg/react-bootstrap';
import { UserProfile } from "../components/UserProfile";
import { Breadcrumb } from '@themesberg/react-bootstrap';
import { faHome } from "@fortawesome/free-solid-svg-icons";

import "../css/profile.css";

export default () => {
  return (
    <Container fluid className="main-content-container">
      {/* Page Header */}
      <Row noGutters className="page-header py-4">
        {/*<PageTitle sm="4" title="List" subtitle="App" className="text-sm-left" />*/}
        <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
          <Breadcrumb.Item><FontAwesomeIcon icon={faHome} /></Breadcrumb.Item>
          <Breadcrumb.Item>User</Breadcrumb.Item>
          <Breadcrumb.Item active>Profile</Breadcrumb.Item>
        </Breadcrumb>
        <h4>Profile change</h4>
        <p className="mb-0">
          { }
        </p>
      </Row>
      <Container>
        <Row>
          <Col xs={12} xl={12}>
            <UserProfile />
          </Col>

          {/*<Col xs={12} xl={4}>
          <Row>
            <Col xs={12}>
              <ProfileCardWidget />
            </Col>
          </Row>
        </Col>*/}
        </Row>
      </Container>
    </Container>
  );
};
