import { Badge, Button, Card, Col, Container, Dropdown, Nav, Pagination, Row } from '@themesberg/react-bootstrap';
import React, { useEffect, useRef, useState } from "react";
import { Redirect, useHistory } from "react-router-dom";

import { faAngleDoubleLeft, faAngleDoubleRight, faAngleDown, faHome } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Breadcrumb } from '@themesberg/react-bootstrap';

import axios from 'axios';
import auth from "../../utils/auth";

import { APP_LIST } from './const/AppConst';

import list from "../../css/list.module.css";


// aws s3 cp 2048_icon.png s3://s3-lg-playground-file-upload/android/2048/

function List(props) {
  const [getAppList, setAppList] = useState();
  const history = useHistory();
  const [filter, setFilter] = useState("allapps");

  const appListPagination = useRef({ maxRows: 0, firstPageIndex: 0, currentPage: 1 })

  const goDetailView = (id) => {
    history.push({
      pathname: "/app/details",
      state: {
        id: id,
        entryPoint: "List"
      }
    });
  }

  const getQueryAppList = async (offset) => {
    try {
      const response = await axios.get(APP_LIST.AWS_APP_APPROVED_LIST_QUERY + `?app_status=1&offset=${offset}&user_type=${auth.getUserType()}&req_id=${auth.getUserID()}`);

      console.log('APP_LIST.AWS_APP_APPROVED_LIST_QUERY: ', APP_LIST.AWS_APP_APPROVED_LIST_QUERY);

      const AppsData = response.data;
      appListPagination.current.maxRows = response.data.totalRow;
      let appInfo = [];

      if (true) {
        const AWSPath = response.data.S3Path;
        let index = 0;

        for (let app of AppsData.appList) {
          let files = [];
          files['icon_file'] = AWSPath[index]['icon_file'];

          appInfo.push({ appData: app, AWSPath: files });
          index++;
        }
      } else {
        const CloudFrontAddress = response.data.CloudFront;

        for (let app of AppsData.appList) {
          let files = [];

          files['icon_file'] = CloudFrontAddress + '/' + encodeURIComponent(app.icon_file);
          appInfo.push({ appData: app, AWSPath: files });
        }
      }

      setAppList(appInfo);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      getQueryAppList(0)
    };
    fetchData();
  }, []);

  const AppCardWidget = (app) => {
    const { id, app_name, app_version, app_type, register_date, download_cnt } = app.appData;
    const { icon_file } = app.AWSPath;

    let targetBadge = (app_type === APP_LIST.ANDROID_TYPE)
        ? {bg: "secondary", target: "Android"} : (app_type === APP_LIST.IOS_TYPE)
          ? {bg: "warning", target: "iOS"} : {bg: "success", target: "Web"}
    let getDate = new Date(register_date).toString().split(' ');
    let registerDate = getDate[0] + ', ' + getDate[1] + ' ' + getDate[2] + ' \'' + (getDate[3] % 2000);

    return (
      <Card className={list.box} style={{ cursor: 'pointer' }} onClick={() => { goDetailView(id)}} >
        <Card.Img src={icon_file} />
        <Card.Body className="p-2">
          <Card.Title className={list.title}>{app_name}</Card.Title>
          <Row>
            <Col>
              <p small="true" className={list.version}>{app_version}</p>
            </Col>

            <Col>
              <p small="true" className={list.target}>
                <Badge pill bg={targetBadge.bg} className="badge-md">{targetBadge.target}</Badge>
              </p>
            </Col>
          </Row>

          <hr style={{margin: "10px 5px 0px 5px"}}/>

          <Row>
            <span small="true" className={list.registerdate}>{registerDate}</span>
            <Col>
              <div className={list.download}>
                <i className="fas fa-cloud-download-alt mx-1"></i>
                <span small="true">{download_cnt}</span>
              </div>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    );
  };

  const onPreviousItem = async () => {
    if (appListPagination.current.firstPageIndex > 0) {
      appListPagination.current.currentPage = appListPagination.current.firstPageIndex;
      appListPagination.current.firstPageIndex -= 10;

      getQueryAppList((appListPagination.current.currentPage - 1) * APP_LIST.PAGE_CARD_VIEW);
    }
  };

  const onNextItem = async (page) => {
    if (((appListPagination.current.firstPageIndex + 10) * APP_LIST.PAGE_CARD_VIEW) < appListPagination.current.maxRows) {
      appListPagination.current.firstPageIndex += 10;
      appListPagination.current.currentPage = appListPagination.current.firstPageIndex + 1;

      getQueryAppList(( appListPagination.current.firstPageIndex) * APP_LIST.PAGE_CARD_VIEW);
    }
  };

  const onPageItem = async (page) => {
    appListPagination.current.currentPage = page;

    getQueryAppList((page - 1) * APP_LIST.PAGE_CARD_VIEW);
  };

  const PageNumbering = (props) => {
    let index = props.index;

    if ((appListPagination.current.maxRows + APP_LIST.PAGE_CARD_VIEW) <= (index * APP_LIST.PAGE_CARD_VIEW)) {
      return <></>;
    }

    return <Pagination.Item className="mx-1" active={appListPagination.current.currentPage === index} onClick={() => onPageItem(index)}>{index}</Pagination.Item>
  }

  const AppCardPagination = (props) => {
    if (appListPagination.current.maxRows <= APP_LIST.PAGE_CARD_VIEW) {
      return <></>;
    }

    let html = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((index) => <PageNumbering index={index + appListPagination.current.firstPageIndex} />)

    return (
      <>
        <Pagination.Prev onClick={() => onPreviousItem()}>
          <FontAwesomeIcon icon={faAngleDoubleLeft} />
        </Pagination.Prev>
        {html}
        <Pagination.Next onClick={() => onNextItem()}>
          <FontAwesomeIcon icon={faAngleDoubleRight} />
        </Pagination.Next>
      </>
    );
  }

  return (
    <>
      {!auth.isLogin() && <Redirect to="/login" />}
      <Container fluid className="main-content-container">
        <Row noGutters className="page-header py-4">
          <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
            <Breadcrumb.Item><FontAwesomeIcon icon={faHome} /></Breadcrumb.Item>
            <Breadcrumb.Item>Apps</Breadcrumb.Item>
            <Breadcrumb.Item active>List</Breadcrumb.Item>
          </Breadcrumb>

          <h4> { filter === "myapps" ? "My Apps" :
              filter === "android" ? "Android" :
              filter === "ios" ? "iOS" :
              filter === "webapp" ? "Web App" : "All Apps"} </h4>
          <p className="mb-0">
            { }
          </p>
        </Row>

        <Dropdown className="btn-toolbar" onSelect={(eventKey) => { setFilter(eventKey) }}>
          <Dropdown.Toggle as={Button} variant="outline-gray-900" size="sm" className="me-2">
            Filter <FontAwesomeIcon icon={faAngleDown} className="ms-2" />
          </Dropdown.Toggle>
          <Dropdown.Menu className="dashboard-dropdown dropdown-menu-left mt-2">
            <Dropdown.Item className="fw-bold" eventKey="allapps">
              All Apps
            </Dropdown.Item>
            {/*<Dropdown.Item className="fw-bold" eventKey="myapps">
              My Apps
            </Dropdown.Item>*/}
            <Dropdown.Item className="fw-bold" eventKey="android">
              Android
            </Dropdown.Item>
            <Dropdown.Item className="fw-bold" eventKey="ios">
              iOS
            </Dropdown.Item>
            <Dropdown.Item className="fw-bold" eventKey="webapp">
              Web
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>

        {getAppList !== undefined &&
        <>
          <Row className="boxs">
            { getAppList.map(({ ...app }, index) => {
              switch(filter) {
                case "myapps":
                  if (app.appData.request_id !== auth.getUserID()) return <></>
                  break;
                case "android":
                  if (app.appData.app_type !== APP_LIST.ANDROID_TYPE) return <></>
                  break;
                case "ios":
                  if (app.appData.app_type !== APP_LIST.IOS_TYPE) return <></>
                  break;
                case "webapp":
                  if (app.appData.app_type !== APP_LIST.WEB_APP_TYPE) return <></>
                  break;
                default:
                  break;
              }

              return <AppCardWidget key={index} {...app}/>
            }) }
          </Row>

          <Card.Footer className="px-3 border-0 d-lg-flex align-items-center justify-content-between">
            <Nav>
              <Pagination className="mb-2 mb-lg-0">
                <AppCardPagination/>
              </Pagination>
            </Nav>
          </Card.Footer>
        </>
        }
      </Container>
    </>
  )
}

export default List;
